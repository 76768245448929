import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { NgForageCache } from 'ngforage';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterHelperService } from './global-filters-helper.service';
import { GlobalFilterMapService } from './global-filters.map.service';
import { GlobalFilterService } from './global-filters.service';

@Injectable()
export class GlobalFilterApiService implements OnDestroy {
  multi_orgs: any = []; // config.property
  orgs: any = []; // config.property
  models: any = []; // config.property
  versions: any = []; // config.property
  languages: any = []; // config.property
  categories: any = []; // config.property
  surveyQuestions: any = []; // config.property
  surveyResponses: any = []; // config.property
  subscriptions: Subscription[] = [];
  apiCallState = {};
  public apiChange = new Subject();
  public apiConfig: any = {
    org: this.getOrgs.bind(this),
    multi_org: this.getOrgs.bind(this),
    model: this.getVindecoderData.bind(this),
    version: this.getVersion.bind(this),
    language: this.getLanguages.bind(this),
    surveyResponse: this.getSurveyResponse.bind(this),
    surveyQuestion: this.getSurveyQuestion.bind(this),
    category: this.getCategories.bind(this),
  };
  allSurveyVersions: any;
  constructor(
    public http: HttpClient,
    public gbfService: GlobalFilterService,
    public filterService: FiltersService,
    public gbfMapService: GlobalFilterMapService,
    public ngCache: NgForageCache,
    public gbfConfig: GlobalFilterConfigService,
    public gbfHelperService: GlobalFilterHelperService
  ) {
    this.subscriptions.push(
      gbfService.gbfApiCall.subscribe((configs: any[]) => {
        configs.forEach((config: any) => {
          if (this.apiConfig[config.name]) {
            if (this.gbfConfig.filterConfig.initialStates[config.name]) {
              this.gbfService[config.name] = this.gbfConfig.filterConfig.initialStates[config.name];
            }
            this.apiConfig[config.name](config);
            // if (!this.apiConfig[config.name]) {
            // this.ngCache.setCached(`gbf_${config.name}`, '{}', 300000);
            // }
            if (this.gbfConfig.filterConfig.initialStates[config.name]) {
              localStorage.removeItem(config.localStorageKey);
            }
          }
        });
      })
    );
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  getCategories(config: any) {
    const url = `/voc/categories`;
    this.http
      .cache()
      .get(`${url}`)
      .subscribe((categories: any) => {
        const mapCategories = this.gbfMapService.mapCategories(categories);
        this.categories = mapCategories;
        this.apiChange.next(config);
      });
  }
  callApi(name: string = '') {
    if (name === '') {
      return;
    }
    const config = this.gbfConfig.filterConfig.config.find((sconfig: any) => {
      return sconfig.name === name;
    });
    if (this[config.property] && this[config.property].length <= 0) {
      this.apiConfig[config.name](config);
    }
  }
  getLocalSelectedVal(config: any) {
    let localVal: any = { selectedTreeItems: [], selected: [] };
    if (config.localStorageKey) {
      try {
        const local = JSON.parse(localStorage.getItem(config.localStorageKey)) || {
          selectedTreeItems: [],
          selected: [],
        };
        localVal = local;
      } catch (e: any) {
        localVal = { selectedTreeItems: [], selected: [] };
      }
    }
    return [...localVal.selectedTreeItems, ...localVal.selected];
  }
  getSurveyResponse(config: any, completed?: Function) {
    const localVal = this.getLocalSelectedVal(config);
    const sv = this.gbfService.version.value;
    if (this.gbfService.authorizationData && this.gbfService.authorizationData.DefaultLanguage) {
      const url = `/voc/response/${sv}/${this.gbfService.authorizationData.DefaultLanguage}`;
      this.http
        .cache()
        .get(`${url}`)
        .subscribe((surveyResponses: any[]) => {
          surveyResponses.sort((a: any, b: any) => (a.Id > b.Id ? 1 : -1));
          surveyResponses = surveyResponses.map((surveyResponse: any) => {
            surveyResponse.level = 1;
            const childrens = surveyResponse.Options.map((option: any) => {
              const foundOption = localVal.find((x: any) => x.id === option.Id);
              if (foundOption) {
                option.IsChecked = foundOption.IsChecked;
                option.IsPartialChecked = foundOption.IsPartialChecked;
                option.active = foundOption.active;
              }
              option.label = option.Title;
              option.Label = option.Title;
              option.value = option.Id;
              option.name = option.Id;
              option.Name = option.Id;
              option.id = option.Id;
              option.level = 2;
              option.parent = surveyResponse.Id;
              return option;
            });
            const found = localVal.find((x: any) => x.id === surveyResponse.Id);
            if (found) {
              surveyResponse.IsChecked = found.IsChecked;
              surveyResponse.IsPartialChecked = found.IsPartialChecked;
              surveyResponse.active = found.active;
            }
            surveyResponse.children = childrens;
            surveyResponse.parent = 'null';
            surveyResponse.level = 1;
            surveyResponse.id = surveyResponse.Id;
            surveyResponse.label = surveyResponse.Id;
            surveyResponse.Label = surveyResponse.Id;
            surveyResponse.name = surveyResponse.Id;
            surveyResponse.Name = surveyResponse.Id;
            surveyResponse.mProps = surveyResponse;
            return surveyResponse;
          });
          this.surveyResponses = surveyResponses;
          if (completed) {
            completed(1);
          }
          this.apiChange.next(config);
        });
    }
  }
  getSurveyQuestion(config: any, completed?: Function) {
    const sv = this.gbfService.version.value;
    if (this.gbfService.authorizationData && this.gbfService.authorizationData.DefaultLanguage) {
      const url = `/voc/questions/${sv}/${this.gbfService.authorizationData.DefaultLanguage}`;
      this.http
        .cache()
        .get(`${url}`)
        .subscribe((surveyQuestions: any[]) => {
          surveyQuestions = surveyQuestions.map((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;
            val.label = val.Id;
            val.Options = val.Options.map((opt: any) => {
              opt.IsChecked = true;
              opt.IsSelected = false;
              return opt;
            });
            return val;
          });
          this.gbfService['surveyQuestion'] = surveyQuestions;
          this.surveyQuestions = surveyQuestions;
          if (completed) {
            completed(1);
          }
          this.apiChange.next(config);
        });
    }
  }
  getLanguages(config: any) {
    const url = `/voc/languages`;
    this.http
      .cache()
      .get(`${url}`)
      .subscribe((languages: any[]) => {
        languages = languages.map((language: any) => {
          language.label = language.Name;
          language.IsChecked = true;
          language.value = language.LanguageCode;
          return language;
        });
        this.languages = this.getCheckedValues(config.name, languages, config.settings.multi);
      });
  }
  getVersion(config: any) {
    this.allSurveyVersions = JSON.parse(localStorage.getItem('surveyVersions'));
    const url = `/surveylist/GetSurveyVersions`;
    if (!this.allSurveyVersions) {
      this.http.get(url).subscribe((res: any) => {
        if (res) {
          res = res.map((r: any) => {
            r.label = r.Version;
            r.value = r.SurveyId;
            return r;
          });
          this.allSurveyVersions = res;
          localStorage.setItem('surveyVersions', JSON.stringify(this.allSurveyVersions));
          this.mapVersionList(config);
        }
      });
    } else {
      this.mapVersionList(config);
    }
  }
  mapVersionList(config: any) {
    const LScurrentVersion = localStorage.getItem('gbf_version');
    let currentVersion: any = this.gbfService.version;

    const LCurrenTab = JSON.parse(localStorage.getItem('gbf_tab'));
    if (this.gbfService.program == '') this.gbfService.program = LCurrenTab.value;

    if (LScurrentVersion) {
      currentVersion = JSON.parse(LScurrentVersion);
    }
    this.versions = this.allSurveyVersions.filter(
      (x: any) =>
        this.gbfService.model && x.Brand === this.gbfService.model.Brand && x.Event === this.gbfService.program
    );
    let activeVersion = this.versions.find((x: any) => x.Version === currentVersion.version);
    if (this.versions && this.versions.length > 0) {
      if (this.gbfService.getSurveyVersion()) {
        if (!activeVersion) {
          activeVersion = this.versions.find(
            (x: any) =>
              x.SurveyId === this.gbfService.getSurveyVersion() &&
              x.Brand === this.gbfService.model.Brand &&
              x.Event === this.gbfService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.gbfService.setSurveyVersion({
            label: activeVersion.Version,
            value: activeVersion.SurveyId,
            version: activeVersion.SurveyId,
            versionName: activeVersion.Version,
          });
        } else {
          if (!activeVersion) {
            activeVersion = this.versions.find(
              (x: any) =>
                x.CurrentSurvey === true &&
                x.Brand === this.gbfService.model.Brand &&
                x.Event === this.gbfService.program
            );
          }
          if (activeVersion && activeVersion.SurveyId) {
            this.gbfService.setSurveyVersion({
              label: activeVersion.Version,
              value: activeVersion.SurveyId,
              version: activeVersion.SurveyId,
              versionName: activeVersion.Version,
            });
          } else {
            if (!activeVersion) {
              activeVersion = this.versions.find(
                (x: any) => x.Brand === this.gbfService.model.Brand && x.Event === this.gbfService.program
              );
            }
            if (activeVersion) {
              this.gbfService.setSurveyVersion({
                label: activeVersion.Version,
                value: activeVersion.SurveyId,
                version: activeVersion.SurveyId,
                versionName: activeVersion.Version,
              });
            } else {
              this.gbfService.setSurveyVersion({
                label: '',
                value: '',
                version: '',
                versionName: '',
              });
            }
          }
        }
      } else {
        if (!activeVersion) {
          activeVersion = this.versions.find(
            (x: any) =>
              x.CurrentSurvey === true && x.Brand === this.gbfService.model.Brand && x.Event === this.gbfService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.gbfService.setSurveyVersion({
            label: activeVersion.Version,
            value: activeVersion.SurveyId,
            version: activeVersion.SurveyId,
            versionName: activeVersion.Version,
          });
        } else {
          if (!activeVersion) {
            activeVersion = this.versions.find(
              (x: any) => x.Brand === this.gbfService.model.Brand && x.Event === this.gbfService.program
            );
          }
          if (activeVersion) {
            this.gbfService.setSurveyVersion({
              label: activeVersion.Version,
              value: activeVersion.SurveyId,
              version: activeVersion.SurveyId,
              versionName: activeVersion.Version,
            });
          } else {
            this.gbfService.setSurveyVersion({
              label: '',
              value: '',
              version: '',
              versionName: '',
            });
          }
        }
      }
    } else {
      this.gbfService.setSurveyVersion({
        label: '',
        value: '',
        version: '',
        versionName: '',
      });
    }
  }
  getVindecoderData(config: any) {
    const url = `/Vindecoder/GetVindecoderFilters`;
    this.http
      .cache()
      .get(url)
      .subscribe((res: any) => {
        this.filterService.modelsTypes = res;
        if (config.mapping === 'ybm') {
          this.models = this.gbfMapService.yearBrandModel(res);
        } else {
          this.models = this.gbfMapService.brandModelYear(res);
        }
        this.apiChange.next(config);
      });
  }
  getOrgs(config: any) {
    const foundInitialState = this.gbfConfig.filterConfig['initialStates'][config.name];
    if (foundInitialState) {
      this.gbfService[config.name + '_initialState'] = foundInitialState;
      this.gbfService[config.name + '_breadcrums'] = [foundInitialState];
    }
    const values = { dealer: { label: '', value: 'All' } };
    if (config.dependon && config.dependon.length) {
      config.dependon.forEach((d: string) => {
        values[d] = this.gbfService[d];
      });
    }
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    const orgIdentifier = h.OrgIdentifier;
    let type;
    switch (values.dealer.value) {
      case 'All':
        type = JSON.stringify(['Private', 'Corporate']);
        break;
      default:
        type = `${JSON.stringify([values.dealer.value])}`;
        break;
    }
    const url = `/reporting/orgList?orgIdentifier=${orgIdentifier}&Fields=${type}`;
    this.http
      .get(url)
      .pipe(
        map((res: any) => {
          this[config.property] = res;
          return res;
          // return this.gbfMapService.mapOrgData(res);
        })
      )
      .subscribe((res) => {
        this.orgs = res;
        this.multi_orgs = res;
        this.filterService.orgList = res;
        this.apiChange.next(config);
        const conx = this.gbfConfig.filterConfig.config.find((sconfig: any) => sconfig.name === 'multi_org');
        if (conx) {
          this.apiChange.next(conx);
        }
      });
  }
  getCheckedValues(name: string, values: any[], multi: boolean) {
    if (values && multi) {
      let checkedValue = this.gbfService[name];
      if (checkedValue && checkedValue.length > 0) {
        return values.map((x: any) => {
          const IsChecked = checkedValue.find((y: any) => y.value === x.value)
            ? checkedValue.find((y: any) => y.value === x.value).IsChecked
            : false;
          return { ...x, IsChecked, active: IsChecked };
        });
      } else {
        return values;
      }
    } else if (values && !multi) {
      const checkedValue = this.gbfService[name];
      if (checkedValue) {
        return values.map((x: any) => {
          const IsChecked = x.value === checkedValue.value ? true : false;
          return { ...x, IsChecked, active: IsChecked };
        });
      } else {
        return values;
      }
    } else {
      return [];
    }
  }
}
