import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '@reflecxfeatures/administration/types/User';
import { ForgetPasswordDTO, ResetPasswordDTO } from '../types/user';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Store } from '@ngrx/store';
import { CVPActions } from '@gstate/actions';
import { identityServerAngularConfig, identityServerTCIConfig, identityServerSSOConfig } from '@env/environment';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { IdleTimeoutService } from '@app/shared/services/idle-timeout.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from '../loader.service';
import { throwError } from 'rxjs';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}
export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = 'credentials';

@Injectable()
export class AuthenticationService {
  image = new BehaviorSubject(null);
  currentImage = this.image.asObservable();
  OnTourSettingChange: EventEmitter<boolean> = new EventEmitter();
  authData: any = null;

  isLoggedIn = false;
  isSetLoggedOut = false;
  permissions: any;
  mgr: UserManager;
  isSSO: boolean;
  tenantName = '';

  constructor(
    private notificationService: NotificationsService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public toastService: ToastService,
    private idleTimeoutService: IdleTimeoutService,
    private loaderService: LoaderService,
    private store: Store<{ EventReducer: any }>,
    public ngxPermission: NgxPermissionsService,
    private oauthService: OAuthService
  ) {
    this.init();
    // this.permissions = ngxPermission.getPermissions();
    const x = setInterval(() => {
      this.checkRefreshedToken();
    }, 30000);
    // const y = setInterval(() => { this.checkLoginStatus(); }, 7000);
  }

  checkRefreshedToken() {
    let oidcToken = JSON.parse(
      localStorage.getItem(
        'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id
      )
    );
    const isSSOLogin = JSON.parse(localStorage.getItem('isSSOLogin'));

    if (this.authData && this.authData !== null && oidcToken !== null) {
      if (this.authData.token !== oidcToken.access_token) {
        this.authData.token = oidcToken.access_token;
        this.authData.expiry = oidcToken.expires_at;

        const currentAuthData: any = JSON.parse(localStorage.getItem('authorizationData'));
        currentAuthData.token = this.authData.token;
        currentAuthData.expiry = this.authData.expiry;

        localStorage.setItem('authorizationData', JSON.stringify(currentAuthData));
      }
    } else if (isSSOLogin) {
      oidcToken = JSON.parse(
        localStorage.getItem('oidc.user:' + identityServerSSOConfig.authority + ':' + identityServerSSOConfig.client_id)
      );
      if (this.authData && this.authData !== null && oidcToken != null) {
        this.authData.token = oidcToken.access_token;
        this.authData.expiry = oidcToken.expires_at;

        const currentAuthData: any = JSON.parse(localStorage.getItem('authorizationData'));
        currentAuthData.token = this.authData.token;
        currentAuthData.expiry = this.authData.expiry;

        localStorage.setItem('authorizationData', JSON.stringify(currentAuthData));
      }
    }
  }

  changeCurrentImage(image: string) {
    this.image.next(image);
  }

  getClientId() {
    return this.authData ? this.authData.ClientId : null;
  }

  getUserId() {
    return this.authData ? this.authData.Id : null;
  }

  public getUserManager(): UserManager {
    return this.mgr;
  }

  login() {
    this.clearAuthData();
    localStorage.setItem('isSSOLogin', null);
    localStorage.setItem('ssoClient', null);
    localStorage.setItem('ssoToken', null);

    // const config = {
    //   authority: identityServerAngularConfig.authority,
    //   client_id: identityServerAngularConfig.client_id,
    //   userStore: new WebStorageStateStore({ store: window.localStorage })
    // };

    // const mgr = new UserManager(config);

    this.mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
          this.mgr.signinRedirect();
        }
      })
      .catch((e) => {
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      });
  }

  storeAuthData(response: any) {
    const data = response;

    this.checkTokenExpiry(data.expiry);
    this.authData = { token: data.access_token, expiry: data.expiry, expiry_date: this.getExpiryDate(data.expiry) };
    localStorage.setItem('authorizationData', JSON.stringify(this.authData));
    // localStorage.setItem('isSSOLogin', JSON.stringify(false));

    return this.requireAuthentication();
  }

  getExpiryDate(secs: number): Date {
    const t = new Date();
    t.setSeconds(t.getSeconds() + secs);
    return t;
  }

  isTokenExpired(expires_in: number) {
    if (!expires_in) {
      return true;
    }

    const seconds = 1000;
    const d = new Date();
    const t = d.getTime();

    if (expires_in < Math.round(t / seconds)) {
      return true;
    }
    return false;
  }

  checkTokenExpiry(expiry?: number) {
    if (expiry) {
      if (this.isTokenExpired(expiry)) {
        localStorage.clear();
        localStorage.setItem('isSSOLogin', null);
        localStorage.setItem('ssoClient', null);
        localStorage.setItem('ssoToken', null);
        this.router.navigate(['/endsession']);
      }
    } else {
      var length = localStorage.length;
      if (length) {
        var ls = JSON.parse(localStorage.getItem('authorizationData'));
        if (ls && this.isTokenExpired(ls.expiry)) {
          localStorage.clear();
          localStorage.setItem('isSSOLogin', null);
          localStorage.setItem('ssoClient', null);
          localStorage.setItem('ssoToken', null);
          this.router.navigate(['/endsession']);
        }
      }
    }
  }

  saveSsoAuthToken(response: any) {
    const currentData = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentData != null) {
      this.logUserLogout();
      this.clearAuthData();
    }

    const data = response;
    this.authData = { token: data.access_token, expiry: data.expiry };

    localStorage.setItem('authorizationData', JSON.stringify(this.authData));
    localStorage.setItem('isSSOLogin', JSON.stringify(true));
  }

  onSignIn(response: any): any {
    const user = response;
    const token = this.authData.token;
    const expiry = this.authData.expiry;
    this.authData = user;
    this.authData.token = token;
    this.authData.expiry = expiry;
    localStorage.setItem('authorizationData', JSON.stringify(this.mapData(this.authData)));
    this.store.dispatch(new CVPActions.PermissionEventAction.PermissionEvent(''));
    this.logLastUserLogout();
    return this.authData;
  }

  getTourGuide() {
    const permission = this.ngxPermission.getPermissions();
    const tourGuideAllowed = permission[Identifiers.TourGuide] ? true : false;

    if (this.authData && this.authData.UserSettings && this.IsInPermission && tourGuideAllowed) {
      return this.authData.UserSettings.TourGuide;
    } else {
      return false;
    }
    // return (this.authData && this.authData.UserSettings) ? this.authData.UserSettings.TourGuide : false;
  }

  updateMarket(user: User): Observable<any> {
    const url = '/user/market';
    return this.http.put(url, user);
  }

  getLanguage(): Observable<any> {
    const DefaultLanguage = localStorage.getItem('language');
    return this.http.get(`/multilingual/${DefaultLanguage}`);
  }

  clearAuthData() {
    this.notificationService.clearAsync().subscribe(() => {
      this.notificationService.clearAsync2().subscribe(() => {
        this.authData = null;
        localStorage.removeItem('authorizationData');
        localStorage.removeItem('configData');
      });
    });
  }

  public requireAuthentication(): Observable<any> {
    const url = '/user/userclaims';
    return this.http
      .skipErrorHandler()
      .get(url)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  public mapData(data: any) {
    delete data.FeatureIds;
    data.DefaultMarketLanguage = data.Markets[0].DefaultLanguage || environment.defaultMarketLanguage;

    data.Features = data.Features.map(function (x: any) {
      return {
        Id: x.Id,
        ClientId: x.ClientId,
        ParentId: x.ParentId,
        Name: x.Name,
        Icon: x.Icon,
      };
    });
    return data;
  }

  storeLastUserInfo() {
    this.init();
    if (this.authData) {
      localStorage.setItem('lastLoginUser', JSON.stringify(this.authData));
    }
  }

  getLastUserInfo() {
    if (localStorage.getItem('lastLoginUser') != null) {
      return localStorage.getItem('lastLoginUser');
    } else {
      return null;
    }
  }
  getUserLanguageByCode() {
    return this.authData.Markets[0].Languages.map((value: any) => {
      return value.Code;
    });
  }
  getMarketDefaultLanguage() {
    return this.authData.DefaultMarketLanguage;
  }
  getClientLogo() {
    return this.authData.ClientInfo.Logo['portal'] || '';
  }
  getClientFabIcon() {
    return this.authData.ClientInfo.Logo['fav_icon'] || '';
  }

  getClientLogoType(type: string) {
    return this.authData.ClientInfo.Logo[type];
  }
  getClientThemes() {
    return this.mapThemesUrls(this.authData.ClientInfo.Themes || []);
  }
  getClientName() {
    return this.authData.ClientName;
  }
  getClientThemeImage(theme: string, type: string) {
    const value = this.authData.ClientInfo.Themes.find((x: any) => x.Field === theme);
    if (value) {
      return value[type];
    }
    return '';
  }
  logLastUserLogout() {
    const user: any = this.getLastUserInfo();
    if (user) {
      const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/activity/logs';
      const data = [
        {
          CreatedOn: new Date(), // moment().utc().format('DD MMM YYYY hh:mm a'),
          Type: 'EXPIRED',
          CreatedBy: user.Id,
          UserName: (user.FirstName || '') + ' ' + (user.LastName || ''),
          ClientId: user.ClientId === '' ? null : user.ClientId,
          MarketId: user.MarketId === '' ? null : user.MarketId,
        },
      ];
      this.http.post(url, data);
      localStorage.setItem('lastLoginUser', null);
    }
  }
  logUserLogout() {
    this.init();
    if (this.authData) {
      const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/activity/logs';
    }
  }

  updateLanguage(language: any) {
    this.authData.DefaultLanguage = language.Code;
    this.authData.MarketId = language.MarketId;
    localStorage.setItem('authorizationData', JSON.stringify(this.authData));
    localStorage.setItem('language', language.Code);
  }

  logout(isIdleTimeout: boolean = false) {
    const isSSOLogin = JSON.parse(localStorage.getItem('isSSOLogin'));

    this.logUserLogout();
    this.clearAuthData();
    this.isSetLoggedOut = true;
    this.isLoggedIn = false;

    if (!isSSOLogin) {
      this.mgr
        .getUser()
        .then((user) => {
          if (user) {
            window.localStorage.clear();
            if (isIdleTimeout) {
              localStorage.setItem('isIdleTimeout', JSON.stringify(true));
              this.idleTimeoutService.postIdleTimoutMessage();
            } else {
              this.idleTimeoutService.postSigningOutMessage();
            }
            const tenant = this.getTenantName();
            localStorage.setItem('tenant', tenant);
            this.mgr.signoutRedirect();
          } else {
            this.isSetLoggedOut = false;
            this.router.navigate(['/login/' + this.getTenantName()]);
          }
        })
        .catch((e) => {
          this.loaderService.showMain = false;
          this.router.navigate(['/oops']);
        });
    } else {
      this.oauthService.tokenEndpoint = identityServerTCIConfig.authority + '/connect/token';
      this.oauthService.clientId = identityServerTCIConfig.client_id;
      this.oauthService.scope = identityServerTCIConfig.scope;
      this.oauthService.dummyClientSecret = identityServerTCIConfig.secret;

      this.router.navigate(['/logoutsso']);
      this.oauthService.logOut();
    }
  }

  expired() {
    this.storeLastUserInfo();
    this.clearAuthData();
  }

  changePassword(id: any, data: any): Observable<any> {
    const url = '/user/changepassword';
    return this.http.put<any>(url, data);
  }

  forgotPassword(data: ForgetPasswordDTO): Observable<any> {
    const url = '/user/forgotpassword';
    return this.http.post<any>(url, data);
  }

  validateResetToken(guid: any) {
    const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/user/link/' + guid;
    return this.http.get(url);
  }
  resetPassword(data: ResetPasswordDTO): Observable<any> {
    const url = '/user/resetpassword/';
    return this.http.put<any>(url, data);
  }

  getUserClient(id: any): Observable<any> {
    const url = '/user/userclient/';
    return this.http.get(url + id);
  }

  getClientByName(name: any): Observable<any> {
    const url = '/client/GetByName/';
    return this.http.get(url + name);
  }

  getPasswordPolicy(id: any): Observable<any> {
    const url = '/client/ispasswordpolicy/';
    return this.http.get(url + id);
  }

  IsInRole(roles: any) {
    return !!(roles.indexOf(this.authData.Role) === -1);
  }
  getTokenParams(data: any): Observable<any> {
    const url = '/user/extractparams';
    return this.http.post<any>(url, data);
  }
  isUserLoggedIn() {
    const value = !!JSON.parse(
      localStorage.getItem(
        'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id
      )
    );
    return value;
  }

  isAuthenticated() {
    const value = !!this.authData;
    return value;
  }
  IsInPermission(feature: any, permission: any) {
    return !!(
      this.authData.Features.findIndex(function (x: any) {
        return x.Feature === feature && x.FeatureOption === permission;
      }) === -1
    );
  }

  getUser() {
    // this.init();
    if (this.authData) {
      return this.authData;
    }
    return null;
  }

  getAntiForgeryToken() {
    const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/user/antiforgerytoken';
    return this.http.get(url);
  }

  getProgram() {
    return this.authData && this.authData.ClientInfo ? this.authData.ClientInfo.DealerEvent : 'NVS';
  }

  isUserExist(id: string) {
    const url = '/user/sso/' + id;
    return this.http.get(url);
  }

  ssoLogin(data: any) {
    const url = '/user/sso';
    return this.http.post(url, data);
  }

  getSSOConfiguration(name: string) {
    const url = identityServerSSOConfig.authority + '/api/v1/configuration/ssoconfiguration/' + name;
    return this.http.get(url);
  }

  createUser(user: any): Observable<any> {
    return this.http.post<any>('/user', user);
  }

  public initSSO(cId: string) {
    let clientId = identityServerSSOConfig.client_id;
    if (cId != null && cId != '') {
      clientId = cId;
    }
    const config = {
      authority: identityServerSSOConfig.authority,
      client_id: clientId,
      redirect_uri: identityServerSSOConfig.redirect_uri,
      response_type: identityServerSSOConfig.response_type,
      scope: identityServerSSOConfig.scope,
      post_logout_redirect_uri: identityServerSSOConfig.post_logout_redirect_uri,
      silent_redirect_uri: identityServerSSOConfig.silent_redirect_uri,
      accessTokenExpiringNotificationTime: identityServerSSOConfig.accessTokenExpiringNotificationTime,
      automaticSilentRenew: identityServerSSOConfig.automaticSilentRenew,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    this.mgr = new UserManager(config);
    this.isSSO = true;
  }

  // private init() {
  //   const config = {
  //     authority: identityServerAngularConfig.authority,
  //     client_id: identityServerAngularConfig.client_id,
  //     redirect_uri: identityServerAngularConfig.redirect_uri,
  //     response_type: identityServerAngularConfig.response_type,
  //     scope: identityServerAngularConfig.scope,
  //     post_logout_redirect_uri: identityServerAngularConfig.post_logout_redirect_uri,
  //     silent_redirect_uri: identityServerAngularConfig.silent_redirect_uri,
  //     accessTokenExpiringNotificationTime: identityServerAngularConfig.accessTokenExpiringNotificationTime,
  //     automaticSilentRenew: identityServerAngularConfig.automaticSilentRenew,
  //     userStore: new WebStorageStateStore({ store: window.localStorage }),
  //     clockSkew: identityServerAngularConfig.clockSkew
  //   };
  //   this.mgr = new UserManager(config);

  //   if (localStorage.getItem('authorizationData') != null) {
  //     this.authData = JSON.parse(localStorage.getItem('authorizationData'));
  //   }
  // }

  init() {
    if (this.getTenantName()) {
      const config = {
        authority: identityServerAngularConfig.authority,
        client_id: identityServerAngularConfig.client_id,
        redirect_uri: identityServerAngularConfig.redirect_uri,
        response_type: identityServerAngularConfig.response_type,
        scope: identityServerAngularConfig.scope,
        post_logout_redirect_uri: identityServerAngularConfig.post_logout_redirect_uri,
        silent_redirect_uri: identityServerAngularConfig.silent_redirect_uri,
        accessTokenExpiringNotificationTime: identityServerAngularConfig.accessTokenExpiringNotificationTime,
        automaticSilentRenew: identityServerAngularConfig.automaticSilentRenew,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        clockSkew: identityServerAngularConfig.clockSkew,
        acr_values: 'tenant:' + this.getTenantName(),
      };
      this.mgr = new UserManager(config);
    } else {
      const config = {
        authority: identityServerAngularConfig.authority,
        client_id: identityServerAngularConfig.client_id,
        redirect_uri: identityServerAngularConfig.redirect_uri,
        response_type: identityServerAngularConfig.response_type,
        scope: identityServerAngularConfig.scope,
        post_logout_redirect_uri: identityServerAngularConfig.post_logout_redirect_uri,
        silent_redirect_uri: identityServerAngularConfig.silent_redirect_uri,
        accessTokenExpiringNotificationTime: identityServerAngularConfig.accessTokenExpiringNotificationTime,
        automaticSilentRenew: identityServerAngularConfig.automaticSilentRenew,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        clockSkew: identityServerAngularConfig.clockSkew,
      };
      this.mgr = new UserManager(config);
    }
    if (localStorage.getItem('authorizationData') != null) {
      this.authData = JSON.parse(localStorage.getItem('authorizationData'));
    }
  }
  getTenantName() {
    const tenant = localStorage.getItem('tenant');
    if (tenant !== null && tenant !== '' && tenant) {
      return tenant;
    } else {
      return null;
    }
  }
  private mapThemesUrls(themes: any) {
    themes.forEach((theme: any) => {
      theme.PreviewImage = theme.PreviewImage;
    });
    return themes;
  }

  private errorHandler(response: any): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      if (response.status === 401) {
        this.router.navigate(['/endsession']);
      }
    }

    switch (response.status) {
      case 0:
        this.router.navigate(['/oops']);
        break;
      case 400:
        this.router.navigate(['/oops']);
        break;
      case 401:
        this.router.navigate(['/endsession']);
        break;
      case 404:
        this.router.navigate(['/oops']);
        break;
      case 422:
        this.router.navigate(['/oops']);
        break;
      case 429:
        this.router.navigate(['/oops']);
        break;
      case 500:
        this.router.navigate(['/oops']);
        break;
      case 501:
        this.router.navigate(['/oops']);
        break;
      case 502:
        this.router.navigate(['/oops']);
        break;
      case 503:
        this.router.navigate(['/oops']);
        break;
      case 504:
        this.router.navigate(['/oops']);
        break;
      case 505:
        this.router.navigate(['/oops']);
        break;
      case 511:
        this.router.navigate(['/oops']);
        break;
      default:
        this.router.navigate(['/oops']);
        break;
    }

    return throwError(response);
  }
}
