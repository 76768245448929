import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { isEmpty } from 'lodash';
interface Nav {
  text: string;
  icon: string;
  isActive: boolean;
  visible?: boolean;
  link: string;
  tooltip?: string;
  click?: Function;
  permission?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  showTopNav = false;
  showRightButtons = false;
  showSideNav = true;
  topNav: MenuItem[] = [];
  navnavBar: any = [];
  activeTab: MenuItem;
  sideNav: any = [];
  ReflecxAppName = 'Reflecx';
  ClearviewAppName = 'Reflecx';
  disable = false;
  questionEditState = false;
  surveyVersionArray: any = [];
  currentSurveyId: any;
  selectCurrentVersion: any;
  surveyType: any = 1;
  IsDynamicServices: boolean = false;
  theme: string = 'default';
  coachReportPrintObj = {
    ID: '',
    Region: '',
    Dealer: '',
    ContactKPI: '',
    ContactFacilitator: '',
    ContactAssignee: '',
    ContactDate: '',
    ActionPlans: '',
    Status: '',
    isPrintClick: false,
  };
  coachActionPrintObj = {
    DisplayID: '',
    Region: '',
    Dealer: '',
    ContactKPI: '',
    EventType: '',
    AssignedTo: '',
    StartDate: '',
    ApprovalDate: '',
    StartScore: '',
    CurrentScore: '',
    TargetScore: '',
    Percentage: '',
    Status: '',
    TargetDate: '',
    isPrintClick: false,
  };
  landingPageForPlatform: Array<string> = ['apple', 'alder'];
  // private themeDimension:Array<{type:string,Width:number,Height:number,aspectRatio:number}> =[]
  private themeDimension: any = {};
  private defaultDimension: any = [
    {
      type: 'LogoPath',
      Width: 100,
      Height: 50,
      aspectRatio: 2,
    },
    {
      type: 'HeaderPath',
      Width: 920,
      Height: 285,
      aspectRatio: 3.23,
    },
    {
      type: 'FaviconPath',
      Width: 16,
      Height: 16,
      aspectRatio: 1,
    },
    {
      type: 'OptionsPath',
      Width: 46,
      Height: 46,
      aspectRatio: 1,
    },
    {
      type: 'template.fromlibrary',
      Width: 650,
      Height: 352,
      aspectRatio: 1.85,
    },
  ];
  surveyStatus: string;
  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private appConfig: AppConfigurationService
  ) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(`${this.ReflecxAppName} | ${newTitle}`);
  }

  public setV(val: boolean) {
    if (this.navnavBar.length === 2) {
      this.navnavBar[1].disable = val;
    }
  }

  public setTitleClearView(newTitle: string) {
    this.titleService.setTitle(`${this.ClearviewAppName} | ${newTitle}`);
  }
  // public setNavigation(type: string) {
  //   switch (type) {
  //     case 'Transmission': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = false;
  //       this.topNav[2].isActive = false;
  //       this.topNav[3].isActive = true;
  //       break;
  //     }
  //     case 'TemplateEditor': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = false;
  //       this.topNav[2].isActive = true;
  //       this.topNav[3].isActive = false;
  //       break;
  //     }
  //     case 'Designer': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = true;
  //       this.topNav[2].isActive = false;
  //       this.topNav[3].isActive = false;
  //       break;
  //     }
  //   }
  // }
  public getThemeImageDimension(key: string): { Width: number; Height: number; aspectRatio: number } {
    if (!isEmpty(this.themeDimension) && this.themeDimension[this.theme.toLowerCase()]) {
      return this.themeDimension[this.theme.toLowerCase()].find((res: any) => {
        return res.type == key;
      });
    } else {
      console.log('Client configuration of theme dimension is missing');
      return this.defaultDimension.find((res: any) => {
        return res.type == key;
      });
    }
  }
  public getConfiguration(): void {
    let dimension: any = this.appConfig.getByIdentifier(Identifiers.ThemeDimension);
    if (dimension) {
      this.themeDimension = dimension['Fields'];
    }
  }

  public addOverlapClassToMainLoader() {
    document.querySelectorAll('div.main-loader').forEach((x: Element) => {
      x.classList.add('loader--index');
    });
  }

  public removeOverlapClassFromMainLoader() {
    document.querySelectorAll('div.main-loader').forEach((x: Element) => {
      x.classList.remove('loader--index');
    });
  }
}
