import { Component, NgModule, Input, TemplateRef, EventEmitter, Output, forwardRef, OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MultiSelect, MultiSelectItem, MultiSelectModule } from 'primeng/multiselect';
import { SharedModule, SelectItem } from 'primeng/api';

import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'r-multiSelectItem',
  template: `
    <li
      class="ui-multiselect-item ui-corner-all"
      (click)="onOptionClick($event)"
      (keydown)="onOptionKeydown($event)"
      [attr.aria-label]="option.label"
      [style.display]="visible ? 'block' : 'none'"
      [attr.tabindex]="option.disabled ? null : '0'"
      [ngStyle]="{ height: itemSize + 'px' }"
      [ngClass]="{
        'ui-state-highlight': selected,
        'ui-state-disabled': option.disabled || (maxSelectionLimitReached && !selected)
      }"
    >
      <div class="ui-chkbox ui-widget">
        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" [ngClass]="{ 'ui-state-active': selected }">
          <span class="ui-chkbox-icon ui-clickable" [ngClass]="{ 'pi pi-check': selected }"></span>
        </div>
      </div>
      <span *ngIf="!template">{{ option.label }}</span>
      <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
    </li>
  `,
})
export class RMultiSelectItem {
  @Input() option: SelectItem;

  @Input() selected: boolean;

  @Input() disabled: boolean;

  @Input() visible: boolean;

  @Input() itemSize: number;

  @Input() template: TemplateRef<any>;

  @Input() maxSelectionLimitReached: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onKeydown: EventEmitter<any> = new EventEmitter();

  onOptionClick(event: Event) {
    this.onClick.emit({
      originalEvent: event,
      option: this.option,
    });
  }

  isItemVisible(item: any) {
    return true;
  }

  onOptionKeydown(event: Event) {
    this.onKeydown.emit({
      originalEvent: event,
      option: this.option,
    });
  }
}

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RMultiSelect),
  multi: true,
};

@Component({
  selector: 'r-multiSelect',
  template: `
    <div
      #container
      [ngClass]="{
        'ui-multiselect ui-widget ui-state-default ui-corner-all': true,
        'ui-multiselect-open': overlayVisible,
        'ui-state-focus': focus,
        'ui-state-disabled': disabled
      }"
      [ngStyle]="style"
      [class]="styleClass"
      (click)="onMouseclick($event, in)"
    >
      <div class="ui-helper-hidden-accessible">
        <input
          #in
          type="text"
          readonly="readonly"
          [attr.id]="inputId"
          [attr.name]="name"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)"
          [disabled]="disabled"
          [attr.tabindex]="tabindex"
          (keydown)="onKeydown($event)"
        />
      </div>
      <div class="ui-multiselect-label-container" [title]="valuesAsString">
        <span class="ui-multiselect-label ui-corner-all">
          <ng-container *ngIf="!selectedItemsTemplate">{{ valuesAsString }}</ng-container>
          <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: { $implicit: value }"></ng-container>
        </span>
      </div>
      <div [ngClass]="{ 'ui-multiselect-trigger ui-state-default ui-corner-right': true }">
        <span class="ui-multiselect-trigger-icon ui-clickable" [ngClass]="dropdownIcon"></span>
      </div>
      <div
        *ngIf="overlayVisible"
        [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow']"
        [@overlayAnimation]="{
          value: 'visible',
          params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
        }"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
        (click)="panelClick = true"
      >
        <div
          class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix"
          [ngClass]="{ 'ui-multiselect-header-no-toggleall': !showToggleAll }"
          *ngIf="showHeader"
        >
          <ng-content select="p-header"></ng-content>
          {{ filterValue }}
          <div class="ui-multiselect-filter-container" *ngIf="filter">
            <input
              #filterInput
              type="text"
              role="textbox"
              [value]="filterValue || searchText || ''"
              (input)="onRFilter()"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all"
              [attr.placeholder]="filterPlaceHolder"
              [attr.aria-label]="ariaFilterLabel"
            />
            <span class="ui-multiselect-filter-icon pi pi-search"></span>
          </div>
          <a
            class="ui-multiselect-close ui-corner-all"
            tabindex="0"
            (click)="close($event)"
            (keydown.enter)="close($event)"
          >
            <span class="pi pi-times"></span>
          </a>
        </div>
        <div class="ui-multiselect-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'">
          <ul
            class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset"
          >
            <li class="ui-multiselect-item ui-corner-all">
              <div class="ui-chkbox ui-widget" *ngIf="showToggleAll && !selectionLimit">
                <div class="ui-helper-hidden-accessible">
                  <input
                    type="checkbox"
                    readonly="readonly"
                    [checked]="allChecked"
                    (focus)="onHeaderCheckboxFocus()"
                    (blur)="onHeaderCheckboxBlur()"
                    (keydown.space)="toggleAll($event)"
                  />
                </div>
                <div
                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                  [ngClass]="{ 'ui-state-active': allChecked, 'ui-state-focus': headerCheckboxFocus }"
                  (click)="toggleAll($event)"
                >
                  <span class="ui-chkbox-icon ui-clickable" [ngClass]="{ 'pi pi-check': allChecked }"></span>
                </div>
              </div>
              <span>Select All</span>
            </li>
            <ng-container *ngIf="!virtualScroll; else virtualScrollList">
              <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                <r-multiSelectItem
                  [option]="option"
                  [selected]="isSelected(option.value)"
                  (onClick)="onOptionClick($event)"
                  (onKeydown)="onOptionKeydown($event)"
                  [maxSelectionLimitReached]="maxSelectionLimitReached"
                  [visible]="isItemVisible(option)"
                  [template]="itemTemplate"
                ></r-multiSelectItem>
              </ng-template>
            </ng-container>
            <ng-template #virtualScrollList>
              <cdk-virtual-scroll-viewport
                #viewport
                [ngStyle]="{ height: scrollHeight }"
                [itemSize]="itemSize"
                *ngIf="virtualScroll && optionsToRender && optionsToRender.length"
              >
                <ng-container
                  *cdkVirtualFor="
                    let option of optionsToRender;
                    let i = index;
                    let c = count;
                    let f = first;
                    let l = last;
                    let e = even;
                    let o = odd
                  "
                >
                  <r-multiSelectItem
                    [option]="option"
                    [selected]="isSelected(option.value)"
                    (onClick)="onOptionClick($event)"
                    (onKeydown)="onOptionKeydown($event)"
                    [maxSelectionLimitReached]="maxSelectionLimitReached"
                    [visible]="isItemVisible(option)"
                    [template]="itemTemplate"
                    [itemSize]="itemSize"
                  ></r-multiSelectItem>
                </ng-container>
              </cdk-virtual-scroll-viewport>
            </ng-template>
            <li *ngIf="emptyOptions" class="p-multiselect-empty-message">{{ emptyFilterMessage }}</li>
            <li *ngIf="filter && optionsToRender && optionsToRender.length === 0" class="ui-multiselect-empty-message">
              {{ emptyFilterMessage }}
            </li>
          </ul>
        </div>
        <div class="ui-multiselect-footer ui-widget-content" *ngIf="footerFacet">
          <ng-content select="p-footer"></ng-content>
        </div>
      </div>
    </div>
  `,
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus',
  },
  providers: [MULTISELECT_VALUE_ACCESSOR],
})
export class RMultiSelect extends MultiSelect implements OnInit {
  @Input() emptyFilterMessage: any;
  @Input() searchText: any = '';
  @Input() debounceTimeBy: any = 1000;
  @Output() onFilterOut: EventEmitter<any> = new EventEmitter<any>();
  debouncd: any;
  panelClick = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.debouncd = debounce(function () {
      this.search();
    }, this.debounceTimeBy);
  }

  isItemVisible(item: any) {
    return true;
  }

  search() {
    const inputValue = this.filterInputChild.nativeElement.value;
    this.onFilterOut.emit(inputValue);
  }

  onRFilter() {
    this.debouncd();
  }
}

@NgModule({
  imports: [CommonModule, SharedModule, ScrollingModule],
  exports: [RMultiSelect, ScrollingModule],
  declarations: [RMultiSelect, RMultiSelectItem],
})
export class RMultiSelectModule {}
