import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { matchHeight } from './assets/js/matchHeight.js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
window['matchHeight'] = matchHeight;
JSON['makeStringify'] = function (obj: any) {
  const cache: any = [];
  const s = JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
    }
    return value;
  });
  return s;
};
if (environment.production) {
  enableProdMode();
}
const bootstrap = () =>
  platformBrowserDynamic().bootstrapModule(AppModule, [
    {
      defaultEncapsulation: ViewEncapsulation.None,
    },
  ]);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
  }
} else {
  bootstrap().catch((err) => {
    console.log(err);
  });
}
