import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { identityServerAngularConfig } from '@env/environment';

import { UserManager, WebStorageStateStore } from 'oidc-client';
import { LoaderService } from './../../core/loader.service';
import { Router } from '@angular/router';
import { AuthServiceCleaner } from './../../shared/services/auth.service.cleaner';

@Component({
  selector: 'app-reset-login',
  templateUrl: './reset-login.component.html',
  styleUrls: ['./reset-login.component.sass'],
})
export class ResetLoginComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private authClearService: AuthServiceCleaner,
    private router: Router
  ) {}

  ngOnInit() {
    this.loaderService.showMain = false;

    // const config = {
    //   authority: identityServerAngularConfig.authority,
    //   client_id: identityServerAngularConfig.client_id,
    //   userStore: new WebStorageStateStore({ store: window.localStorage })
    // }

    // const mgr = new UserManager(config);

    const mgr = this.authService.getUserManager();

    mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.authClearService.SetLogoutEvent();
        } else {
          window.localStorage.clear();
          this.authService.isSetLoggedOut = true;
          this.router.navigate(['/login']);
        }
      })
      .catch((e) => {
        window.localStorage.clear();
        this.loaderService.showMain = false;
        // window.location.href = window.origin;
        window.location.href = '/';
      });
  }
}
