<div class="maintenance-wrapper">
  <div class="maintenance">
    <div class="maintenance__inner">
      <div class="icon-wrapper">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 64 64"
          style="enable-background: new 0 0 64 64"
          xml:space="preserve"
        >
          <path
            class="info-icon"
            d="M32,1C14.9,1,1,14.9,1,32c0,17.1,13.9,31,31,31s31-13.9,31-31C63,14.9,49.1,1,32,1z M32,57C18.2,57,7,45.8,7,32
	C7,18.2,18.2,7,32,7c13.8,0,25,11.2,25,25C57,45.8,45.8,57,32,57z M32,14.8c2.9,0,5.3,2.4,5.3,5.3s-2.4,5.3-5.3,5.3
	s-5.3-2.4-5.3-5.3S29.1,14.8,32,14.8z M39,46.5c0,0.8-0.7,1.5-1.5,1.5h-11c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5H28v-8
	h-1.5c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5h8c0.8,0,1.5,0.7,1.5,1.5V42h1.5c0.8,0,1.5,0.7,1.5,1.5V46.5z"
          />
        </svg>
      </div>
      <h1>Attention! Time Synchronization Required.</h1>
      <p>
        Please sync your local system time with standard time. You can't proceed further if your local time is not sync
        with your standard time.
      </p>
      <p>
        Current Time Difference:<b> {{ timeDiff }}</b>
      </p>
    </div>
  </div>
</div>
