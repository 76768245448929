import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaginationHeader } from '../types/paginationData';
import { environment } from '@env/environment';
import { RequestCache } from './request.cache.service';
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    environment.loaderStyle = 'main';

    if (this.detectCachableRequest(request)) {
      // request = request.clone({ setParams: { observe: 'response' } });
      return next.handle(request).pipe(
        map((event) => {
          if (event instanceof HttpResponse && event.headers) {
            event = event.clone({ body: this.resolveReferences(event) });
            if (environment.enableCache) {
              this.cache.put(request, event);
            }
          }
          return event;
        })
      );
    }

    return next.handle(request);
  }

  // private checkParam(params: HttpParams): boolean {
  //   if (params.has('PageNo') || params.has('PageSize')) {
  //     return true;
  //   }
  //   return false;
  // }

  private detectCachableRequest(request: HttpRequest<any>): boolean {
    const method = request.method.toLowerCase();
    return (
      !/^(http|https):/i.test(request.url) &&
      (method === 'get' ||
        (method === 'post' && request.body.hasOwnProperty('PageNo') && request.body.hasOwnProperty('PageSize')))
    );
  }

  private resolveReferences(event: HttpResponse<any>): any {
    if (event.headers) {
      const paginatedHeader = event.headers.get('x-pagination');
      if (paginatedHeader) {
        const data = JSON.parse(paginatedHeader) as PaginationHeader;
        return {
          Response: {
            PageNo: data.CurrentPage,
            PageSize: data.PageSize,
            Total: data.TotalCount,
            Data: event.body,
          },
          Status: event.status,
        };
      }
    }
    return event.body;
  }
}
