<div [ngStyle]="style" [ngClass]="'ui-chkbox ui-widget'" [class]="styleClass">
  <div class="ui-helper-hidden-accessible">
    <input
      #cb
      type="checkbox"
      [attr.id]="inputId"
      [name]="name"
      [value]="value"
      [checked]="checked"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [ngClass]="{ 'ui-state-focus': focused }"
      (change)="handleChange($event)"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
    />
  </div>
  <div
    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
    (click)="onClick($event, cb, true)"
    [ngClass]="{ 'ui-state-active': checked, 'ui-state-disabled': disabled, 'ui-state-focus': focused }"
  >
    <span class="ui-chkbox-icon ui-clickable" [ngClass]="{ 'pi pi-check': checked }"></span>
  </div>
</div>
<label
  (click)="onClick($event, cb, true)"
  [class]="labelStyleClass"
  [ngClass]="{
    'ui-chkbox-label': true,
    'ui-label-active': checked,
    'ui-label-disabled': disabled,
    'ui-label-focus': focused
  }"
  [attr.for]="inputId"
  ><ng-content></ng-content
></label>
