import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { identityServerAngularConfig } from '@env/environment';

import { UserManager, WebStorageStateStore } from 'oidc-client';
import { LoaderService } from './../../core/loader.service';
import { AuthServiceCleaner } from './../../shared/services/auth.service.cleaner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.sass'],
})
export class EndSessionComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private authClearService: AuthServiceCleaner,
    private router: Router
  ) {}

  ngOnInit() {
    this.loaderService.showMain = false;

    // const config = {
    //   authority: identityServerAngularConfig.authority,
    //   client_id: identityServerAngularConfig.client_id,
    //   userStore: new WebStorageStateStore({ store: window.localStorage })
    // };

    // const mgr = new UserManager(config);

    const mgr = this.authService.getUserManager();
    mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.authClearService.SetLogoutEvent();
        } else {
          window.localStorage.clear();
          this.authService.isSetLoggedOut = false;
          this.router.navigate(['/login']);
        }
      })
      .catch((e) => {
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      });
  }
}
