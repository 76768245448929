import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalFilterMapService {
  public yearBrandModel(res: any) {
    const mModelTypes: any = [];
    const years: any[] = [];
    res.forEach((m: any) => {
      const brands: any = {
        Label: m.label,
        Name: m.label,
        children: new Array<any>(),
        mProps: {
          Model: '',
          Brand: m.label,
          Year: '', // m.year
        },
        hasChild: true,
      };
      if (m.children && m.children) {
        brands.children = m.children.map((model: any) => {
          const mm: any = {
            Label: model.label,
            Name: model.label,
            mProps: {
              Model: '', // model.label,
              Brand: '', // brands.Name,
              Year: '', // model.year
            },
          };
          if (model.year && Array.isArray(model.year)) {
            model.hasChild = true;
            model.year.forEach((my: any) => {
              if (!years.find((x: any) => x.Name === my.year)) {
                years.push({
                  Label: my.year,
                  children: [],
                  Name: my.year,
                  mProps: {
                    Model: '', // model.label,
                    Brand: '', // brands.Name,
                    Year: my.year,
                  },
                  hasChild: false,
                });
              }
            });
          }
          return mm;
        });
      }
      years.forEach((x: any) => {
        brands.mProps.Year = x.Name;
        brands.parent = x.Name;
        brands.Name = x.Label + '_' + brands.Label;
        brands.children = brands.children.map((model: any) => {
          model.parent = x.Label + '_' + brands.Label;
          model.mProps = {
            Model: model.Name,
            Brand: brands.Label,
            Year: x.Name,
          };
          return model;
        });
        return x.children.push(JSON.parse(JSON.stringify(brands)));
      });
    });
    return years.sort((a: any, b: any) => {
      return parseInt(b.Label, 0) - parseInt(a.Label, 0);
    });
  }
  public mapOrgData(res: any) {
    const orgHierarchy: any = [];
    const hierarchy = this.getNodes(1, 'null', res);
    for (let i = 0; i < hierarchy.length; i++) {
      orgHierarchy.push(hierarchy[i]);
    }
    return orgHierarchy;
  }
  brandModelYear(res: any) {
    const mModelTypes: any = [];
    res.forEach((m: any) => {
      const brands: any = {
        id: m.label,
        Label: m.label,
        Name: m.label,
        children: new Array<any>(),
        mProps: {
          Model: '',
          Brand: m.label,
          Year: '',
        },
      };
      if (m.children && m.children) {
        brands.hasChild = true;
        brands.children = m.children.map((model: any) => {
          const mm: any = {
            id: m.label + '_' + model.label,
            Label: model.label,
            Name: model.label,
            parent: m.label,
            mProps: {
              Model: model.label,
              Brand: brands.Name,
              Year: '',
            },
          };
          if (model.year && Array.isArray(model.year)) {
            mm.hasChild = false;
            mm.children = model.year.map((my: any) => {
              return {
                id: m.label + '_' + model.label + '_' + my.year,
                Label: my.year,
                Name: my.year,
                parent: m.label + '_' + model.label,
                mProps: {
                  Model: model.label,
                  Brand: brands.Name,
                  Year: my.year,
                },
                hasChild: false,
              };
            });
          }
          return mm;
        });
      }
      mModelTypes.push(brands);
    });
    return mModelTypes;
  }
  searchNode(level: number, name: string, dataset: any[]): any {
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].Level === level) {
        for (let j = 0; j < dataset[i].Data.length; j++) {
          if (dataset[i].Data[j].Parent.toLowerCase() === name.toLowerCase()) {
            for (let k = 0; k < dataset[i].Data[j].Nodes.length; k++) {
              dataset[i].Data[j].Nodes[k].parent = dataset[i].Data[j].Parent;
            }
            return dataset[i].Data[j].Nodes;
          }
        }
      }
    }
    return [];
  }
  getNodesByName(level: number, name: string, dataset: any[]) {
    const obj = this.searchNodeByName(level, name, dataset);
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].children = this.getNodesByName(level + 1, element.Name, dataset);
      });
    }
    return obj;
  }
  searchNodeByName(level: number, name: string, dataset: any[]): any {
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].Level === level) {
        for (let j = 0; j < dataset[i].Data.length; j++) {
          for (let k = 0; k < dataset[i].Data[j].Nodes.length; k++) {
            if (dataset[i].Data[j].Nodes[k].Name === name) {
              return dataset[i].Data[j].Nodes;
            }
          }
        }
      }
    }
    return [];
  }
  getNodes(level: number, name: string, dataset: any) {
    const obj = this.searchNode(level, name, dataset);
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].type = element.type;
        obj[index].children = this.getNodes(level + 1, element.Name, dataset);
      });
    }
    return obj;
  }
  mapCategories(arr: any) {
    let tree: any = [];
    arr.forEach((element: any) => {
      switch (element.Level) {
        case 2: {
          tree = element.Data[0].Nodes;
          tree.forEach((ele: any) => {
            ele.Level = element.Level;
            ele.level = element.Level;
            ele.id = element.Name;
            ele.Id = element.Name;
            ele.parent = 'null';
            ele.CategoryId = ele.Name;
          });
          break;
        }
        case 3: {
          element.Data.forEach((l2: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree) {
                if (tree[i].Name === l2.Parent) {
                  tree[i]['children'] = l2.Nodes;
                  tree[i]['children'].forEach((ele: any) => {
                    ele.Level = 2;
                    ele.level = 2;
                    ele.parent = l2.Parent;
                    ele.id = element.Name;
                    ele.Id = element.Name;
                    ele.CategoryId = ele.Name;
                  });
                }
              }
            }
          });
          break;
        }
        case 4: {
          element.Data.forEach((l3: any) => {
            if (tree) {
              for (let i = 0; i < tree.length; i++) {
                if (tree[i].children) {
                  for (let j = 0; j < tree[i].children.length; j++) {
                    if (tree[i].children[j].Name === l3.Parent) {
                      tree[i].children[j]['children'] = l3.Nodes;
                      tree[i].children[j]['children'].forEach((ele: any) => {
                        ele.Level = 3;
                        ele.level = 3;
                        ele.parent = l3.Parent;
                        ele.id = ele.Name;
                        ele.Id = ele.Name;
                        ele.CategoryId = ele.Name;
                      });
                    }
                  }
                }
              }
            }
          });
          break;
        }
      }
    });
    return tree;
  }
}
