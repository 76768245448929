<div class="login-page">
  <div class="col-left">
    <form [formGroup]="resetform" style="height: 100%">
      <div class="login-form">
        <div class="form">
          <div class="form__inner">
            <div class="form__header">
              <h3>Reset Password</h3>
              <h4>Enter your new password</h4>
            </div>

            <label>NEW PASSWORD</label>
            <fieldset class="form__group">
              <div class="password-strength">
                <input
                  type="Password"
                  formControlName="newPassword"
                  (keyup)="onPaswordChange($event.target.value)"
                  class="form__input"
                  autocomplete="new-form"
                  placeholder="{{ 'Enter new password' }}"
                />
                <i class="fal fa-lock-alt form__icon"></i>
                <div class="password-strength-bar">
                  <div *ngIf="strengthColor == 'red'" class="password-strength-block weak"></div>

                  <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>
                  <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>

                  <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                  <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                  <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                </div>
              </div>

              <div class="field__error" *ngIf="submitted">
                <p *ngIf="f.newPassword.errors && f.newPassword.errors?.required">
                  {{ 'New password is required.' }}
                </p>
                <p *ngIf="!f.newPassword.errors?.required && passwordPolicy != null && isPasswordPolicyError">
                  {{ passwordPolicyError }}
                </p>
              </div>
            </fieldset>
            <br />
            <label>RETYPE PASSWORD</label>
            <fieldset class="form__group">
              <input
                type="Password"
                formControlName="confirmPassword"
                class="form__input"
                autocomplete="new-form"
                placeholder="{{ 'Enter confirm password' }}"
              />
              <i class="fal fa-lock-alt form__icon"></i>

              <div class="field__error" *ngIf="submitted">
                <p *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.required">
                  {{ 'Confirm password is required.' }}
                </p>
                <p *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.mustMatch">
                  {{ 'Password must Match.' }}
                </p>
              </div>
            </fieldset>

            <fieldset class="form__group actions">
              <div class="auth-button">
                <button
                  class="btn btn--secondary btn--big"
                  type="button"
                  [disabled]="isSubmit"
                  (click)="resetPassword()"
                >
                  CHANGE
                </button>
              </div>
            </fieldset>
            <div class="row" *ngIf="passwordPolicy != null">
              <div class="password-hint">
                <p>Password Must:</p>
                <ul>
                  <li *ngIf="passwordPolicy.IsUpperCase">New Password must have at least one upper case character</li>
                  <li *ngIf="passwordPolicy.IsLowerCase">Must have at least one lower case character</li>
                  <li *ngIf="passwordPolicy.IsSpecialCharacter">Must have at least one special character</li>
                  <li *ngIf="passwordPolicy.IsDigit">Must have at least one digit</li>
                  <li *ngIf="passwordPolicy.Length > 0">
                    Password must be minimum {{ passwordPolicy.Length }} characters long
                  </li>
                  <li *ngIf="passwordPolicy.LastUsedPassword > 0">
                    Shouldn’t be the last {{ passwordPolicy.LastUsedPassword }} used passwords
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-right" *ngIf="imagePath" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
  <div class="col-right" *ngIf="imagePath == undefined"></div>
</div>
