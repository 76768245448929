export class Permission {
  // MENU
  public static readonly MENU_GLOBAL_DASHBOARD = 'reporting_globaldashboard';
  public static readonly MENU_MAIN_DASHBOARD = 'reporting_maindashboard';
  public static readonly MENU_HOTALERT = 'reporting_hotalerts';
  public static readonly MENU_REPORTS_SECTION = 'reporting_customreports';
  public static readonly MENU_TEXT_ANALYTICS = 'reporting_textanalytics';
  public static readonly MENU_REPORT_GENERATOR = 'reporting_reportgenerator';
  public static readonly MENU_CROSSTAB = 'reporting_crosstabs';
  public static readonly MENU_APPEALS = 'reporting_appeals';
  public static readonly MENU_Filter = 'reporting_showfilter';
  public static readonly MENU_ProgramTabs = 'reporting_showprogramtab';
  public static readonly MENU_DATASOURCE = 'datasource';
  public static readonly MENU_SURVEY = 'survey';
  public static readonly MENU_TRANSMISSION = 'transmission';
  public static readonly MENU_INSIGHT = 'insights';
  // public static readonly MENU_APPEALS = 'appeals';
  public static readonly MENU_LIBRARY = 'LibraryModule';
  public static readonly MENU_USER = 'user';
  public static readonly MENU_VOC = 'reporting_voc';
  public static readonly MENU_SOCIALMEDIA = 'reporting_socialmedia';
  public static readonly MENU_VOCDEMO = 'reporting_demovoc';

  // GLOBAL DASHBOARD
  public static readonly GLOBALDASHBOARD_SALES_QUESTIONS_WIDGET = 'reporting_globaldashboard_salesquestionwidget';
  public static readonly GLOBALDASHBOARD_AREA_OF_IMPROVEMENT_WIDGET =
    'reporting_globaldahsboard_areaofimprovement_widget';
  public static readonly GLOBALDASHBOARD_KPI_WIDGET = 'reporting_globaldahsboard_kpi_widget';
  public static readonly GLOBALDASHBOARD_PERFORMANCE_TREND_WIDGET = 'reporting_globaldahsboard_performancetrend_widget';
  public static readonly GLOBALDASHBOARD_RECOMMENDED_QUESTIONS_WIDGET =
    'reporting_globaldahsboard_recommendedquestions_widget';
  public static readonly GLOBALDASHBOARD_SURVEYCOMPLETION_WIDGET =
    'reporting_globaldahsboard_surveycompletionreport_widget';
  public static readonly GLOBALDASHBOARD_TOPBOTTOM_PERFORMANCE_WIDGET =
    'reporting_globaldahsboard_topbottomperformance_widget';
  public static readonly GLOBALDASHBOARD_VOC_SENTIMENT_WIDGET = 'reporting_globaldahsboard_vocsentimentcount_widget';
  public static readonly GLOBALDASHBOARD_YEAR_OVER_YEAR_WIDGET = 'reporting_globaldahsboard_yearoveryear_widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERJOURNEY_WIDGET = 'reporting_globaldahsboard_customerJourney_Widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERCOMMENTS_WIDGET = 'reporting_globaldahsboard_customercomments_widget';
  public static readonly GLOBALDASHBOARD_RECOMMENDED_ACTION_AP_BUTTON =
    'reporting_globaldahsboard_recommendedactions_widget_ap_button';
  public static readonly GLOBALDASHBOARD_RECOMMENDED_ACTION_Widget =
    'reporting_globaldahsboard_recommendedactions_widget';
  public static readonly GLOBALDASHBOARD_TOP_5_CLOSED_LOOP_ALERTS = 'reporting_globaldahsboard_top_5_closed_alerts';
  public static readonly GLOBALDASHBOARD_KEY_PERFORMANCE = 'reporting_globaldahsboard_keyperformance_widget';
  public static readonly GLOBALDASHBOARD_KEY_PERFORMANCE_INDICATOR =
    'reporting_globaldahsboard_keyperformanceindicators_widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERQUESTIONCOMMENT = 'reporting_globaldashboard_customerquestioncomment';

  //HOT ALERT DASHBOARD
  public static readonly GLOBALDASHBOARD_HOTALERT_WIDGET = 'reporting_globaldahsboard_hotalerts_widget';
  public static readonly HOTALERT_TOTALALERTS_WIDGET = 'reporting_hotalert_totalalerts_widget';
  public static readonly HOTALERT_ALERTSEFFICIENCY_WIDGET = 'reporting_hotalert_alertsefficiency_widget';
  public static readonly HOTALERT_TOPBOTTOM_PERFORMANCE_WIDGET = 'reporting_hotalert_topbottomperformers_widget';
  public static readonly HOTALERT_REASONSESCALATIONS_WIDGET = 'reporting_hotalert_reasonsescalations_widget';
  public static readonly HOTALERT_TOPRESOLUTIONS_WIDGET = 'reporting_hotalert_top5resolutions_widget';

  // REPORTING
  public static readonly REPORT_CONSTACTSTATS = 'reporting_customreports_contactstatistics';
  public static readonly REPORT_ORGANIZATION_SUMMARY = 'reporting_customreports_organizationsummary';
  public static readonly REPORT_SURVEYLIST = 'reporting_customreports_surveylist';
  public static readonly REPORT_PERFORMANCE_TREND = 'reporting_customreports_performancetrend';
  public static readonly REPORT_MANIPULATION_REPORT = 'reporting_customreports_manipulationreport';
  public static readonly REPORT_PORTALTRAFFIC = 'reporting_customreports_portaltraffic';
  public static readonly REPORT_LOGIN_SUMMARY = 'reporting_customreports_portaltraffic_loginsummary';
  public static readonly REPORT_PORTALTRAFFIC_LOGINDETAIL = 'reporting_customreports_portaltraffic_logintrafficdetail';
  public static readonly REPORT_PORTALTRAFFIC_LOGINSUMMARY =
    'reporting_customreports_portaltraffic_logintrafficsummary';
  public static readonly REPORT_HOTALERT_LIST = 'reporting_hotalerts_hotalertlist';

  // TEXT ANALYTIC / VOC
  //public static readonly REPORT_TEXTANALYTIC_VOCDASHBOARD = 'reporting_textanalytics_vocdashboard';
  public static readonly REPORT_TEXTANALYTIC_VOCDASHBOARD = 'reporting_textanalytics';
  public static readonly REPORT_TEXTANALYTIC_TREEREPORT = 'reporting_textanalytics_treereport';
  public static readonly REPORT_TEXTANALYTIC_COMMENTLIST = 'reporting_textanalytics_commentlist';
  public static readonly REPORT_TEXTANALYTIC_CATEGORYCONFIGURATION = 'reporting_textanalytics_categoryconfiguration';
  public static readonly REPORT_TEXTANALYTIC_VOCINTERFACE = 'reporting_textanalytics_vocinterface';

  public static readonly VOCDASHBOARD_SENTIMENT_BY_REGION_WIDGET = 'reporting_vocdashboard_sentimentbyregion';
  public static readonly VOCDASHBOARD_SENTIMENT_COUNT_WIDGET = 'reporting_vocdashboard_sentiment_count';
  public static readonly VOCDASHBOARD_CATEGORY_SCORE_CORRELATION_WIDGET =
    'reporting_vocdashboard_category_score_correlation';
  public static readonly VOCDASHBOARD_CATEGORIES_DISPLAYED_OVER_TIME_PERIOD_WIDGET =
    'reporting_vocdashboard_categories_displayed_over_time_period';
  public static readonly VOCDASHBOARD_CATEGORY_FREQUENCY_AND_SCORE_CORELATION_WIDGET =
    'reporting_vocdashboard_category_frequency_and_score_corelation';
  public static readonly VOCDASHBOARD_CATEGORY_SUMMARY_WIDGET = 'reporting_vocdashboard_category_summary';
  public static readonly VOCDASHBOARD_SENTIMENT_TREND_WIDGET = 'reporting_vocdashboard_sentiment_trend';
  public static readonly VOCDASHBOARD_TRENDING_TOPICS_WIDGET = 'reporting_vocdashboard_trending_topics';
  public static readonly VOC_SHOW_UNASSIGNED_COMMENTS = 'reporting_show_unassigned_comments';

  // SOCIAL MEDIA
  public static readonly REPORT_SOCIALMEDIA_DASHBOARD = 'reporting_sm_dashboard';
  public static readonly REPORT_SOCIALMEDIA_RANKINGREPORT = 'reporting_sm_ranking_report';
  public static readonly REPORT_SOCIALMEDIA_REVIEWLIST = 'reporting_sm_review_list_report';
  public static readonly REPORT_SOCIALMEDIA_MASTERLIST = 'reporting_sm_master_list_report';
  public static readonly REPORT_SOCIALMEDIA_DEALERCOMPETITORS = 'reporting_sm_competitors_report';
  public static readonly REPORT_SOCIALMEDIA_TREEREPORT = 'reporting_socialmedia_treereport';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_RESPONSE_TIME_WIDGET =
    'reporting_vocdashboard_review_response_time';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_RESPONSE_RATE_WIDGET =
    'reporting_vocdashboard_review_response_rate';
  public static readonly SOCIALMEDIADASHBOARD_TOTAL_REVIEWS_WIDGET = 'reporting_vocdashboard_total_reviews';
  public static readonly SOCIALMEDIADASHBOARD_RATINGS_OVER_TIME = 'reporting_sm_ratings_over_time';
  public static readonly SOCIALMEDIADASHBOARD_REVIEWS_OVER_TIME = 'reporting_sm_reviews_over_time';
  public static readonly SOCIALMEDIADASHBOARD_TOP_BOTTOM_PERFORMERS = 'reporting_sm_top_bottom_performers';
  public static readonly SOCIALMEDIADASHBOARD_RATINGS_DISTRIBUTION = 'reporting_sm_ratings_distribution';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_SITES_DISTRIBUTION = 'reporting_sm_review_sites_distribution';
  public static readonly SOCIALMEDIADASHBOARD_NOT_RESPONDED_REVIEWS = 'reporting_sm_not_responded_reviews';
  public static readonly SOCIALMEDIADASHBOARD_KPI_AVERAGE_RATINGS = 'reporting_sm_kpi_average_ratings';
  public static readonly SOCIALMEDIADASHBOARD_KPI_RESPONSE_RATE = 'reporting_sm_kpi_response_rate';
  public static readonly SOCIALMEDIADASHBOARD_KPI_RESPONSE_TIME = 'reporting_sm_kpi_response_time';
  public static readonly SOCIALMEDIADASHBOARD_KPI_TOTAL_REVIEWS = 'reporting_sm_kpi_total_reviews';
  public static readonly SOCIALMEDIADASHBOARD_DEALER_PROFILE = 'reporting_sm_dealer_profile';
  public static readonly SOCIALMEDIADASHBOARD_REGIONAL_MAP = 'reporting_socialmedia_regionalmap';
  public static readonly SOCIALMEDIADASHBOARD_CATEGORY_DISPLAY_OVER_TIME =
    'reporting_socialmedia_categorydisplayedovertime';
  public static readonly SOCIALMEDIADASHBOARD_SENTIMENT_COUNT = 'reporting_socialmedia_sentimentcount';
  public static readonly SOCIALMEDIADASHBOARD_CATEGORY_SUMMARY = 'reporting_socialmedia_categorysummary';
  public static readonly SOCIALMEDIADASHBOARD_SENTIMENT_TREND = 'reporting_socialmedia_sentimenttrend';
  public static readonly SOCIALMEDIADASHBOARD_TRENDING_TOPICS = 'reporting_socialmedia_trendingtopics';
  public static readonly SOCIALMEDIA_SHOW_UNASSIGNED_COMMENTS = 'reporting_show_unassigned_comments_on_sm';

  public static readonly REPORTING_ALLOW_COMPETITOR_COLUMN_GENERIC = 'reporting_allow_column_';

  // VOC DEMO
  public static readonly REPORT_VOCDEMO_TREEREPORT = 'reporting_vocdemo_treereport';
  public static readonly REPORT_VOCDEMO_TEXTCLASSIFICATION = 'reporting_vocdemo_textclassification';
  public static readonly REPORT_VOCDEMO_AIKNOWLEDGE = 'reporting_vocdemo_aiknowledge';
  public static readonly REPORT_VOCDEMO_AUDIOSENTIMENT = 'reporting_vocdemo_audiosentiment';

  // Coach
  public static readonly REPORT_COACH_ACTIONPLAN = 'reporting_coach_actionplan';
  public static readonly REPORT_COACH_CONTACTREPORT = 'reporting_coach_contactreport';
  public static readonly REPORT_COACH = 'reporting_coach';

  // REPORT GENERATOR
  public static readonly REPORT_REPORTGENERATOR_MYREPORT = 'reporting_reportgenerator_myreport';

  // FILTERS
  public static readonly FILTER_CONTACTSTATS_VIEWSELECTION = 'reporting_customreports_contactstatistics_viewselection';
  public static readonly FILTER_CONTACTSTATS_YEARSELECTION = 'reporting_customreports_contactstatistics_yearselection';
  public static readonly REPORTING_TRANSMISION_MONITORING = 'reporting_transmission_monitoring';
  public static readonly REPORTING_SHOW_DEALERTYPE_DROPDOWN = 'reporting_showdealerType_Dropdown';
}
