import { CommonModule } from '@angular/common';
import {
  NgModule,
  forwardRef,
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  AfterViewChecked,
  ChangeDetectionStrategy,
  Injector,
} from '@angular/core';
import { RadioButton, RadioControlRegistry } from 'primeng/radiobutton';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RfRadioButton),
  multi: true,
};

@Component({
  selector: 'rf-radioButton',
  templateUrl: './radiobutton.html',
  providers: [RADIO_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RfRadioButton extends RadioButton implements AfterViewChecked {
  constructor(public cdr: ChangeDetectorRef, private _injector: Injector, private _registry: RadioControlRegistry) {
    super(cdr, _injector, _registry);
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [RfRadioButton],
  declarations: [RfRadioButton],
})
export class RfRadioButtonModule {}
