import {
  ISentimentAnalysis,
  ISentimentCount,
  ICategorySummaryResponse,
  ICategoryScoreCorrelationResponse,
  TreeNode,
  ICommentsBusinessArea,
  ISurveyResponseResponse,
  IAspectCategoriesResponse,
  INotRespondedReviews,
  ITreeReportResponse,
  ISentimentDropdownOptions,
  ITrendingTopicsResponse,
} from './widget-interface';
import { type } from 'os';

export const sentimentAnalysisData: ISentimentAnalysis[] = [
  {
    name: 'service (5007)',
    weight: 2,
  },
  {
    name: 'great (2824)',
    weight: 3,
  },
  {
    name: 'friendly (2569)',
    weight: 4,
  },
  {
    name: 'vehicle (2398)',
    weight: 5,
  },
  {
    name: 'sales (2336)',
    weight: 2,
  },
  {
    name: 'good (2318)',
    weight: 3,
  },
  {
    name: 'service (2088)',
    weight: 4,
  },
  {
    name: 'staff (2068)',
    weight: 3,
  },
  {
    name: 'experience (2005)',
    weight: 3,
  },
  {
    name: 'tres (1732)',
    weight: 1,
  },
];

// export const trendingTopicsResponse: ITrendingTopicsResponse = [{

//     Sentiment: 'Positive',
//     Topics: [
//                 {
//                     Title: 'service (2)', Count: 2
//                 },
//                 {
//                     Title: 'great (3)', Count: 3
//                 },
//                 {
//                     Title: 'friendly (4)', Count: 4
//                 },
//                 {
//                     Title: 'vehicle (5)', Count: 5
//                 },
//                 {
//                     Title: 'sales (2)', Count: 2
//                 },
//                 {
//                     Title: 'good (3)', Count: 3
//                 },
//                 {
//                     Title: 'staff (3)', Count: 3
//                 },
//                 {
//                     Title: 'experience (3)', Count: 3
//                 },
//                 {
//                     Title: 'tres (1)', Count: 1
//                 }
//             ]
//         },
//         {
//             Sentiment: 'Negative',
//             Topics: [
//                 {
//                     Title: 'service (2)', Count: 2
//                 },
//                 {
//                     Title: 'great (3)', Count: 3
//                 },
//                 {
//                     Title: 'friendly (4)', Count: 4
//                 },
//             ]
//         },
//         {
//             Sentiment: 'Neutral',
//             Topics: [
//                 {
//                     Title: 'good (1)', Count: 1
//                 },
//                 {
//                     Title: 'service (2)', Count: 2
//                 },
//                 {
//                     Title: 'staff (3)', Count: 3
//                 },
//                 {
//                     Title: 'experience (4)', Count: 4
//                 },
//                 {
//                     Title: 'tres (5)', Count: 5
//                 },
//             ]
//         }
//     ];

export const sentimentCountData: ISentimentCount[] = [
  { Title: 'Positive', Count: 12111, Percent: 0, Color: 'red', Order: 1 },
  { Title: 'Neutral', Count: 1607, Percent: 0, Color: 'red', Order: 2 },
  { Title: 'Negative', Count: 1855, Percent: 0, Color: 'red', Order: 3 },
];

export const notRespondedReviewsData: INotRespondedReviews[] = [
  { Title: 'Google', Count: 2233, Percent: 0, Color: 'black', Order: 1 },
  { Title: 'Yelp', Count: 600, Percent: 0, Color: 'green', Order: 2 },
  { Title: 'Dater', Count: 200, Percent: 0, Color: 'blue', Order: 3 },
];
export const KPIDropdownOptions: any[] = [
  {
    label: 'KPI Score',
    value: 'KPI Score',
    surveyTypes: ['Sales', 'CPO', 'Service', 'Collision'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'Sales Consultant Index Score',
    value: 'Sale Consultant Index',
    surveyTypes: ['Sales', 'CPO'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'NPS KPI',
    value: 'Voc Nps Kpi',
    surveyTypes: ['Sales', 'CPO', 'Service'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'Service Advisor Index',
    value: 'Service Advisor Index Score',
    surveyTypes: ['Service'],
    brands: ['Vans'],
  },
  {
    label: 'Service Advisor Index',
    value: 'Service Advisor Index',
    surveyTypes: ['Service'],
    brands: ['Mercedes-Benz', 'smart'],
  },
];
export const KPIDropdownOptionsCategoryFrequency: any[] = [
  {
    label: 'KPI Score',
    value: 'KPI Score',
    surveyTypes: ['Sales', 'CPO', 'Service', 'Collision'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'Sales Consultant Index Score',
    value: 'Sale Consultant Index',
    surveyTypes: ['Sales', 'CPO'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'NPS KPI',
    value: 'Voc Nps Kpi',
    surveyTypes: ['Sales', 'CPO', 'Service'],
    brands: ['Mercedes-Benz', 'smart', 'Vans'],
  },
  {
    label: 'Service Advisor Index',
    value: 'Service Advisor Index Score',
    surveyTypes: ['Service'],
    brands: ['Vans'],
  },
  {
    label: 'Service Advisor Index',
    value: 'Service Advisor Index',
    surveyTypes: ['Service'],
    brands: ['Mercedes-Benz', 'smart'],
  },
];
export const sentimentDropdownOptions: ISentimentDropdownOptions[] = [
  {
    label: 'All Sentiment',
    value: null,
  },
  {
    label: 'Positive',
    value: 0,
  },
  {
    label: 'Neutral',
    value: 1,
  },
  {
    label: 'Negative',
    value: 2,
  },
  {
    label: 'Unclassified',
    value: 3,
  },
];

export const sentimentDropdownOptions2: ISentimentDropdownOptions[] = [
  {
    label: 'Overall',
    value: 1,
  },
  {
    label: 'Positive',
    value: 2,
  },
  {
    label: 'Negative',
    value: 3,
  },
  {
    label: 'Neutral',
    value: 4,
  },
  {
    label: 'Unassigned',
    value: 5,
  },
];

export const categorySummaryData: ICategorySummaryResponse[] = [
  {
    Title: 'Positive',
    Data: [50, 20, 10, 10, 10],
  },
  {
    Title: 'Neutral',
    Data: [30, 30, 20, 30, 10],
  },
  {
    Title: 'Negative',
    Data: [20, 50, 70, 60, 80],
  },
];

const categoryScoreCorrelation: TreeNode[] = [
  {
    data: {
      Name: 'Facility',
      SurveyCount: 4,
      Score: 0.0,
      Cid: '1',
      CategoryScoreCorrelationBar: [
        {
          Title: 'Recommended',
          Data: [50.0],
        },
        {
          Title: 'Passive',
          Data: [9.1],
        },
        {
          Title: 'Detractor',
          Data: [40.9],
        },
      ],
    },
    children: [
      {
        data: {
          Name: 'Facility - Other',
          SurveyCount: 0,
          Score: 0.0,
          CategoryScoreCorrelationBar: [
            {
              Title: 'Recommended',
              Data: [50.0],
            },
            {
              Title: 'Passive',
              Data: [9.1],
            },
            {
              Title: 'Detractor',
              Data: [40.9],
            },
          ],
        },
      },
      {
        data: {
          Name: 'Website Content/Design',
          SurveyCount: 0,
          Score: 0.0,
          CategoryScoreCorrelationBar: [
            {
              Title: 'Recommended',
              Data: [50.0],
            },
            {
              Title: 'Passive',
              Data: [9.1],
            },
            {
              Title: 'Detractor',
              Data: [40.9],
            },
          ],
        },
      },
      {
        data: {
          Name: 'Convenience of Location',
          SurveyCount: 0,
          Score: 0.0,
          CategoryScoreCorrelationBar: [
            {
              Title: 'Recommended',
              Data: [50.0],
            },
            {
              Title: 'Passive',
              Data: [9.1],
            },
            {
              Title: 'Detractor',
              Data: [40.9],
            },
          ],
        },
      },
      {
        data: {
          Name: 'Showroom/Waiting Area',
          SurveyCount: 4,
          Score: 0.0,
          CategoryScoreCorrelationBar: [
            {
              Title: 'Recommended',
              Data: [50.0],
            },
            {
              Title: 'Passive',
              Data: [9.1],
            },
            {
              Title: 'Detractor',
              Data: [40.9],
            },
          ],
        },
        children: [
          {
            data: {
              Name: 'Showroom/Waiting Area - Other',
              SurveyCount: 0,
              Score: 0.0,
              CategoryScoreCorrelationBar: [
                {
                  Title: 'Recommended',
                  Data: [50.0],
                },
                {
                  Title: 'Passive',
                  Data: [9.1],
                },
                {
                  Title: 'Detractor',
                  Data: [40.9],
                },
              ],
            },
          },
          {
            data: {
              Name: 'Vehicle Exposition/Workshop Area',
              SurveyCount: 0,
              Score: 0.0,
              CategoryScoreCorrelationBar: [
                {
                  Title: 'Recommended',
                  Data: [50.0],
                },
                {
                  Title: 'Passive',
                  Data: [9.1],
                },
                {
                  Title: 'Detractor',
                  Data: [40.9],
                },
              ],
            },
          },
          {
            data: {
              Name: 'Parking',
              SurveyCount: 1,
              Score: 100.0,
              CategoryScoreCorrelationBar: [
                {
                  Title: 'Recommended',
                  Data: [50.0],
                },
                {
                  Title: 'Passive',
                  Data: [9.1],
                },
                {
                  Title: 'Detractor',
                  Data: [40.9],
                },
              ],
            },
          },
          {
            data: {
              Name: 'Available Amenities',
              SurveyCount: 2,
              Score: -100.0,
            },
          },
          {
            data: {
              Name: 'Appearance/Spacious/Comfort',
              SurveyCount: 1,
              Score: 100.0,
              CategoryScoreCorrelationBar: [
                {
                  Title: 'Recommended',
                  Data: [50.0],
                },
                {
                  Title: 'Passive',
                  Data: [9.1],
                },
                {
                  Title: 'Detractor',
                  Data: [40.9],
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    data: {
      Name: 'Product',
      SurveyCount: 60,
      Score: 43.3,
      cid: '2',
      CategoryScoreCorrelationBar: [
        {
          Title: 'Recommended',
          Data: [50.0],
        },
        {
          Title: 'Passive',
          Data: [9.1],
        },
        {
          Title: 'Detractor',
          Data: [40.9],
        },
      ],
    },
    children: [
      {
        data: {
          Name: 'barcelona.jpg',
          SurveyCount: 99,
          Score: 'Picture',
          CategoryScoreCorrelationBar: [
            {
              Title: 'Recommended',
              Data: [50.0],
            },
            {
              Title: 'Passive',
              Data: [9.1],
            },
            {
              Title: 'Detractor',
              Data: [40.9],
            },
          ],
        },
      },
      {
        data: {
          Name: 'primeui.png',
          SurveyCount: 99,
          Score: 'Picture',
        },
      },
      {
        data: {
          Name: 'optimus.jpg',
          SurveyCount: 99,
          Score: 'Picture',
        },
      },
    ],
  },
  {
    data: {
      Name: 'People',
      SurveyCount: 157,
      Score: 50.3,
      cid: '3',
    },
  },
  {
    data: {
      Name: 'Process',
      SurveyCount: 56,
      Score: 28.6,
      cid: '4',
    },
  },
  {
    data: {
      Name: 'General',
      SurveyCount: 87,
      Score: 28.7,
      cid: '5',
      CategoryScoreCorrelationBar: [
        {
          Title: 'Recommended',
          Data: [50.0],
        },
        {
          Title: 'Passive',
          Data: [9.1],
        },
        {
          Title: 'Detractor',
          Data: [40.9],
        },
      ],
    },
    children: [],
  },
];

export const categoriesOverTimePeriod: any = [
  {
    Title: 'People',
    Months: [
      {
        Year: 2019,
        Month: 2,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 3,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 4,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 5,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 6,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 7,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 8,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 9,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 10,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 11,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 12,
        Score: 10.0,
      },
      {
        Year: 2020,
        Month: 1,
        Score: 30.9,
      },
    ],
  },
  {
    Title: 'Process',
    Months: [
      {
        Year: 2019,
        Month: 2,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 3,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 4,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 5,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 6,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 7,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 8,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 9,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 10,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 11,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 12,
        Score: 10.3,
      },
      {
        Year: 2020,
        Month: 1,
        Score: 25,
      },
    ],
  },
  {
    Title: 'Product',
    Months: [
      {
        Year: 2019,
        Month: 2,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 3,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 4,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 5,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 6,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 7,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 8,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 9,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 10,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 11,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 12,
        Score: 16.0,
      },
      {
        Year: 2020,
        Month: 1,
        Score: 12.1,
      },
    ],
  },
  {
    Title: 'Facility',
    Months: [
      {
        Year: 2019,
        Month: 2,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 3,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 4,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 5,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 6,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 7,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 8,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 9,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 10,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 11,
        Score: 0.0,
      },
      {
        Year: 2019,
        Month: 12,
        Score: 30.0,
      },
      {
        Year: 2020,
        Month: 1,
        Score: 11.9,
      },
    ],
  },
];

export const commentsBusinessAreaData: ICommentsBusinessArea[] = [
  { Title: 'General', Count: 12111, Percent: 0, Color: 'red', Order: 1 },
  { Title: 'People', Count: 1607, Percent: 0, Color: 'red', Order: 2 },
  { Title: 'Process', Count: 1855, Percent: 0, Color: 'red', Order: 3 },
  { Title: 'Product', Count: 1855, Percent: 0, Color: 'red', Order: 3 },
  { Title: 'Facility', Count: 1855, Percent: 0, Color: 'red', Order: 3 },
];

export const surveyResponseResponse: ISurveyResponseResponse = {
  surveyResponseData: [
    {
      data: {
        id: 1,
        name: 'SQ1',
        score: 0.0,
        isSelected: false,
      },
      children: [
        {
          data: {
            id: 100,
            name: 'Below my expectation',
            score: 0.0,
            isSelected: false,
          },
        },
        {
          data: {
            id: 101,
            name: 'Met my expectation',
            score: 0.0,
            isSelected: false,
          },
        },
        {
          data: {
            id: 102,
            name: 'Surpassed my expectation',
            score: 0.0,
            isSelected: false,
          },
        },
      ],
    },
    {
      data: {
        id: 2,
        name: 'SQ2',
        score: 43.3,
        isSelected: true,
      },
      children: [
        {
          data: {
            id: 200,
            name: 'Yes',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 201,
            name: 'No',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 202,
            name: 'Not decided yet',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 203,
            name: 'Not decided yet 1',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 204,
            name: 'Not decided yet 2',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 205,
            name: 'Not decided yet 3',
            score: 'Picture',
            isSelected: false,
          },
        },
        {
          data: {
            id: 206,
            name: 'Not decided yet 4',
            score: 'Picture',
            isSelected: false,
          },
        },
      ],
    },
    {
      data: {
        id: 3,
        name: 'SW',
        score: 50.3,
        isSelected: false,
      },
    },
    {
      data: {
        id: 3,
        name: 'SW-A',
        score: 50.3,
        isSelected: false,
      },
    },
    {
      data: {
        id: 3,
        name: 'SW-B',
        score: 50.3,
        isSelected: false,
      },
    },
    {
      data: {
        id: 3,
        name: 'SW-C',
        score: 50.3,
        isSelected: false,
      },
    },
  ],
};

export const aspectCategoriesResponse: IAspectCategoriesResponse = {
  aspectCategoriesData: [
    {
      data: {
        id: 1,
        name: 'Facility',
        isSelected: false,
      },
      children: [
        {
          data: {
            id: 100,
            name: 'Convenience of Location',
            isSelected: false,
          },
        },
        {
          data: {
            id: 101,
            name: 'Hours of Operation',
            isSelected: false,
          },
        },
        {
          data: {
            id: 102,
            name: 'Showroom/Waiting Area',
            isSelected: false,
          },
          children: [
            {
              data: {
                id: 1021,
                name: 'Appearance/Spacious/Comfort',
                isSelected: false,
              },
            },
            {
              data: {
                id: 1022,
                name: 'Available Amenities',
                isSelected: false,
              },
            },
            {
              data: {
                id: 1023,
                name: 'Parking',
                isSelected: false,
              },
              children: [
                {
                  data: {
                    id: 10231,
                    name: 'Parking-1',
                    isSelected: false,
                  },
                },
                {
                  data: {
                    id: 10232,
                    name: 'Parking-2',
                    isSelected: false,
                  },
                },
              ],
            },
            {
              data: {
                id: 1024,
                name: 'Vehicle Exposition/Workshop Area',
                isSelected: false,
              },
            },
            {
              data: {
                id: 1025,
                name: 'Showroom/Waiting Area - Other',
                isSelected: false,
              },
            },
          ],
        },
        {
          data: {
            id: 103,
            name: 'Website Content/Design',
            isSelected: false,
          },
        },
        {
          data: {
            id: 104,
            name: 'Facility - Other',
            isSelected: false,
          },
        },
      ],
    },
    {
      data: {
        id: 2,
        name: 'Product',
        score: 43.3,
        isSelected: true,
      },
      children: [],
    },
    {
      data: {
        id: 3,
        name: 'People',
        score: 50.3,
        isSelected: false,
      },
    },
    {
      data: {
        id: 3,
        name: 'Process',
        score: 50.3,
        isSelected: false,
      },
    },
    {
      data: {
        id: 3,
        name: 'General',
        score: 50.3,
        isSelected: false,
      },
    },
  ],
};

export const dealerCompetitorsResponse: any = {
  locations: [
    {
      name: 'Longueuil MB',
      address: '760 Rue Saint-Charles Est, Longueuil, QC J4H 1C3, Canada',
      google_place_id: 'ChIJrac-xZgcyUwRSPhYwW71O-k',
      rtx_id: '5e01d0f2443c0d2a7907ceaa',
      avg_rating: 4.55668604651163,
      total_reviews: 688,
      latitude: 45.5496885,
      longitude: -73.4991334,
      sources: [
        {
          source: 'google',
          avg_rating: 4.56186317321689,
          total_reviews: 687,
          last_fetched_at: null,
          url_exists: false,
          url_path: null,
        },
        {
          source: 'yelp',
          avg_rating: 1,
          total_reviews: 1,
          last_fetched_at: null,
          url_exists: false,
          url_path: null,
        },
        {},
      ],
      competitors: [
        {
          google_place_id: 'ChIJ314HKMMYyUwRwmUHxHIZH0Q',
          competitor_id: 160760,
          name: 'Les Spécialistes du Financement.com',
          address: '1110 Boulevard Marie-Victorin, Longueuil, QC J4G 2H9, Canada',
          latitude: 45.5568703,
          longitude: -73.4943281,
          types: ['car_dealer', 'point_of_interest', 'store', 'establishment'],
          is_suggested_competitor: true,
          avg_rating: 3.06363636363636,
          total_reviews: 110,
          loading_sources: false,
          sources: [
            {
              source: 'google',
              avg_rating: 3.06363636363636,
              total_reviews: 110,
              last_fetched_at: '2020-02-04T05:58:12.544588',
              url_exists: true,
              url_path: 'https://maps.google.com/?cid=4908670099562915266',
            },
            {},
            {},
          ],
        },
        {
          google_place_id: 'ChIJqXGwD6IcyUwR3wY7RkivuZ0',
          competitor_id: 160759,
          name: 'Occasion Longueuil',
          address: '70 Boulevard Roland-Therrien, Longueuil, QC J4H 3V8, Canada',
          latitude: 45.5493289,
          longitude: -73.4970852,
          types: ['car_dealer', 'point_of_interest', 'store', 'establishment'],
          is_suggested_competitor: true,
          avg_rating: null,
          total_reviews: 0,
          loading_sources: false,
          sources: [
            {
              source: 'google',
              avg_rating: null,
              total_reviews: 0,
              last_fetched_at: '2020-02-04T05:58:12.79129',
              url_exists: true,
              url_path: 'https://maps.google.com/?cid=11365307859596936927',
            },
            {},
            {},
          ],
        },
      ],
    },
  ],
  _links: {
    self: {
      href: 'https://api-gateway.reviewtrackers.com/intel/locations?location_ids[]=5e01d0f2443c0d2a7907ceaa&page=1&per_page=25',
    },
  },
  _page: 1,
  _per_page: 25,
  _total: 1,
  _total_pages: 1,
};

// export const treeReportResponse: ITreeReportResponse = {
//     CategoryTab: [
//         {
//             title: 'All',
//             count: 472,
//             isSelected: false,
//         },
//         {
//             title: 'Positive',
//             count: 366,
//             isSelected: true,
//         },
//         {
//             title: 'Negative',
//             count: 23,
//             isSelected: false,
//         },
//         {
//             title: 'Neutral',
//             count: 83,
//             isSelected: false,
//         },
//         {
//             title: 'Unassigned',
//             count: 0,
//             isSelected: false,
//         },
//     ],
//     CategoriesData: [
//         {
//             Data: {
//                 id: 0,
//                 name: 'All',
//                 isSelected: false
//             },
//             Children: [
//                 {
//                     data: {
//                         id: 100,
//                         name: 'Showroom/Waiting Area',
//                         isSelected: false
//                     },
//                 },
//                 {
//                     data: {
//                         id: 101,
//                         name: 'Convenience Of Location',
//                         isSelected: false
//                     },
//                 },
//                 {
//                     data: {
//                         id: 102,
//                         name: 'Recommended',
//                         isSelected: false,
//                     },
//                     children: [
//                         {
//                             data: {
//                                 id: 1021,
//                                 name: 'Safety/Reliability',
//                                 isSelected: false
//                             },
//                         },
//                         {
//                             data: {
//                                 id: 1022,
//                                 name: 'Hybrid Models',
//                                 isSelected: false
//                             },
//                         },
//                         {
//                             data: {
//                                 id: 1023,
//                                 name: 'Other Brands',
//                                 isSelected: false
//                             },
//                             children: [
//                                 {
//                                     data: {
//                                         id: 10231,
//                                         name: 'Parking-1',
//                                         isSelected: false
//                                     },
//                                 },
//                                 {
//                                     data: {
//                                         id: 10232,
//                                         name: 'Parking-2',
//                                         isSelected: false
//                                     },
//                                 },
//                             ]
//                         },
//                         {
//                             data: {
//                                 id: 1024,
//                                 name: 'Retailers',
//                                 isSelected: false
//                             }
//                         },
//                         {
//                             data: {
//                                 id: 1025,
//                                 name: 'Recommended - Other',
//                                 isSelected: false
//                             }
//                         },
//                     ]
//                 },
//                 {
//                     data: {
//                         id: 103,
//                         name: 'Website Content/Design',
//                         isSelected: false
//                     }
//                 },
//                 {
//                     data: {
//                         id: 104,
//                         name: 'Facility - Other',
//                         isSelected: false
//                     }
//                 },
//             ]
//         },
//         {
//             data: {
//                 id: 1,
//                 name: 'Facility',
//                 isSelected: false,
//                 comments: [
//                     {
//                         comment: 'I have purchased 4 Altimas from this dealership.All my past and present experiences were outstanding. All my needs were met and explained in detail. ...',
//                         type: 'positive'
//                     },
//                     {
//                         comment: 'The whole experience was delightful - our salesman Slav made it so easy and effortless; stress free',
//                         type: 'positive'
//                     },
//                     {
//                         comment: 'I have had three previous purchases with Hunt Club MB, the Titan would have been my fourth purchase from them. I went there first, it was not a g...',
//                         type: 'positive'
//                     },
//                     {
//                         comment: 'As per my service experience review I will never go bak again to this dealer. After they have my money looks like they dont care about my car.',
//                         type: 'negative'
//                     },
//                     {
//                         comment: 'Not sure yet. My wife said the guy who is the head of the service department was rude to her. I havent been in to speak to Averry about it yet.',
//                         type: 'negative'
//                     },
//                 ],
//                 cloud: [
//                     {
//                         title: 'MB are amazing',
//                         count: 1
//                     },
//                     {
//                         title: '4th car',
//                         count: 1
//                     },
//                     {
//                         title: 'car purchase',
//                         count: 1
//                     },
//                     {
//                         title: 'alta MB',
//                         count: 1
//                     },
//                     {
//                         title: 'customer experience',
//                         count: 1
//                     },
//                     {
//                         title: 'experience especially',
//                         count: 1
//                     },
//                     {
//                         title: 'jeff sittler',
//                         count: 1
//                     },
//                     {
//                         title: 'sittler helping',
//                         count: 1
//                     },
//                     {
//                         title: '4th car purchase',
//                         count: 1
//                     },
//                     {
//                         title: 'purchase with alta',
//                         count: 1
//                     },

//                 ]
//             },
//             children: [
//                 {
//                     data: {
//                         id: 100,
//                         name: 'Showroom/Waiting Area',
//                         isSelected: false
//                     },
//                 },
//                 {
//                     data: {
//                         id: 101,
//                         name: 'Convenience Of Location',
//                         isSelected: false
//                     },
//                 },
//                 {
//                     data: {
//                         id: 102,
//                         name: 'Recommended',
//                         isSelected: false,
//                         comments: [
//                             {
//                                 comment: 'I have purchased 4 Altimas from this dealership.All my past and present experiences were outstanding. All my needs were met and explained in detail. ...',
//                                 type: 'positive'
//                             },
//                             {
//                                 comment: 'Not sure yet. My wife said the guy who is the head of the service department was rude to her. I havent been in to speak to Averry about it yet.',
//                                 type: 'negative'
//                             },
//                         ],
//                         cloud: [
//                             {
//                                 title: 'MB are amazing',
//                                 count: 1
//                             },
//                             {
//                                 title: '4th car',
//                                 count: 1
//                             },
//                             {
//                                 title: 'car purchase',
//                                 count: 1
//                             },
//                             {
//                                 title: 'alta MB',
//                                 count: 1
//                             },
//                             {
//                                 title: 'customer experience',
//                                 count: 1
//                             }
//                         ]
//                     },
//                     children: [
//                         {
//                             data: {
//                                 id: 1021,
//                                 name: 'Safety/Reliability',
//                                 isSelected: false
//                             },
//                         },
//                         {
//                             data: {
//                                 id: 1022,
//                                 name: 'Hybrid Models',
//                                 isSelected: false
//                             },
//                         },
//                         {
//                             data: {
//                                 id: 1023,
//                                 name: 'Other Brands',
//                                 isSelected: false,
//                                 comments: [
//                                     {
//                                         comment: 'I have purchased 4 Altimas from this dealership.All my past and present experiences were outstanding. All my needs were met and explained in detail. ...',
//                                         type: 'positive'
//                                     },
//                                 ],
//                                 cloud: [
//                                     {
//                                         title: 'jeff sittler',
//                                         count: 1
//                                     },
//                                     {
//                                         title: 'sittler helping',
//                                         count: 1
//                                     },
//                                 ]
//                             },
//                             children: [
//                                 {
//                                     data: {
//                                         id: 10231,
//                                         name: 'Parking-1',
//                                         isSelected: false
//                                     },
//                                 },
//                                 {
//                                     data: {
//                                         id: 10232,
//                                         name: 'Parking-2',
//                                         isSelected: false
//                                     },
//                                 },
//                             ]
//                         },
//                         {
//                             data: {
//                                 id: 1024,
//                                 name: 'Retailers',
//                                 isSelected: false
//                             }
//                         },
//                         {
//                             data: {
//                                 id: 1025,
//                                 name: 'Recommended - Other',
//                                 isSelected: false
//                             }
//                         },
//                     ]
//                 },
//                 {
//                     data: {
//                         id: 103,
//                         name: 'Website Content/Design',
//                         isSelected: false
//                     }
//                 },
//                 {
//                     data: {
//                         id: 104,
//                         name: 'Facility - Other',
//                         isSelected: false
//                     }
//                 },
//             ]
//         },
//         {
//             data: {
//                 id: 2,
//                 name: 'Product',
//                 score: 43.3,
//                 count: 43.3,
//                 isSelected: true,
//             },
//             children: []
//         },
//         {
//             data: {
//                 id: 3,
//                 name: 'People',
//                 score: 50.3,
//                 isSelected: false,
//             },
//         },
//         {
//             data: {
//                 id: 3,
//                 name: 'Process',
//                 score: 50.3,
//                 isSelected: false
//             },
//         },
//         {
//             data: {
//                 id: 3,
//                 name: 'General',
//                 score: 50.3,
//                 isSelected: false
//             },
//         },
//     ]
// };
