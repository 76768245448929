import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable, of, Subscriber, Subject, BehaviorSubject, merge, from, empty, concat } from 'rxjs';
import { startWith, tap, switchMap, flatMap, take } from 'rxjs/operators';
import { RequestCache } from './request.cache.service';
import { LoaderService } from '../loader.service';
import { Logger } from '../logger.service';
import { PaginationHeader } from '../types/paginationData';
import { Store } from '@ngrx/store';
import { ChangeCacheState } from '@gstate/actions/cache.action';
import { CVPActions } from '@gstate/actions';
import { CachedItem } from 'ngforage';
const log = new Logger('CacheInterceptor');
import { environment } from '@env/environment';
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  enableCache = true;
  constructor(private cache: RequestCache, private loader: LoaderService, private store: Store<any>) {
    this.enableCache = environment.enableCache;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    if (!this.enableCache || !environment.enableCache) {
      return next.handle(req);
    }
    let maybeCachedResponse: any = empty();
    return from(this.cache.get(req)).pipe(
      switchMap((cacheItem: any) => {
        if (cacheItem && cacheItem['data']) {
          this.loader.showTop = true;
          this.loader.showMain = false;
          next.handle(req).subscribe((res: HttpResponse<any>) => {
            this.loader.showTop = true;
            this.loader.showMain = false;
            if (res instanceof HttpResponse) {
              this.cache.put(req, res);
              this.store.dispatch(new CVPActions.CacheStateEvent.ChangeCacheState({ cache: res }));
              this.loader.showTop = false;
            }
          });
          const d = JSON.parse(cacheItem['data']) as HttpResponse<any>;
          const cacheResponse = new HttpResponse(d);
          maybeCachedResponse = of(cacheResponse);
        } else {
          maybeCachedResponse = this.sendRequest(req, next, this.cache);
        }
        return maybeCachedResponse;
      })
    );
    // const cachedResponse = this.cache.get(req);
    // if (cachedResponse) {
    //   this.loader.showTop = true;
    //   this.loader.showMain = false;
    //   next.handle(req).subscribe((res: HttpResponse<any>) => {
    //     this.loader.showTop = true;
    //     this.loader.showMain = false;
    //     if (res instanceof HttpResponse) {
    //       this.cache.put(req, res);
    //       this.store.dispatch(new CVPActions.CacheStateEvent.ChangeCacheState({ cache: res }));
    //       this.loader.showTop = false;
    //     }
    //   });
    //   return of(cachedResponse);
    // } else {
    //   return this.sendRequest(req, next, this.cache);
    // }
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (environment.enableCache) {
            cache.put(req, event);
          }
        }
      })
    );
  }

  configure(options?: { update?: boolean; enableCache?: boolean } | null): CachingInterceptor {
    this.enableCache = options.enableCache;
    log.info('Caching enable : ' + this.enableCache);
    return this;
  }
}
