import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { NgForageCache } from 'ngforage';
import { environment } from '@env/environment';

const maxAge = 900000;
@Injectable()
export class RequestCache {
  constructor(private ngCache: NgForageCache) {}
  get(req: HttpRequest<any>) {
    if (environment.enableCache) {
      const url = req.urlWithParams.replace('&observe=response', '').replace('?observe=response', '');
      return this.ngCache.getCached(url);
    }
  }
  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    if (environment.enableCache) {
      const url = req.url;
      this.ngCache.setCached(url, JSON['makeStringify'](response), 30 * 60000);
    }
  }
}
