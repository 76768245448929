import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-message',
  templateUrl: './session-message.component.html',
  styleUrls: ['./session-message.component.sass'],
})
export class SessionMessageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
