import { RfPrimeTemplate } from '../common';
import { ContentChildren, Component, QueryList, Optional } from '@angular/core';
import { CellEditor } from 'primeng/table';
import { CVPTableComponent } from './table.component';
import { CVPEditableColumn } from './directives/editablecolumn.directive';
import { CVPEditableRow } from './directives/editableRow.directive';
import { EditableRow } from 'primeng/table';

@Component({
  selector: 'cvp-cellEditor',
  templateUrl: './templates/celleditor.html',
})
export class CVPCellEditor extends CellEditor {
  // @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;

  constructor(
    public dt: CVPTableComponent,
    @Optional() public editableColumn: CVPEditableColumn,
    @Optional() public editableRow: EditableRow
  ) {
    super(dt, editableColumn, editableRow);
  }
}
