import { Injectable } from '@angular/core';
import { Config } from './app.config.type';

@Injectable()
export class AppConfigurationService {
  config: Array<Config>;
  constructor() {
    this.config = this.getAuthorizationDataLocalStorage();
  }
  getByIdentifier(identifier: string) {
    this.config = this.getAuthorizationDataLocalStorage();
    if (this.config) {
      const conff = this.config.find((conf: Config) => {
        return conf.Identifier === identifier;
      });
      return conff ? conff : false;
    } else {
      return false;
    }
  }
  getByFeatureId(featureId: string) {
    this.config = this.getAuthorizationDataLocalStorage();
    if (this.config) {
      const conff = this.config.find((conf: Config) => {
        return conf.FeatureId === featureId;
      });
      return conff ? conff : false;
    } else {
      return false;
    }
  }
  getHideColumns(cols: any[], fieldName: string, hideFieldName: string, reportName: string) {
    if (this.config) {
      const conff = this.getByIdentifier('DynamicColunmsHides');
      const clientConfig = conff['ClientConfig'];
      let fields: any[] = [];
      if (clientConfig != null && clientConfig !== undefined) {
        fields = clientConfig['Fields'];
      } else {
        fields = conff['Fields'];
      }

      let filter = {};
      let columns: any[] = [];

      if (fields !== undefined && fields != null) {
        filter = fields.filter((x) => x['key'] === reportName)[0];
      }

      if (filter !== undefined && filter != null) {
        columns = filter['value'];
      }

      cols.forEach((e) => {
        const colmns = columns.filter((w) => w['name'] === e[fieldName])[0];
        if (colmns !== undefined && colmns != null) {
          e[hideFieldName] = colmns['visibility'];
          e.order = colmns['order'] ?? 1000;
        } else {
          e[hideFieldName] = true;
          e.order = 1000;
        }
      });
    } else {
      console.log('Error', 'Report Hide config not found');
    }
    cols.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    return cols;
  }
  private getAuthorizationDataLocalStorage(): any {
    const authData = localStorage.getItem('authorizationData');
    return authData ? JSON.parse(authData)['Configurations'] : false;
  }
}
