import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import { Observable } from 'rxjs';
import { identityServerAngularConfig } from '@env/environment';

import { AuthenticationService } from '../core/authentication/authentication.service';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { environment } from '@env/environment';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { MyStartupService } from '@app/app.service';
import { LoaderService } from './../core/loader.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Guid } from 'guid-typescript';
import { HttpClient } from '@angular/common/http';
import { AuthServiceCleaner } from '@app/shared/services/auth.service.cleaner';
import { IdleTimeoutService } from '@app/shared/services/idle-timeout.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  identity$: Observable<User>;
  loggedIn$: Observable<boolean>;
  loading$: Observable<boolean>;
  expiring$: Observable<boolean>;
  expired$: Observable<boolean>;
  errors$: Observable<any>;

  public isLoggedIn: boolean;
  public identity: any;

  error: string = undefined;

  permissions: NgxPermissionsObject;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private toastService: ToastService,
    private idleTimeoutService: IdleTimeoutService,
    private http: HttpClient,
    private authClearService: AuthServiceCleaner,
    private route: ActivatedRoute,
    private ngxpermissions: NgxPermissionsService,
    private myStartupService: MyStartupService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService // private i18nService: I18nService
  ) {}

  ngOnInit() {
    // this.initLogin();
    const mgr = this.authService.getUserManager();
    let isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
    if (isIdleTimeout && isIdleTimeout == true) {
      window.localStorage.clear();
      mgr.signoutRedirect();
      return;
    }

    this.checkTenantName();
  }

  checkTenantName() {
    let tenantName = this.route.snapshot.paramMap.get('id');
    if (!tenantName && this.authService.authData == null) {
      if (environment.login.isDefaultEnabled) {
        tenantName = environment.login.defaultRouteValue;
      }
    }
    if (tenantName) {
      if (this.authService.authData == null) {
        if (this.authService.getTenantName() == null || tenantName !== this.authService.getTenantName()) {
          localStorage.setItem('tenant', tenantName);
        }
        this.authService.init();
        this.initLogin();
      } else {
        this.authService.init();
        this.initLogin();
      }
    } else {
      localStorage.setItem('tenant', '');
      this.authService.init();
      this.initLogin();
    }
  }

  initLogin() {
    this.loaderService.showMain = true;
    const mgr = this.authService.getUserManager();
    let isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
    if (isIdleTimeout && isIdleTimeout == true) {
      window.localStorage.clear();
      mgr.signoutRedirect();
      return;
    }

    mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.authService.checkTokenExpiry(user.expires_at);
          this.isLoggedIn = true;
          this.authService.isLoggedIn = true;
          this.identity = user;
          this.initUser();
        } else {
          this.isLoggedIn = false;
          this.authService.isLoggedIn = false;
          this.identity = '';
          if (this.authService.isSetLoggedOut === false) {
            this.authService.clearAuthData();
            localStorage.setItem('isSSOLogin', null);
            localStorage.setItem('ssoClient', null);
            localStorage.setItem('ssoToken', null);
            localStorage.setItem('isIdleTimeout', null);
            localStorage.setItem('idle_timeout_enabled', null);
            mgr.signinRedirect();
          } else {
            this.router.navigate(['/endsession']);
          }
        }
      })
      .catch((e) => {
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      });
  }

  login() {
    this.authService.login();
  }

  initUser() {
    const res = {
      access_token: this.identity.access_token,
      expiry: this.identity.expires_at,
    };

    this.authService.storeAuthData(res).subscribe(
      (response) => {
        const data = this.authService.onSignIn(response);
        if (response && response.IdleTimeoutConfig != null && response.IdleTimeoutConfig.IsIdletimeoutEnabled) {
          this.idleTimeoutService.setIdletimeout(response.IdleTimeoutConfig.TimeoutSeconds);
        }

        if (environment.signalRConfigured) {
          this.signalRService.init();
          this.realTimeNotificationService.init();
          this.realTimeNotificationService.subscribeListeners();
        }
        this.authService.getLanguage().subscribe((langResponse) => {
          this.myStartupService.data = langResponse;
          // localStorage.setItem('translations', JSON.stringify(langResponse));
          localStorage.setItem('translations', JSON['makeStringify'](langResponse));
          this.permissions = this.ngxpermissions.getPermissions();
          const Admin = this.permissions['Admin'] ? true : false;
          const Reporting = this.permissions['Reporting'] ? true : false;

          this.loaderService.showMain = false;
          if (localStorage.getItem('loginNotificationId') == null) {
            const id: string = Guid.create().toString();
            localStorage.setItem('appNotifyId', id);
            const notifyData = { message: 'Login Successfull', pageName: 'Login', module: 'Login', appNotifyId: id };
            this.notificationsService.ActivityNonRequestNotification(notifyData, true);
          }
          if (Reporting) {
            this.router.navigate(['/reporting']);
          } else if (Admin) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['/unauthorized']);
          }
        });
      },
      (reason) => {
        switch (reason.status) {
          case -1: {
            this.error = 'Some error occurred. Please check your internet connection or try again';
            this.loaderService.showMain = false;

            break;
          }
          case 400: {
            this.error = reason.Message;
            this.loaderService.showMain = false;

            break;
          }
          case 500: {
            this.error = reason.error.ExceptionMessage;
            this.loaderService.showMain = false;

            break;
          }
        }
      }
    );
  }
}
