<div class="error-wrapper">
  <div class="error">
    <div class="error__inner">
      <i class="fal fa-info-circle"></i>
      <h1>Oops! Something went wrong.</h1>
      <p>This page couldn't load correctly. Please try again.</p>
      <p>Cette page n'a pas pu être chargée correctement. Veuillez réessayer.</p>
      <p><a (click)="toHome()" class="btn">Click Here to Try Again</a></p>
    </div>
  </div>
</div>
