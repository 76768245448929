<div class="login-page">
  <div class="col-left">
    <form name="authForm" novalidate style="height: 100%">
      <div class="login-form">
        <div class="form">
          <div class="form__inner">
            <div class="form__header">
              <h3>Forgot Password?</h3>
              <p>Enter your email below, and we'll send you the password reset link</p>
            </div>
            <fieldset class="form__group validation-message" *ngIf="error">
              <p>{{ error }}</p>
            </fieldset>
            <label>ENTER EMAIL ADDRESS</label>
            <fieldset class="form__group">
              <input
                class="form__input"
                type="email"
                name="email"
                required="required"
                placeholder="Enter your email"
                pattern="/^(([^<>()\[\]\.,;:\s@\”]+(\.[^<>()\[\]\.,;:\s@\”]+)*)|(\”.+\”))@(([^<>()[\]\.,;:\s@\”]+\.)+[^<>()[\]\.,;:\s@\”]{2,})$/i"
                [(ngModel)]="email"
                required
              />
              <i class="far fa-envelope form__icon"></i>

              <div ng-messages="authForm.email.$error" *ngIf="submitted">
                <p class="field__error" ng-message="pattern">Email is invalid.</p>
                <p class="field__error" ng-message="required">Email is required.</p>
              </div>
            </fieldset>

            <fieldset class="form__group actions">
              <button class="btn" (click)="forgotPassword(email)" [disabled]="isSubmit">SEND</button>

              <!--<a ui-sref="auth.login" class="btn btn--secondary btn--big" style="float : right">
                              Return to login
                          </a>-->
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-right" *ngIf="imagePath" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
  <div class="col-right" *ngIf="imagePath == undefined"></div>
</div>
