import { Injectable, OnDestroy } from '@angular/core';
@Injectable()
export class GlobalFilterConfigService {
  filterConfig: any = {
    returnFilter: {
      test: {
        signature: 'property',
        filterKey: 'Test',
        name: 'test',
        valueProp: 'value',
      },
      dealer: {
        signature: 'method',
        name: 'getDealer',
      },
      model: {
        signature: 'method',
        name: 'getModel',
      },
      calendar: {
        signature: 'method',
        name: 'getDates',
      },
      org: {
        signature: 'method',
        name: 'getOrg',
      },
      surveyScore: {
        signature: 'method',
        name: 'getSurveyScore',
      },
      category: {
        signature: 'method',
        name: 'getCategory',
      },
      version: {
        signature: 'method',
        name: 'getVersion',
      },
      language: {
        signature: 'method',
        name: 'getlanguageCode',
      },
      surveyQuestion: {
        signature: 'method',
        name: 'getSurveyQuestion',
      },
      surveyResponse: {
        signature: 'method',
        name: 'getSurveyQuestionResponses',
      },
      sources: {
        signature: 'method',
        name: 'getSources',
      },
      sentiment: {
        signature: 'method',
        name: 'getSentiments',
      },
      multi_org: {
        signature: 'method',
        name: 'getMultiOrg',
      },
    },
    pageUrlPrefix: '',
    pageConfig: {
      default: {
        filter: ['test', 'dealer', 'org', 'model', 'version', 'calendar', 'applybutton'],
      },
      '/reporting/globaldashboard': {
        filter: ['test', 'dealer', 'org', 'model', 'version', 'calendar', 'applybutton'],
      },
      '/reporting/reportgenerator': {
        filter: ['dealer', 'calendar'],
        tabs: [],
      },
      '/reporting/voc': {
        filter: [
          'dealer',
          'org',
          'model',
          'version',
          'calendar',
          'applybutton',
          'break',
          'language',
          'surveyQuestion',
          'surveyResponse',
          'category',
          'surveyScore',
        ],
      },
      '/reporting/voc/dashboard': {
        filter: [
          'dealer',
          'org',
          'model',
          'version',
          'calendar',
          'applybutton',
          'break',
          'language',
          'surveyQuestion',
          'surveyResponse',
          'category',
          'surveyScore',
        ],
      },
      '/reporting/voc/commentlist': {
        filter: [
          'dealer',
          'org',
          'model',
          'version',
          'calendar',
          'applybutton',
          'break',
          'language',
          'surveyQuestion',
          'surveyResponse',
          'category',
          'surveyScore',
        ],
      },
      '/reporting/voc/treereport/voc': {
        filter: ['dealer', 'org', 'sources', 'language', 'calendar', 'applybutton'],
      },
      '/reporting/socialmedia/treereport/sm': {
        filter: ['dealer', 'org', 'sources', 'language', 'calendar', 'applybutton'],
        tabs: [],
      },
      '/reporting/voc/dynamictopic': {
        filter: [
          'dealer',
          'org',
          'model',
          'version',
          'calendar',
          'applybutton',
          'break',
          'language',
          'surveyQuestion',
          'surveyResponse',
          'category',
          'surveyScore',
        ],
      },
      '/reporting/socialmedia/reviewlist': {
        filter: ['dealer', 'org', 'sources', 'calendar', 'applybutton', 'break', 'language', 'sentiment', 'category'],
      },
      '/reporting/socialmedia/rankingreport': {
        filter: ['dealer', 'org', 'sources', 'calendar', 'applybutton', 'break', 'language', 'sentiment', 'category'],
      },
      '/reporting/socialmedia': {
        filter: ['dealer', 'org', 'sources', 'calendar', 'applybutton', 'break', 'language', 'category'],
        tabs: [],
      },
      '/reporting/socialmedia/dashboard': {
        filter: ['dealer', 'org', 'sources', 'calendar', 'applybutton', 'break', 'language', 'category'],
        tabs: [],
      },
    },
    initialStates: {
      test: {
        label: 'All Test',
        value: 'Test',
      },
      tab: {
        label: 'New Customer',
        value: 'Sales',
      },
      sentiment: {
        label: 'All Sentiments',
        value: 'All Sentiments',
      },
      sources: [
        {
          label: 'Dealer Rater',
          value: 'DealerRater',
          IsChecked: true,
        },
        {
          label: 'Google',
          value: 'google',
          IsChecked: true,
        },
        {
          label: 'Yelp',
          value: 'yelp',
          IsChecked: true,
        },
      ],
      language: [
        {
          label: 'English',
          value: 'english',
          LanguageCode: 'en-CA',
          IsChecked: true,
        },
        {
          label: 'French',
          value: 'french',
          LanguageCode: 'fr-CA',
          IsChecked: true,
        },
        {
          label: 'Simpliefied Chinese',
          value: 'simpliefied_chinese',
          LanguageCode: 'ct-CA',
          IsChecked: true,
        },
        {
          label: 'Traditional Chinese',
          value: 'traditional_chinese',
          LanguageCode: 'ch-CA',
          IsChecked: true,
        },
      ],
      category: {
        level: 1,
        parent: 'null',
        name: 'All Categories',
        label: 'All Categories',
        active: true,
        IsChecked: true,
      },
      dealer: {
        label: 'All',
        value: 'All',
      },
      version: {
        label: 'V2021-B',
        value: '6024f561c95b37926171b870',
      },
      model: {
        Brand: 'Ignition Gas and Electric',
        Model: '',
        Year: '',
      },
      org: {
        level: 1,
        Identifier: 'Default',
        NodeId: '5ff86466a81ecf30e4c63c2b',
        MId: '5ff862fda81ecf30e4c63c26',
        NodeName: '',
        parent: 'null',
        name: 'Corporate',
        label: 'Corporate',
        active: true,
        IsChecked: true,
      },
      multi_org: [
        {
          level: 1,
          Identifier: 'Default',
          NodeId: '5ff86466a81ecf30e4c63c2b',
          parent: 'null',
          name: 'Corporate',
          label: 'Corporate',
          OrgIdentifier: 'Default',
          OrgLevel: 1,
          OrgName: '5ff86466a81ecf30e4c63c2b',
          OrgParent: 'null',
          active: true,
          IsChecked: true,
        },
      ],
    },
    activeState: {
      test: false,
      dealer: false,
      version: false,
      events: false,
      org: false,
      model: false,
      category: false,
      language: false,
      surveyResponse: false,
      surveyQuestion: false,
      surveyScore: false,
      calendar: false,
      sources: false,
      sentiment: false,
      question_comments: false,
      multi_org: false,
    },
    tabs: [
      {
        label: 'New Customer',
        value: 'Sales',
      },
      {
        label: 'My Account',
        value: 'CPO',
      },
      {
        label: 'Customer Support',
        value: 'Service',
      },
      {
        label: 'Product',
        value: 'Collision',
      },
    ],
    config: [
      {
        type: 'list',
        dynamic: false,
        display: false,
        name: 'test',
        title: ['label'],
        placeholder: 'Select Test',
        rootClass: 'filter__item flex4',
        settings: {
          multi: false,
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: false,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_test',
        values: [
          {
            label: 'All Test',
            value: 'Test',
          },
          {
            label: 'MB Test 2',
            value: 'Test 2',
          },
        ],
      },
      {
        type: 'list',
        dynamic: false,
        name: 'dealer',
        title: ['label'],
        placeholder: 'Select Dealer Type',
        rootClass: 'filter__item flex4',
        settings: {
          multi: false,
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: false,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_dealer',
        values: [
          {
            label: 'All',
            value: 'All',
          },
          {
            label: 'Residential',
            value: 'Private',
          },
          {
            label: 'Commercial',
            value: 'Corporate',
          },
          {
            label: 'SMB',
            value: 'Collision',
          },
        ],
      },
      {
        type: 'list',
        dynamic: false,
        name: 'sentiment',
        title: ['label'],
        placeholder: 'All Sentiment',
        rootClass: 'filter__item flex4',
        settings: {
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: false,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_sentiments',
        values: [
          {
            label: 'All Sentiment',
            value: 'All Sentiment',
          },
          {
            label: 'Positive',
            value: 'Positive',
          },
          {
            label: 'Negative',
            value: 'Negative',
          },
          {
            label: 'Neutral',
            value: 'Neutral',
          },
          {
            label: 'Unclassified',
            value: 'Unclassified',
          },
        ],
      },
      {
        type: 'list',
        dynamic: false,
        name: 'sources',
        title: ['label'],
        placeholder: 'Select Sources',
        rootClass: 'filter__item flex4',
        settings: {
          multi: true,
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: true,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_sources',
        values: [
          {
            label: 'Dealer Rater',
            value: 'DealerRater',
            IsChecked: true,
          },
          {
            label: 'Google',
            value: 'google',
            IsChecked: true,
          },
          {
            label: 'Yelp',
            value: 'yelp',
            IsChecked: true,
          },
        ],
      },
      {
        type: 'tree',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'orgs',
        placeholder: 'Select Org',
        title: ['name'],
        dependon: ['dealer'],
        name: 'org',
        rootClass: 'filter__item flex4',
        actionContainerClass: 'filter-field filter--withicon',
        listContainerClass: 'filter-dropdown',
        listUlClass: 'tree-list',
        icons: {
          leftIcon: 'fal fa-industry',
        },
        localStorageKey: 'gbf_org',
        settings: {
          multi: false,
          listStyleType: 'none',
          listDoneButton: false,
          bigModal: true,
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
      {
        type: 'tree',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'models',
        placeholder: 'Select Model Brand Year',
        treeTitles: ['Brands', 'Models', 'Years'],
        mapping: '',
        title: ['Model', 'Brand', 'Year'],
        name: 'model',
        rootClass: 'filter__item flex4',
        actionContainerClass: 'filter-field filter--withicon',
        listContainerClass: 'filter-dropdown dropdown--allmodels',
        listUlClass: 'tree-list',
        localStorageKey: 'gbf_mby',
        icons: {
          leftIcon: 'fal fa-car',
        },
        settings: {
          multi: false,
          listStyleType: 'none',
          listDoneButton: true,
          bigModal: false,
          showTitle: true,
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
      {
        type: 'list',
        dynamic: false,
        name: 'version',
        placeholder: 'Select Version',
        property: 'versions',
        rootClass: 'filter__item flex2',
        dependon: ['model'],
        localStorageKey: 'gbf_version',
        title: ['label'],
        settings: {
          multi: false,
          remote: true,
          listStyleType: 'none',
          listDoneButton: false,
        },
        values: [
          {
            label: 'V19',
            value: 'v19',
          },
          {
            label: 'V20',
            value: 'v20',
          },
          {
            label: 'V20B',
            value: 'V20B',
          },
          {
            label: 'V20C',
            value: 'V20C',
          },
        ],
      },
      {
        type: 'calendar',
        placeholder: 'Select Date',
        name: 'calendar',
        format: 'MMM D, YYYY',
        rootClass: 'filter__item flex4',
        localStorageKey: 'gbf_calendar',
        range: 'YTD',
      },
      {
        name: 'applybutton',
      },
      {
        name: 'break',
      },
      {
        type: 'list',
        dynamic: false,
        name: 'language',
        placeholder: 'Select Language',
        title: ['label'],
        rootClass: 'filter__item flex3',
        property: 'languages',
        settings: {
          multi: true,
          remote: true,
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: true,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_language',
        values: [
          {
            label: 'English',
            value: 'english',
            LanguageCode: 'en-CA',
            IsChecked: true,
          },
          {
            label: 'French',
            value: 'french',
            LanguageCode: 'fr-CA',
            IsChecked: true,
          },
          {
            label: 'Simpliefied Chinese',
            value: 'simpliefied_chinese',
            LanguageCode: 'ct-CA',
            IsChecked: true,
          },
          {
            label: 'Traditional Chinese',
            value: 'traditional_chinese',
            LanguageCode: 'ch-CA',
            IsChecked: true,
          },
        ],
      },
      {
        type: 'list',
        dynamic: false,
        name: 'surveyQuestion',
        placeholder: 'Select Survey Question',
        property: 'surveyQuestions',
        rootClass: 'filter__item flex3',
        title: ['label'],
        settings: {
          multi: true,
          remote: true,
          listDoneButton: false,
          listStyleType: 'none',
          showTitle: true,
        },
        icons: {
          leftIcon: '',
        },
        localStorageKey: 'gbf_survey_question',
      },
      {
        type: 'tree',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'surveyResponses',
        placeholder: 'Select Survey Response',
        title: ['label'],
        name: 'surveyResponse',
        rootClass: 'filter__item flex4',
        actionContainerClass: 'filter-field filter--withicon',
        listContainerClass: 'filter-dropdown dropdown--surveyresponses',
        listUlClass: 'tree-list',
        localStorageKey: 'gbf_survey_response',
        icons: {
          leftIcon: '',
        },
        settings: {
          multi: true,
          initialChecked: 'none',
          listStyleType: 'none',
          listDoneButton: true,
          bigModal: false,
          showTitle: true,
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
      {
        type: 'tree',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'categories',
        placeholder: 'Select Category',
        title: ['label'],
        name: 'category',
        rootClass: 'filter__item flex4',
        localStorageKey: 'gbf_category',
        listUlClass: 'tree-list tree-list_straight',
        settings: {
          multi: false,
          stright: true,
          listStyleType: 'none',
          listDoneButton: false,
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
      {
        type: 'slider',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'surveyScores',
        placeholder: 'Select Score',
        title: ['label'],
        name: 'surveyScore',
        rootClass: 'filter__item flex4',
        localStorageKey: 'gbf_survey_score',
        settings: {
          multi: false,
          listStyleType: 'none',
          listDoneButton: false,
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
      {
        type: 'tree',
        dynamic: false,
        treeToLinear: true,
        startLevel: 1,
        property: 'multi_orgs',
        placeholder: 'Select Org',
        title: ['name'],
        dependon: ['dealer'],
        treeTitles: ['Level1', 'Level2', 'Level3', 'Level4', 'Level5'],
        treeListConfig: {
          '0': {
            display: false,
            multi: false,
            listStyleType: 'none',
            showTitle: true,
          },
          '1': {
            multi: false,
            listStyleType: 'none',
            showTitle: true,
          },
        },
        name: 'multi_org',
        rootClass: 'filter__item flex4',
        actionContainerClass: 'filter-field filter--withicon',
        listContainerClass: 'filter-dropdown',
        listUlClass: 'tree-list',
        localStorageKey: 'gbf_multi_org',
        icons: {
          leftIcon: 'fal fa-industry',
        },
        settings: {
          sort: 'checked',
          multi: true,
          listStyleType: 'none',
          listDoneButton: true,
          bigModal: true,
          showTitle: true,
          callApiOn: 'done',
        },
        values: [
          {
            label: 'V8',
            value: 'v8',
          },
        ],
      },
    ],
  };
}
