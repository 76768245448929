import { Directive, Input, ElementRef, NgZone } from '@angular/core';
import { EditableColumn } from 'primeng/table';
import { CVPTableComponent } from '../table.component';

@Directive({
  selector: '[cvpEditableColumn]',
})
export class CVPEditableColumn extends EditableColumn {
  @Input('cvpEditableColumn') data: any;
  @Input('cvpEditableColumnField') field: any;

  constructor(public dt: CVPTableComponent, public el: ElementRef, public zone: NgZone) {
    super(dt, el, zone);
  }
}
