import {
  NgModule,
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Checkbox } from 'primeng/checkbox';

export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RfCheckboxComponent),
  multi: true,
};

@Component({
  selector: 'rf-checkbox',
  templateUrl: 'checkbox.component.html',
  providers: [CHECKBOX_VALUE_ACCESSOR],
})
export class RfCheckboxComponent extends Checkbox implements AfterViewChecked {
  constructor(public cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  onClick(event: any, checkbox: any, focus: boolean) {
    event.stopPropagation();
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [RfCheckboxComponent],
  declarations: [RfCheckboxComponent],
})
export class RfCheckboxModule {}
