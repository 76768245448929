import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authData: any = JSON.parse(localStorage.getItem('authorizationData'));
    if (authData) {
      let language = localStorage.getItem('language');
      if (!language) {
        language = 'en-ca';
      }
      return next.handle(
        req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + authData.token,
            'Accept-Language': language,
            'App-Version': environment.appVersion,
          },
        })
      );
    }
    return next.handle(req);
  }
}
