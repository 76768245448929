import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { LoaderService } from '@app/core/loader.service';
import { AuthServiceCleaner } from '@app/shared/services/auth.service.cleaner';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.sass'],
})
export class IdleTimeoutComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private authClearService: AuthServiceCleaner,
    private router: Router
  ) {}

  ngOnInit() {}

  toHome() {
    this.router.navigate(['/login']);
  }
}
