import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of, EMPTY, Subject } from 'rxjs';
import { RestMessage } from '@app/core/types';
import { FileUploader } from 'ng2-file-upload';
import { Logger, AuthenticationService } from '@app/core';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { OrgHierachy } from '@app/core/types/orgHierarchy';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { SurveyVersions } from '@app/core/types/suveyVesions';
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { TreeNode } from '@primeng/primeng';
import { ILanguages } from '@app/core/types/languages';
import _ from 'lodash';
import { sentimentDropdownOptions } from '../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-data';
import { GlobalFilterService } from '../module/global-filters/global-filters.service';

const log = new Logger('FilterService');

@Injectable()
export class FiltersService {
  closecontext = new Subject<any>();
  subjecttab = new Subject<any>();
  dealerChanged = new Subject<any>();
  dateFormat = 'MMM D, YYYY';
  temp: any[] = [];
  orgHierarchy: OrgHierachy[] = [];
  names: string[] = [];
  org: any;
  value: any;
  doneDate: any;
  program = 'NVS';
  activeYear: string;
  activeModalType = 'Mercedes-Benz';
  activeModal: string;
  orgApiData: any;
  reportingUrl = '/reporting';
  nodeId: string = null;
  currentOrg = 'National';
  currentLevel = 1;
  currentParent: string = null;
  dealerType = 'All';
  orgIdentifier: string;
  pageSize = 25;
  pageNo = 1;
  SortColumn = '';
  Sort = '';
  filterItem = '';
  field = '';
  dateIdentitfierMapperData = {
    R1: 'TODAY',
    R10: 'R10',
    R30: 'R30',
    MTD: 'MTD',
    QTD: 'QTD',
    YTD: 'YTD',
    'PREVIOUS MONTH': 'month',
    'PREVIOUS QUARTER': 'quarter',
    'PREVIOUS YEAR': 'year',
    'CUSTOM RANGE': 'period',
  };
  period = '';
  surveyVersion = '';
  allSurveyVersions: SurveyVersions[] = [];
  currentSurveyVersionList: SurveyVersions[] = [];
  timeZone: string;
  timeZoneCode: string;
  timeZoneOffset: number;
  orgList: any;
  languageTitle = 'All Languages';
  sentimentTitle = 'All Sentiment';
  aspectCategoryTitle = 'All Categories';
  aspectCategorySelected: TreeNode;
  surveyQuestionTitle = 'Survey Questions';
  surveyScoreTitle = 'Score Range';
  filterDisabledStatus = true;
  languageChTitle = 'All Languages';
  languageCodefiled: any[] = [];
  surveyresponsefiled: any[] = [];
  dateFilter: any = { label: '', value: '', expr: '', translate: '' };
  dateFilterList: any = [];
  modelsTypes: any = [];
  inMemoryActiveHierarchy: any;
  inMemoryModel = '';
  inMemoryCalendar = '';
  inMemoryDealerType = '';
  inMemoryDateIdentifier: string;
  MainNode: number;
  LastNode: number;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private gbfService: GlobalFilterService,
    private activated: ActivatedRoute
  ) {
    const programStorage = JSON.parse(localStorage.getItem('program'));
    if (programStorage) {
      this.program = programStorage;
    } else {
      this.program = this.authService.getProgram();
    }
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    const survey = JSON.parse(localStorage.getItem('survey_version'));
    this.orgIdentifier = h.OrgIdentifier;
    if (!this.surveyVersion) {
      if (survey) {
        this.surveyVersion = survey.version;
      } else {
        this.surveyVersion = h.DefaultSurveyVersion;
      }
      //  this.surveyVersion = h.DefaultSurveyVersion;
    }
    this.timeZone = h.TimeZone;
    this.timeZoneCode = h.TimeZoneCode;
    this.timeZoneOffset = h.TimeZoneOffset;
    this.MainNode = h.MainNodeLevel;
    this.LastNode = h.LastNodeLevel;
    // this.getLanguagesData()
    // .subscribe((res: any) => {

    //     res.forEach((t:any) => {

    //           this.languageCodefiled.push(t.LanguageCode);
    //     });
    // });
    // this.setlanguageCode(this.languageCodefiled);
    this.setInMemoryInitial('inMemoryActiveHierarchy', 'active', true);
    this.setInMemoryInitial('inMemoryModel', 'modal_type', false);
    this.setInMemoryInitial('inMemoryCalendar', 'filter_date', false);
    this.setInMemoryInitial('inMemoryDealerType', 'dealer_type', false);
    this.setInMemoryInitial('inMemoryDateIdentifier', 'date_identifier', false);
  }
  getGridFilters(reportName: string) {
    const data = JSON.parse(localStorage.getItem('authorizationData'));
    const config: [] = data.Configurations;
    const reportConfig = config.filter((e) => e['Identifier'] === 'configuration-reporting-search-filter')[0];
    const clientConfig = reportConfig['ClientConfig'];
    let fields: any[] = [];
    if (clientConfig != null && clientConfig !== undefined) {
      fields = clientConfig['Fields'];
    } else {
      fields = config['Fields'];
    }
    let filterRec = {};
    let columns: any[] = [];

    if (reportConfig !== undefined && reportConfig != null) {
      if (fields === undefined || fields == null) {
        return columns;
      } else {
        filterRec = fields.filter((x) => x['name'] === reportName)[0];
      }
    }
    if (filterRec !== undefined && filterRec != null && filterRec !== {}) {
      columns = filterRec['filters'];
    }

    return columns;
  }
  setInMemoryInitial(componentKey: string, localKey: any, jsonParse: boolean) {
    if (!this[componentKey]) {
      if (jsonParse) {
        this[componentKey] = JSON.parse(localStorage.getItem(localKey));
      } else {
        this[componentKey] = localStorage.getItem(localKey);
      }
    }
  }
  getMainNode() {
    return this.MainNode;
  }
  getLastNode() {
    return this.LastNode;
  }

  getSmReviewTypes() {
    const sourceTypes = [
      {
        Id: 'All',
        Title: 'All',
        IsChecked: true,
      },
      {
        Id: 'Dealer Rater',
        Title: 'Dealer Rater',
        IsChecked: true,
      },
      {
        Id: 'Google',
        Title: 'Google',
        IsChecked: true,
      },
      {
        Id: 'Yelp',
        Title: 'Yelp',
        IsChecked: true,
      },
    ];
    return sourceTypes;
  }

  getReportGeneratorConfig(url: string) {
    return this.httpClient.get<any>(url);
  }

  getAllOrgsReportGenerator() {
    const type = JSON.stringify(['Private', 'Corporate', 'Collision']);
    const url = `${this.reportingUrl}/orgList?orgIdentifier=${this.orgIdentifier}&Fields=${type}`;
    return this.httpClient.get<RestMessage>(url).pipe(
      tap((_: any) => log.debug('in tap getData')),
      map((res: any) => {
        this.orgApiData = res;
        return this.mapOrgData(res);
      })
    );
  }

  getAllOrgs() {
    let type;
    switch (this.dealerType) {
      case 'All':
        type = JSON.stringify(['Private', 'Corporate']);
        break;
      default:
        type = `${JSON.stringify([this.dealerType])}`;
        break;
    }
    const url = `${this.reportingUrl}/orgList?orgIdentifier=${this.orgIdentifier}&Fields=${type}`;
    return this.httpClient.get<RestMessage>(url).pipe(
      tap((_: any) => log.debug('in tap getData')),
      map((res: any) => {
        this.orgApiData = res;
        return this.mapOrgData(res);
      })
    );
  }

  getAllOrgsBydealertype() {
    let dealerType: any = {};
    if (this.inMemoryDealerType) {
      dealerType = JSON.parse(this.inMemoryDealerType);
    } else {
      dealerType = JSON.parse(localStorage.getItem('dealer_type'));
    }
    let type;
    switch (dealerType.value) {
      case 'All':
        type = JSON.stringify(['Private', 'Corporate']);
        break;
      default:
        type = `${JSON.stringify([dealerType.value])}`;
        break;
    }
    const url = `${this.reportingUrl}/orgList?orgIdentifier=${this.orgIdentifier}&Fields=${type}`;
    return this.httpClient.get<RestMessage>(url).pipe(
      tap((_: any) => log.debug('in tap getData')),
      map((res: any) => {
        this.orgApiData = res;
        return this.mapOrgData(res);
      })
    );
  }

  public mapOrgData(res: any) {
    this.org = res;
    const orgHierarchy: any = [];
    const hierarchy = this.getNodes(1, 'null');
    for (let i = 0; i < hierarchy.length; i++) {
      orgHierarchy.push(hierarchy[i]);
    }
    return orgHierarchy;
  }
  resetDefaultFilters() {
    this.activeModalType = 'Mercedes-Benz';
    this.dealerType = 'All';
    this.languageTitle = 'All Languages';
    this.surveyQuestionTitle = 'Survey Questions';
    this.sentimentTitle = 'All Sentiment';
    this.aspectCategoryTitle = 'All Categories';
    this.surveyScoreTitle = 'Score Range';
    this.program = 'Sales';
    this.activeYear = new Date().getFullYear().toString();
    this.activeModal = '';
    this.languageChTitle = 'All Languages';
  }

  public getNodes(level: number, name: string): any {
    const obj = this.searchNode(level, name);
    const nodes: any[] = [];
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].type = element.type;
        obj[index].isTerminate = element.IsTerminate;
        obj[index].terminationDate = element.TerminationDate;
        if (element.Identifier === 'Custom' && level === 2) {
          obj[index].children = this.getNodes(level + 2, element.Name);
        } else {
          obj[index].children = this.getNodes(level + 1, element.Name);
        }
        obj[index].hide = false;
        if (element.IsTerminate && element.TerminationDate) {
          const found = moment(element.TerminationDate).format(this.dateFormat);
          if (moment(found).isBetween(this.getCalenderRange()[0], this.getCalenderRange()[1], undefined, '(]')) {
            nodes.push(obj[index]);
          } else {
            obj[index].hide = true;
            nodes.push(obj[index]);
          }
        } else {
          nodes.push(obj[index]);
        }
      });
    }
    return nodes;
  }
  public getNodesByName(level: number, name: string) {
    const obj = this.searchNodeByName(level, name);
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].children = this.getNodesByName(level + 1, element.Name);
      });
    }
    return obj;
  }
  searchNode(level: number, name: string): any {
    for (let i = 0; i < this.org.length; i++) {
      if (this.org[i].Level === level) {
        for (let j = 0; j < this.org[i].Data.length; j++) {
          if (this.org[i].Data[j].Parent === name) {
            for (let k = 0; k < this.org[i].Data[j].Nodes.length; k++) {
              this.org[i].Data[j].Nodes[k].parent = this.org[i].Data[j].Parent;
            }
            return this.org[i].Data[j].Nodes;
          }
        }
      }
    }
    return [];
  }
  searchNodeByName(level: number, name: string): any {
    for (let i = 0; i < this.org.length; i++) {
      if (this.org[i].Level === level) {
        for (let j = 0; j < this.org[i].Data.length; j++) {
          // if (this.org[i].Data[j].Name === name) {
          for (let k = 0; k < this.org[i].Data[j].Nodes.length; k++) {
            if (this.org[i].Data[j].Nodes[k].Name === name) {
              return this.org[i].Data[j].Nodes;
              // this.org[i].Data[j].Nodes[k].Name = this.org[i].Data[j].Name;
            }
          }
          // return this.org[i].Data[j].Nodes;
          // }
        }
      }
    }
    return [];
  }
  updateCalendarRange(value: any) {
    this.value = value;
  }
  setTab(message: string) {
    this.subjecttab.next({ text: message });
  }

  clearTab() {
    this.subjecttab.next();
  }

  getTab(): Observable<any> {
    return this.subjecttab.asObservable();
  }

  getCloseContext(): Observable<any> {
    return this.closecontext.asObservable();
  }

  resetSurveyResponse() {
    if (localStorage.getItem('survey_responses') !== null) {
      localStorage.removeItem('survey_responses');
    }
    if (localStorage.getItem('survey_question') !== null) {
      localStorage.removeItem('survey_question');
    }
  }

  getSurveyQuestionData(): any {
    const sv = this.getSurveyVersion();
    const authData = JSON.parse(localStorage.getItem('authorizationData'));

    if (authData && authData.DefaultLanguage) {
      const url = `/voc/questions/${sv}/${authData.DefaultLanguage}`;
      return this.httpClient.cache().get<RestMessage>(`${url}`);
    } else {
      return of([]);
    }
  }

  getSurveyResponseData(): any {
    const sv = this.getSurveyVersion();
    const authData = JSON.parse(localStorage.getItem('authorizationData'));

    if (authData && authData.DefaultLanguage) {
      const url = `/voc/response/${sv}/${authData.DefaultLanguage}`;
      return this.httpClient.cache().get<RestMessage>(`${url}`);
    } else {
      return of([]);
    }
  }

  getValue() {
    let date: any = null;
    if (this.inMemoryCalendar) {
      date = this.inMemoryCalendar;
    } else {
      date = localStorage.getItem('filter_date') || null;
    }
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        return [date[0], date[1]];
      }
    } else {
      const thisYear = new Date().getFullYear();
      const start = new Date('1/1/' + thisYear);
      const defaultStart = moment(start.valueOf()).toDate();
      return this.value ? this.value : [defaultStart, moment().toDate()];
    }
  }
  getLocalStorageModalType() {
    let d: any = null;
    if (this.inMemoryModel) {
      d = JSON.parse(this.inMemoryModel);
    } else {
      d = localStorage.getItem('modal_type');
    }
    return d;
  }
  getModelType() {
    const d = this.getLocalStorageModalType();
    if (d && d.activeModal && d.activeModalType) {
      return {
        activeModalType: d.activeModalType.label,
        activeModal: d.activeModal.label,
      };
    } else {
      return {
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
      };
    }
  }

  updateModel(modalYear: string = '', modalType: string = '', modal: string = '') {
    if (modalYear !== '') {
      this.activeYear = modalYear;
    } else {
      this.activeYear = '';
    }
    this.activeModalType = modalType;
    this.activeModal = modal;
  }
  getCalenderRange() {
    let date: any = null;
    if (this.inMemoryCalendar) {
      date = this.inMemoryCalendar;
    } else {
      date = localStorage.getItem('filter_date') || null;
    }
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        const start = moment(date[0]).format('MMM D, YYYY');
        const end = moment(date[1]).format('MMM D, YYYY');
        const v = [start, end];
        this.inMemoryCalendar = v.join('|');
        localStorage.setItem('filter_date', v.join('|'));
        return v;
      }
    } else {
      const start = moment().startOf('month').format('MMM D, YYYY');
      const end = moment().format('MMM D, YYYY');
      const v = [start, end];
      this.inMemoryCalendar = v.join('|');
      localStorage.setItem('filter_date', v.join('|'));
      return this.value ? this.value : v;
    }
  }

  getActiveOrgHierarchy() {
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;
    this.nodeId = h.OrganizationName;
    let active: any = [];
    if (this.inMemoryActiveHierarchy) {
      active = this.inMemoryActiveHierarchy;
    } else {
      active = JSON.parse(localStorage.getItem('active'));
    }
    if (active && active.length > 0) {
      // if (active.length == 3) {
      let lastActive = active[active.length - 1];
      if (!lastActive.active.Name) {
        lastActive = active[active.length - 2];
      }
      if (lastActive && lastActive.active) {
        let o = null;
        if (lastActive.active.parent && lastActive.active.parent.Name) {
          o = lastActive.active.parent.Name;
        } else if (lastActive.active.parent) {
          o = lastActive.active.parent;
        }
        if (typeof o === 'object') {
          o = 'null';
        }
        return {
          OrgName: lastActive.active.NodeId,
          OrgLevel: lastActive.active.level,
          OrgParent: o,
          Identifier: lastActive.active.Identifier,
        };
      } else {
        return {
          OrgName: this.nodeId, // 'National',
          OrgLevel: this.currentLevel,
          OrgParent: this.currentParent,
          Identifier: this.orgIdentifier,
        };
      }
      // }
      // else {
      //     const lastActive = active[active.length - 1];
      //     if (lastActive && lastActive.active) {
      //         return {
      //             OrgName: lastActive.active.Name,
      //             OrgLevel: lastActive.active.level,
      //             OrgParent: lastActive.active.parent,
      //         };
      //     }
      //     else {
      //         return {
      //             OrgName: 'National',
      //             OrgLevel: 1,
      //             OrgParent: null,
      //         }
      //     }

      // }
    } else {
      return {
        OrgName: this.nodeId,
        OrgLevel: this.currentLevel,
        OrgParent: this.currentParent,
        Identifier: this.orgIdentifier,
      };
    }
  }

  setProgram(program: string) {
    this.program = program;
    // this.resetSurveyResponse();
  }
  setSurveyVersion(versionName: string = '', version: string = '') {
    localStorage.setItem('survey_version', JSON.stringify({ version: version, versionName: versionName }));
    this.surveyVersion = version;
  }
  getSurveyVersion() {
    //JSON.parse(localStorage.gbf_version).value

    //const survey = JSON.parse(localStorage.getItem('survey_version'));
    const survey = JSON.parse(localStorage.getItem('gbf_version'));
    if (JSON.parse(localStorage.getItem('gbf_version'))) {
      return survey.version;
    } else {
      return this.surveyVersion;
    }
    // return this.surveyVersion;
  }
  setPaging(size: number, page: number) {
    this.pageSize = size;
    this.pageNo = page;
    this.gbfService.pageSize = size;
    this.gbfService.pageNo = page;
  }
  setSorting(field: string, order: number) {
    this.SortColumn = field;
    this.Sort = order.toString();
    this.gbfService.SortColumn = field;
    this.gbfService.Sort = order.toString();
  }
  getDealerType() {
    let dealerType: any = {};
    if (this.inMemoryDealerType) {
      dealerType = JSON.parse(this.inMemoryDealerType);
    } else {
      dealerType = JSON.parse(localStorage.getItem('dealer_type'));
    }
    if (dealerType && dealerType.label) {
      return { ...dealerType, default: false };
    } else {
      const authData = JSON.parse(localStorage.getItem('authorizationData'));
      if (authData) {
        switch (authData['FilterType']) {
          case 'Private':
            return {
              label: this.translateService.instant('mb_private_only'),
              translate: 'mb_private_only',
              value: 'Private',
            };
          case 'Corporate':
            return {
              label: this.translateService.instant('mb_corporate_only'),
              translate: 'mb_corporate_only',
              value: 'Corporate',
            };
          case 'Collision':
            return {
              label: this.translateService.instant('collision_centres'),
              translate: 'collision_centres',
              value: 'Collision',
            };
          default:
            return {
              label: this.translateService.instant('all_mb_(corporate_&_private)'),
              value: 'All',
              translate: 'all_mb_(corporate_&_private)',
              default: true,
            };
        }
      } else {
        return {
          label: this.translateService.instant('all_mb_(corporate_&_private)'),
          value: 'All',
          translate: 'all_mb_(corporate_&_private)',
          default: true,
        };
      }
    }
  }
  setField(field: string) {
    this.field = field;
    this.gbfService.field = field;
  }
  setFilters(filtersItem: string) {
    this.filterItem = filtersItem;
    this.gbfService.filterItem = filtersItem;
  }
  setDateFilter(value: string, label: string, translate: string, expr: string) {
    const jData = JSON.stringify({ value, label, translate });
    this.inMemoryCalendar = jData;
    localStorage.setItem('date_filter', jData);
    this.dateFilter = { value, label, translate, expr };
  }
  setDealerType(value: string, label: string, translate: string, clearActiveHierchy: boolean = true) {
    const jData = JSON.stringify({ value, label, translate });
    this.inMemoryDealerType = jData;
    if (clearActiveHierchy) {
      this.inMemoryActiveHierarchy = [];
      localStorage.setItem('active', '[]');
    }
    localStorage.setItem('dealer_type', jData);
    this.dealerChanged.next(value);
    this.dealerType = value;
  }

  setlanguageCode(language: any[]) {
    this.languageCodefiled = language;
  }
  getlanguageCode() {
    this.languageCodefiled = [];
    const languages = JSON.parse(localStorage.getItem('Preserve_LanguageCode'));
    if (languages) {
      languages.map((x: any) => {
        if (x.checked && x.Name !== 'All') {
          this.languageCodefiled.push(x.LanguageCode);
        }
      });
    } else {
      this.languageCodefiled = ['en-CA', 'fr-CA', 'ct-CA', 'ch-CA', 'OL'];
    }
    if (this.languageCodefiled && this.languageCodefiled.length > 0) {
      return this.languageCodefiled;
    } else {
      return (this.languageCodefiled = ['en-CA', 'fr-CA', 'ct-CA', 'ch-CA', 'OL']);
    }
  }

  setsureyresponcefilter(data: any) {
    localStorage.removeItem('sureyresponce_filter');
    localStorage.setItem('sureyresponce_filter', JSON.stringify(data));
  }
  getsureyresponcefilter() {
    const DataResponse = JSON.parse(localStorage.getItem('Preserve_survey_responses'));
    if (DataResponse) {
      DataResponse.map((x: any) => {
        if (x.Options.length > 0) {
          x.Options = x.Options.filter((op: any) => {
            const flag = op.IsChecked;
            return flag === true;
          });
        }
      });
      const res = DataResponse.filter((x: any) => x.Id !== 'All' && x.IsChecked === true && x.Options.length > 0);
      if (res && res.length > 0) {
        return res;
      } else {
        return DataResponse;
      }
    } else {
      return [];
    }
  }

  setSurveyresponseFiels(response: any[]) {
    //  const res = [...response];

    const res = response.filter((x) => x.Id !== 'All Survey Responses');
    if (res && res.length > 0) {
      res.forEach((val: any) => {
        delete val.IsChecked;
        delete val.IsSelected;
        delete val.IsAddClass;
        val.Options = val.Options.filter((o: any) => {
          const flag = o.IsChecked;
          delete o.IsChecked;
          delete o.IsSelected;
          return flag === true;
        });
      });
      this.surveyresponsefiled = res;
    }
  }
  getFilterObject() {
    const gbfilters = this.gbfService.getFilterObject();
    return gbfilters;
    this.getModelType();
    const dateRange = this.getCalenderRange();
    const activeHierarchy = this.getActiveOrgHierarchy();

    const filters = {
      Event: this.program,
      StartDate: dateRange[0],
      EndDate: dateRange[1],
      OrgName: activeHierarchy.OrgName, //NodeId
      OrgLevel: activeHierarchy.OrgLevel, // Org Parent
      OrgParent: activeHierarchy.OrgParent, // Parent
      OrgIdentifier: activeHierarchy.Identifier, //Identifier
      PageSize: this.pageSize,
      PageNo: this.pageNo,
      ActiveModal: this.getModelType().activeModal,
      ActiveModalClass: this.getModelType().activeModalType,
      ActiveModalYear: this.activeYear,
      DealerType: this.getDealerType().value,
      SurveyVersion: this.surveyVersion,
      DateIdentifier: this.getDateIdentifier(),
      Fields: this.field,
      Filters: this.filterItem,
      Status: '',
      SortColumn: this.SortColumn,
      Sort: this.Sort,
    };
    return filters;
  }

  getFilterObjectForReviewList() {
    const filters: any = this.getFilterObject();
    return filters;
    // filters.SourceType = this.getSourceType(),
    // filters.LanguageCode = this.getlanguageCode();
    // filters.CategoryId = this.getAspectCategory() ? this.getAspectCategory().key : null;
    // filters.CategoryName = this.getAspectCategory() ? this.getAspectCategory().label : null;
    // filters.CategoryLevel = this.getAspectCategory() ? this.getAspectCategory().leaf : null;
    // filters.SentimentType = this.getSentiment() ? this.getSentiment().value : null;
  }
  getFilterObjectForVOCDashboard() {
    const gbfilters = this.gbfService.getFilterObject();
    return gbfilters;
    // this.getModelType();
    // const dateRange = this.getCalenderRange();
    // const activeHierarchy = this.getActiveOrgHierarchy();
    // const category = this.gbfService.getCategory();
    // if(category.CategoryLevel < 1)
    // category.CategoryLevel = 1;

    // gbfilters.LanguageCode = this.gbfService.getlanguageCode().LanguageCode;
    // const surveyScore = this.gbfService.getSurveyScore();
    // gbfilters.ScoreRangeType = surveyScore.ScoreRangeType;
    // gbfilters.ScoreRangeMin = surveyScore.ScoreRangeMin;
    // gbfilters.ScoreRangeMax = surveyScore.ScoreRangeMax;
    // gbfilters.CategoryLevel = category.CategoryLevel;
    // const filters = {
    //     Event: this.program,
    //     StartDate: dateRange[0],
    //     EndDate: dateRange[1],
    //     OrgName: activeHierarchy.OrgName,
    //     OrgLevel: activeHierarchy.OrgLevel,
    //     OrgParent: activeHierarchy.OrgParent,
    //     PageSize: this.pageSize,
    //     PageNo: this.pageNo,
    //     ActiveModal: this.getModelType().activeModal,
    //     ActiveModalClass: this.getModelType().activeModalType,
    //     ActiveModalYear: this.activeYear,
    //     DealerType: this.getDealerType().value,
    //     LanguageCode: this.getLanguage() ? this.getLanguage().LanguageCode : null,
    //     CategoryId:  category.CategoryId,//this.getAspectCategory() ? this.getAspectCategory().key : null,
    //     CategoryName: category.CategoryName, //this.getAspectCategory() ? this.getAspectCategory().label : null,
    //     CategoryLevel: category.CategoryLevel,//this.getAspectCategory() ? this.getAspectCategory().leaf : null,
    //     SurveyQuestions: this.getSurveyQuestionObj(),
    //     SurveyResponses: this.getsureyresponcefilter(), // this.surveyresponsefiled,//this.getSurveyQuesRespObj(),
    //     OrgIdentifier: this.orgIdentifier,
    //     SurveyVersion: this.surveyVersion,
    //     ScoreRangeType: this.getSurveyScore().Type,
    //     ScoreRangeMin: this.getSurveyScore().Score[0],
    //     ScoreRangeMax: this.getSurveyScore().Score[1],
    //     Fields: this.field,
    //     Filters: this.filterItem,
    //     Status: '',
    //     SortColumn: this.SortColumn,
    //     Sort: this.Sort,
    // };
    // return gbfilters;
  }

  getFilterObjectForTreeReportSM() {
    const filters: any = this.getFilterObjectForVOCDashboard();
    return filters;
    // filters.SourceType = this.getSourceType();
    // return filters;
  }
  getFilterObjectForSoocialMedia() {
    const filters: any = this.getFilterObject();
    return filters;
    // filters.SourceType = this.getSourceType(),
    //     filters.LanguageCode = this.getlanguageCode(),
    //     filters.CategoryName = this.getAspectCategory() ? this.getAspectCategory().label : null;
    // filters.CategoryLevel = this.getAspectCategory() ? this.getAspectCategory().leaf : null;
  }

  getFilterObjectAsQueryParams() {
    const filters = this.getFilterObject();
    return Object.keys(filters)
      .map((key) => key + '=' + filters[key])
      .join('&');
  }
  getSentimentData(includeUnassigend: any) {
    const index = sentimentDropdownOptions.findIndex((x) => x.label == 'Unclassified');
    if (index >= 0 && includeUnassigend === false) {
      return sentimentDropdownOptions.filter((x) => x.label !== 'Unclassified');
    }
    if (includeUnassigend) {
      if (index < 0) {
        sentimentDropdownOptions.push({
          label: 'Unclassified',
          value: 3,
        });
      }
    }
    return sentimentDropdownOptions;
  }

  getFilterObjectForVOCCommentList() {
    const customfilter = this.getFilterObjectForVOCDashboard() as any;
    return customfilter;
    // customfilter.SentimentType = this.getSentiment() ? this.getSentiment().value : null;
    // customfilter.LanguageCode = this.gbfService.getlanguageCode().LanguageCode; // this.getLanguageCh() ? this.getLanguageCh().LanguageCode : null;
  }

  getSentiment() {
    const vocSentiment = JSON.parse(localStorage.getItem('voc_sentiment'));
    if (vocSentiment && vocSentiment.label) {
      return { ...vocSentiment, default: false };
    } else {
      return {
        label: this.translateService.instant('All Sentiment'),
        value: null,
      };
    }
  }

  setSentiment(data: any) {
    localStorage.setItem('voc_sentiment', JSON.stringify(data));
    this.sentimentTitle = data.label;
    this.gbfService.setSentiment(this.sentimentTitle);
  }

  getGbfSentiment() {
    return this.gbfService.getSentiments('', {});
  }

  setGbfSentiment(data: any) {
    localStorage.setItem('gbf_sentiments', JSON.stringify(data));
    this.gbfService.sentiment = data;
  }

  getAspectCategoryData() {
    const url = `/voc/categories`;
    return this.httpClient.cache().get<RestMessage>(`${url}`);
  }

  setAspectCategory(data: any) {
    localStorage.setItem('voc_category', JSON.stringify(data));
    this.aspectCategorySelected = data;
  }

  getAspectCategory() {
    const aspectCategory = JSON.parse(localStorage.getItem('voc_category'));
    if (aspectCategory && aspectCategory.label) {
      return aspectCategory;
    } else {
      return {
        label: 'All Categories',
      };
    }
  }

  getSurveyQuestionObj() {
    let sq = this.getSurveyQuestion();
    if (sq && sq.length > 0) {
      const isselectAll = sq.filter((x: any) => x.IsChecked === true);
      if (isselectAll.length > 0) {
        if (sq && sq.length > 0) {
          // sq.forEach((val: any) => {
          //     // delete val.IsChecked;
          //     delete val.IsSelected;
          //     val.Options = val.Options.filter((o: any) => {
          //         const flag = o.IsChecked;
          //         // delete o.IsChecked;
          //         delete o.IsSelected;
          //         return (flag) ? true : false;
          //     });
          // });
          sq = sq.filter((obj: any) => {
            // delete obj.IsChecked;
            delete obj.IsSelected;
            obj.Options = obj.Options.filter((o: any) => {
              const flag = o.IsChecked;
              delete o.IsChecked;
              delete o.IsSelected;
              return flag ? true : false;
            });
            return obj.Id.toLowerCase() !== 'all' && obj.IsChecked === true;
          });
        }
      } else {
        if (sq && sq.length > 0) {
          sq = sq.filter((obj: any) => {
            delete obj.IsSelected;
            obj.Options = obj.Options.filter((o: any) => {
              const flag = o.IsChecked;
              delete o.IsChecked;
              delete o.IsSelected;
              return flag ? true : false;
            });
            return obj.Id.toLowerCase() !== 'all';
          });
        }
      }
    }
    return sq;
  }

  setAspectCategoryBC(data: any) {
    localStorage.setItem('voc_category_bc', JSON.stringify(data));
  }

  getAspectCategoryBC() {
    const aspectCategoryBC = JSON.parse(localStorage.getItem('voc_category_bc'));
    return aspectCategoryBC;
  }

  getSurveyScore() {
    const surveyScore = JSON.parse(localStorage.getItem('survey_score'));
    if (surveyScore && surveyScore.Type) {
      return { ...surveyScore, default: false };
    } else {
      return {
        Type: 'Survey Score',
        Score: [0, 100],
      };
    }
  }

  getSurveyQuesRespObj() {
    const sqr = this.getSurveyResponses();
    if (sqr && sqr.length > 0) {
      sqr.forEach((val: any) => {
        delete val.IsChecked;
        delete val.IsSelected;
        val.Options = val.Options.filter((o: any) => {
          const flag = o.IsChecked;
          delete o.IsChecked;
          delete o.IsSelected;
          return flag ? true : false;
        });
      });
    }
    return sqr;
  }

  setLanguage(data: ILanguages) {
    const name = data.Name;
    localStorage.setItem('voc_language', JSON.stringify(data));
    this.languageTitle = data ? `${data.Name} (${data.LanguageCode})` : null;
  }

  getLanguage() {
    const vocLanguage = JSON.parse(localStorage.getItem('voc_language'));
    if (vocLanguage && vocLanguage.Name) {
      return { ...vocLanguage, default: false };
    }
  }

  getLanguagesData() {
    const url = `/voc/languages`;
    return this.httpClient.cache().get<RestMessage>(`${url}`);
  }

  getSurveyQuestion() {
    const surveyQuestion = JSON.parse(localStorage.getItem('survey_question'));
    if (surveyQuestion) {
      return surveyQuestion;
    }
  }

  getSourceType() {
    const sourceType = localStorage.getItem('source_type');
    if (sourceType) {
      return JSON.parse(sourceType);
    }

    return this.getSmReviewTypes();
  }

  getDateIdentifier() {
    if (this.inMemoryDateIdentifier) {
      return this.inMemoryDateIdentifier;
    }
    if (localStorage.getItem('date_identifier')) {
      const dateIdentifier = localStorage.getItem('date_identifier');
      if (dateIdentifier) {
        return dateIdentifier;
      }
    }
  }

  getDateIdentifierMapper() {
    return this.gbfService.getDateIdentifier();
  }

  getSurveyResponses() {
    const surveyResponses = JSON.parse(localStorage.getItem('survey_responses'));
    if (surveyResponses && surveyResponses.length > 0) {
      return surveyResponses;
    }
  }
  setSurveyResponses(data: any) {
    localStorage.setItem('survey_responses', JSON.stringify(data));
  }

  setSourceTypeResponses(data: any) {
    localStorage.setItem('source_type_responses', JSON.stringify(data));
  }

  setFilterPreserveSurveyResponses(data: any) {
    localStorage.removeItem('Preserve_survey_responses');
    localStorage.setItem('Preserve_survey_responses', JSON.stringify(data));
  }
  getFilterPreserveSurveyResponses() {
    return JSON.parse(localStorage.getItem('Preserve_survey_responses'));
  }
  setFilterPreserveSurveyResponsesOptions(data: any) {
    localStorage.removeItem('Preserve_survey_responses_Options');
    localStorage.setItem('Preserve_survey_responses_Options', JSON.stringify(data));
  }
  getFilterPreserveSurveyResponsesOptions() {
    return JSON.parse(localStorage.getItem('Preserve_survey_responses_Options'));
  }
  setFilterPreserveSurveyQuestion(data: any) {
    localStorage.setItem('Preserve_survey_Questions', JSON.stringify(data));
  }
  getFilterPreserveSurveyQuestion() {
    return JSON.parse(localStorage.getItem('Preserve_survey_Questions'));
  }
  setFilterPreserveLanguageCode(data: any) {
    localStorage.removeItem('Preserve_LanguageCode');
    localStorage.setItem('Preserve_LanguageCode', JSON.stringify(data));
  }
  getFilterPreserveLanguageCode() {
    return JSON.parse(localStorage.getItem('Preserve_LanguageCode'));
  }
  setFilterPreserveSurveyresponseLabel(data: any) {
    localStorage.removeItem('Preserve_SurveyresponseLabel');
    localStorage.setItem('Preserve_SurveyresponseLabel', JSON.stringify(data));
  }
  getFilterPreserveSurveyresponseLabel() {
    return JSON.parse(localStorage.getItem('Preserve_SurveyresponseLabel'));
  }
  setFilterPreserveLanguageLabel(data: any) {
    localStorage.removeItem('Preserve_LanguageLabel');
    localStorage.setItem('Preserve_LanguageLabel', JSON.stringify(data));
  }
  getFilterPreserveLanguageLabel() {
    return JSON.parse(localStorage.getItem('Preserve_LanguageLabel'));
  }
  setSurveyScore(data: any) {
    localStorage.setItem('survey_score', JSON.stringify(data));
    this.surveyScoreTitle = data.Type;
  }

  setRankingReportSliderScore(data: any) {
    localStorage.setItem('survey_score', JSON.stringify(data));
    this.surveyScoreTitle = data.Type;
  }

  setSurveyQuestion(data: any) {
    localStorage.setItem('survey_question', JSON.stringify(data));
  }
  setSourceType(data: any) {
    localStorage.setItem('source_type', JSON.stringify(data));
  }
  setDateIdentifier(data: any) {
    const jData = data;
    this.inMemoryDateIdentifier = jData;
    localStorage.setItem('date_identifier', jData);
  }

  getVindecoderData() {
    const url = `/Vindecoder/GetVindecoderFilters`;
    return this.httpClient.cache().get<RestMessage>(url);
  }

  getSurveyVersions() {
    const url = `/surveylist/GetSurveyVersions`;
    return this.httpClient.get<RestMessage>(url);
    // .pipe(
    //     tap((_: any) => log.debug('in tap getData')),
    //     map((res: any) => {
    //         return this.mapVersionData(res);
    //     })
    // );
  }

  setLanguageCh(data: ILanguages) {
    const name = data.Name;
    localStorage.setItem('voc_language_ch', JSON.stringify(data));
    this.languageChTitle = data ? `${data.Name} (${data.LanguageCode})` : null;
  }

  getLanguageCh() {
    const vocLanguageCh = JSON.parse(localStorage.getItem('voc_language_ch'));
    if (vocLanguageCh && vocLanguageCh.Name) {
      return { ...vocLanguageCh, default: false };
    }
  }

  // getLanguagesChData() {
  //     const url = `/voc/languages`;
  //     return this.httpClient
  //         .cache()
  //         .get<RestMessage>(`${url}`);
  // }

  mapVersionData(data: any) {
    this.allSurveyVersions = [];
    data.forEach((o: any) => {
      const row: SurveyVersions = {};
      row.Event = o.Event;
      row.Brand = o.Brand;
      row.SurveyId = o.SurveyId;
      row.ParentSurveyId = o.ParentSurveyId;
      row.Version = o.Version;
      row.CurrentSurvey = o.CurrentSurvey;

      this.allSurveyVersions.push(row);
    });
  }

  ResetFilters() {
    const dateRange = this.getCalenderRange();
    const activeHierarchy = this.getActiveOrgHierarchy();
    this.activeModalType = 'Mercedes-Benz';
    this.activeModal = '';
    const filters = {
      Event: this.program,
      StartDate: dateRange[0],
      EndDate: dateRange[1],
      // OrgName: 'National',
      // OrgLevel: 1,
      // OrgParent: 'null',
      // Query: '',
      PageSize: 25,
      PageNo: 0,
      ActiveModalClass: 'Mercedes-Benz',
      ActiveModalYear: '',
      DealerType: 'All',
    };
    return filters;
  }

  getProgram() {
    return this.program;
  }
}

// Extra Work

// public addChildren(i1: number, j1: number, k1: number, orgHierarchy: any) {
//     var result = orgHierarchy.find((x: any) => {
//         return x.label === this.org[i1].data[j1].parent;
//     });
//     var index = orgHierarchy.indexOf(result);
//     if (~index) {
//         orgHierarchy[index].children.push({
//             label: this.org[i1].data[j1].nodes[k1].name,
//             level: this.org[i1].level,
//             parent: orgHierarchy[index],
//             children: []
//         });
//     }
// }
// this.temp.sort((a: any, b: any) => {
//     if (a.level < b.level) {
//         return -1;
//     }
//     if (a.level > b.level) {
//         return 1;
//     }
//     return 0;
// });
// this.orgHierarchy.push({level: this.temp[0].level, parent: this.temp[0].parent, children: [], label: this.temp[0].childs[0].name})
// this.orgHierarchy.push({level: this.temp[0].level, parent: this.temp[0].parent, children: [], label: this.temp[0].childs[1].name})
// for(let i = 1 ; i < this.temp.length; i++) {
//     var result = this.orgHierarchy.find((x: any) => {
//         return x.label === this.temp[i].parent;
//     });
//     var index = this.orgHierarchy.indexOf(result);
//     if (~index) {
//         this.orgHierarchy[index].children = this.temp[i].childs;
//     }
// }

// for (let i = this.org.length - 1; i > 0; i--) {
//     let children: any = [];
//     for (let j = 0; j < this.org[i].data.length; j++) {
//         let child: any = [];
//         for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//             child.push({ label: this.org[i].data[j].nodes[k].name })
//         }
//         children.push({ parent: this.org[i].data[j].parent, children: child, level: this.org[i].level })
//     }
//     this.temp.push(children);
// }

// for (let i = 0; i < this.org.length; i++) {
//     for (let j = 0; j < this.org[i].data.length; j++) {
//         if (this.org[i].data[j].parent) {
//             if (this.org[i].data[j].parent != 'null' && this.org[i].data[j].parent != '') {
//                 for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//                     if (this.org[i].level == 2) {
//                         this.addChildren(i, j, k, this.orgHierarchy);
//                     } else if (this.org[i].level == 3) {
//                         for(let x = 0 ; x < this.orgHierarchy.length ;x++) {
//                             this.addChildren(i, j, k, this.orgHierarchy[x].children);
//                         }

//                     } else if (this.org[i].level == 4) {
//                         for(let x = 0 ; x < this.orgHierarchy.length ;x++) {
//                             for(let y = 0; y < this.orgHierarchy[x].children.length ; y++) {
//                                 this.addChildren(i, j, k, this.orgHierarchy[x].children[y].children);
//                             }
//                         }
//                     }

//                 }

//             } else {
//                 for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//                     this.orgHierarchy.push({
//                         label: this.org[i].data[j].nodes[k].name,
//                         level: this.org[i].level,
//                         parent: null,
//                         children: []
//                     });
//                 }
//             }

//         }
//     }

// }
/*if (level == 1) {
    let child = [];
    for (let i = 0; i < this.org[level - 1].data[0].nodes.length; i++) {
        child.push({ children: [], level: this.org[level - 1].level, parent: this.org[level - 1].data[0].parent, label: this.org[level - 1].data[0].nodes[i].name })
    }
    // orgHierarchy.push(child);
    return child;
} else if (level != 1 && level <= 4) {
    var child = [];
    for (let i = 0; i < this.org[level - 1].data.length; i++) {
        for (let j = 0; j < this.org[level - 1].data[i].nodes.length; j++) {
            child.push({ children: [], level: this.org[level - 1].level, parent: this.org[level - 1].data[i].parent, label: this.org[level - 1].data[i].nodes[j].name })

        }
    }
    // orgHierarchy.push(child);
    return child;



}*/

// }
// recursion(orgArray: any, orgHierarchy: any) {
//     for(let i = 0 ; i < orgArray.length ; i++) {
//         for(let j = 0 ; j < orgArray[i].children.length ; j++) {
//             orgHierarchy.push({label: orgArray[i].children[j].label, children:[]})
//         }

//     }

// }
