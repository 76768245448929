import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutSsoComponent } from './logout-sso/logout-sso.component';
import { SsoComponent } from './sso/sso.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { IdleTimeoutComponent } from './idle-timeout/idle-timeout.component';
import { SignoutMessageComponent } from './signout-message/signout-message.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: extract('Login') },
  },
  {
    path: 'login/:id',
    component: LoginComponent,
    data: { title: extract('Login') },
  },
  {
    path: 'sso',
    component: SsoComponent,
    data: { title: extract('sso') },
  },
  {
    path: 'logoutsso',
    component: LogoutSsoComponent,
    data: { title: extract('Logout') },
  },
  {
    path: 'endsession',
    component: EndSessionComponent,
    data: { title: extract('End Session') },
  },
  {
    path: 'idle-timeout',
    component: IdleTimeoutComponent,
    data: { title: extract('Idle Timeout') },
  },
  {
    path: 'signout-message',
    component: SignoutMessageComponent,
    data: {
      breadcrumb: 'signout-message',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoginRoutingModule {}
