import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import * as JSPDF from 'jspdf';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FiltersService } from '@app/shared/services/filters.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

declare function getHtmlElement(
  doc: HTMLElement,
  name: string,
  obj: any,
  printWithEvent: boolean,
  translations: any,
  printOrgNameAndBrand: boolean
): any;

@Injectable({ providedIn: 'root' })
export class HelperService {
  constructor(
    private filtersService: FiltersService,
    public translateService: TranslateService,
    public translationHelperService: TranslationHelperService,
    public gbfService: GlobalFilterService
  ) {}

  saveFile(name: string, type: string, data: any) {
    if (data != null && navigator.msSaveBlob) {
      return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    }
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none;');
    const blob = new Blob([data], { type: type });
    const fr = new FileReader();
    const that = this;
    fr.onload = function () {
      const r = JSON.parse(JSON.stringify(this.result));
      const blob2 = new Blob([data], { type: type });
      const url = window.URL.createObjectURL(blob2);
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      document.getElementsByTagName('body')[0].append(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    };

    fr.readAsText(blob);
  }
  printPdf(fileName: string) {
    const data = document.documentElement;

    const widget = data.getElementsByClassName(
      'ng-trigger ng-trigger-overlayAnimation ng-tns-c9-3 action--menu ui-menu ui-widget ui-widget-content ui-corner-all ui-menu-dynamic ui-shadow ng-star-inserted'
    )[0];
    if (widget) {
      data
        .getElementsByClassName(
          'ng-trigger ng-trigger-overlayAnimation ng-tns-c9-3 action--menu ui-menu ui-widget ui-widget-content ui-corner-all ui-menu-dynamic ui-shadow ng-star-inserted'
        )[0]
        .setAttribute('data-html2canvas-ignore', 'true');
    }

    // html2canvas(data).then(canvas => {
    //     // Few necessary setting options
    //     const imgWidth = 208;
    //     const pageHeight = 295;
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     const heightLeft = imgHeight;

    //     const contentDataURL = canvas.toDataURL('image/png');

    //     const pdf = new JSPDF.default('p', 'mm', 'a4');
    //     const position = 0;
    //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //     pdf.save(fileName); // Generated PDF
    // });

    html2canvas(data).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const doc = new JSPDF.default('p', 'mm');
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      // Generated PDF
      doc.save('asdfghj' + '.pdf');
    });
  }
  public exportJsonAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // var range = XLSX.utils.decode_range(worksheet['!ref']);
    // let C, R = range.s.r;
    // for (C = range.s.c; C <= range.e.c; ++C) {

    //     const headerCell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })];
    //     if (headerCell && headerCell.t) {
    //         worksheet[XLSX.utils.encode_cell({ c: C, r: R })].s = { font: { bold: true } };
    //     }
    // }
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportTableAsExcelFile(
    htmlElement: HTMLElement,
    excelFileName: string,
    printWithEvent: boolean = true,
    isRawString: boolean = false,
    printOrgNameAndBrand: boolean = true,
    startDate: string = null,
    endDate: string = null
  ): void {
    try {
      var obj = this.filtersService.getFilterObject();
      var activeHierarchy = this.gbfService.getActiveOrgHierarchy();
      var hierarchy = JSON.parse(localStorage.getItem('gbf_org'));
      if (hierarchy != null) {
        var orgName = '';
        var count = hierarchy.selectedTreeItems.length;
        hierarchy.selectedTreeItems.forEach((element: any, index: number, arr: any[]) => {
          if ((element.label != null || element.label !== undefined) && element.level <= activeHierarchy.OrgLevel) {
            if (index === 0) {
              orgName = element.label;
            } else {
              orgName += ' > ' + element.label;
            }
          }
        });
        if (orgName !== '') {
          count = null;
          hierarchy = null;
          obj.OrgName = orgName;
        }
      } else {
        obj.OrgName = activeHierarchy.OrgName;
      }

      if (startDate && endDate) {
        obj.StartDate = startDate;
        obj.EndDate = endDate;
      }

      obj.Event = this.translationHelperService.GetEventTranslation(obj.Event);
      var newobj: any = {};
      Object.keys(obj).forEach((key: Extract<keyof typeof Object, string>) => {
        newobj[key] = {
          translation: this.translateService.instant(key),
          value: obj[key],
        };
      });
      var translations = {
        daterange: this.translateService.instant('Date Range'),
        reportname: this.translateService.instant('Report Name'),
        brand: this.translateService.instant('Brand'),
        eventtype: this.translateService.instant('Event Type'),
        reportdate: this.translateService.instant('Report Date'),
      };
      const html = getHtmlElement(
        htmlElement,
        this.translateService.instant(excelFileName),
        newobj,
        printWithEvent,
        translations,
        printOrgNameAndBrand
      );
      let worksheet: XLSX.WorkSheet = null;
      if (isRawString === true) {
        worksheet = XLSX.utils.table_to_sheet(html, { raw: isRawString });
      } else {
        worksheet = XLSX.utils.table_to_sheet(html);
      }

      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, this.translateService.instant('data'));
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } catch (e) {}
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  // tslint:disable-next-line: member-ordering
  addPropertiesToObject(srcObj: any, destObj: any) {
    if (destObj && srcObj) {
      // Add properties
      // tslint:disable-next-line: forin
      for (const key in srcObj) {
        destObj[key] = srcObj[key];
      }
    }
  }
  // tslint:disable-next-line: member-ordering
  exportExelFile(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blobValue, filename + '.xlsx');
  }
  // tslint:disable-next-line: member-ordering
  exportCSVFile(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: 'text/csv',
    });
    FileSaver.saveAs(blobValue, filename + '.csv');
  }

  // tslint:disable-next-line: member-ordering
  downloadResource(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: this.getMimeType(filename),
    });
    FileSaver.saveAs(blobValue, filename);
  }

  private getMimeType(fileName: string) {
    const type = new Map<string, string>();
    type.set('pdf', 'application/pdf');
    type.set('doc', 'application/vnd.ms-word');
    type.set('docx', 'application/vnd.ms-word');
    type.set('xls', 'application/vnd.ms-excel');
    type.set('xlsx', 'application/vnd.openxmlformats officedocument.spreadsheetml.sheet');
    type.set('png', 'image/png');
    type.set('jpg', 'image/jpeg');
    type.set('csv', 'text/csv');
    type.set('bmp', 'image/bmp');

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(fileName)[1];
    return type.get(ext);
  }
}
