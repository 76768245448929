import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { identityServerAngularConfig } from '@env/environment';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SsoComponent } from './sso/sso.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { WebStorageStateStore, Log } from 'oidc-client';
import { from } from 'rxjs';

import { OAuthModule } from 'angular-oauth2-oidc';
import { LogoutSsoComponent } from './logout-sso/logout-sso.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { ResetLoginComponent } from './reset-login/reset-login.component';
import { TimeSyncComponent } from './time-sync/time-sync.component';
import { IdleTimeoutComponent } from './idle-timeout/idle-timeout.component';
import { SignoutMessageComponent } from './signout-message/signout-message.component';

export interface State {
  router: RouterReducerState;
}

export const rootStore: ActionReducerMap<State> = {
  router: routerReducer,
};

export function getWebStorageStateStore() {
  return new WebStorageStateStore({ store: window.localStorage });
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    FormsModule,
    OAuthModule.forRoot(),

    StoreModule.forRoot(rootStore),
    EffectsModule.forRoot([]),
  ],
  declarations: [
    LoginComponent,
    SsoComponent,
    LogoutComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LogoutSsoComponent,
    EndSessionComponent,
    ResetLoginComponent,
    TimeSyncComponent,
    IdleTimeoutComponent,
    SignoutMessageComponent,
  ],
})
export class LoginModule {}
