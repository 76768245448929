import { Component, OnInit, HostListener, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { environment } from '@env/environment';
import { Logger, I18nService } from '@app/core';
import { NotificationsService } from './shared/services/notifications.service';
import { Notification } from '@app/core/types/notifications';
import { ToastService } from './shared/services/toast/toast.service';
import { ConnectionService } from 'ng-connection-service';
import { RoutesRecognized, Event } from '@angular/router';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
// import { read } from 'fs';
import { forkJoin } from 'rxjs';
import { identityServerAngularConfig } from '@env/environment';
const log = new Logger('App');
import { DynamicDialogRef, DynamicDialogConfig } from '@primeng';
import { SessionMessageComponent } from './login/session-message/session-message.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthServiceCleaner } from './shared/services/auth.service.cleaner';
import { IdleTimeoutService } from './shared/services/idle-timeout.service';
import { BroadcastChannel } from 'broadcast-channel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
})
export class AppComponent implements OnInit, AfterViewInit {
  // NotificationUpdateInterval = 60000;
  Interval: any;
  isConnected = true;
  ReadNotificationCount = 50;
  permissions: NgxPermissionsObject;
  isPortalTrafficDataPushAllowed: boolean;
  notificationApiRequestinProcess: boolean;

  _localStorageKey = 'idle_timeout_countdown_last_reset_timestamp';
  _isTimerEnabledKey = 'idle_timeout_enabled';
  _idleSecondsTimer: any = null;
  _lastResetTimeStamp: any;
  _localStorage: any = null;
  _clockTimer: any = null;
  _clockTimerKey = 'clock_timer';
  _redirectClockTimerKey: any = null;
  _timerCount = 0;
  _isCurrentActive = false;
  channel: BroadcastChannel<any>;
  CHANNEL_NAME = 'broadcast-channel';

  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private translateService: TranslateService,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private authService: AuthenticationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private authClearService: AuthServiceCleaner,
    public idleTimeoutService: IdleTimeoutService,
    public config: DynamicDialogConfig,
    private notificationService: NotificationsService,
    public toastService: ToastService,
    public ngxPermission: NgxPermissionsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log('APP COMPONENT');

    this.ResetTime();
    log.info('clock timer enabled');
    this._clockTimer = setInterval(() => {
      this.initIdleTimeOut();
    }, 15000);

    this.channel = new BroadcastChannel(this.CHANNEL_NAME);
    this.channel.postMessage('new channel has been created');
    this.channel.onmessage = (msg: any) => {
      log.info('Message Received: ' + JSON.stringify(msg));
      if (msg.isClearInterval == true) {
        this.ClearTimerAndRestart();
      } else if (msg.isIdleTimeout == true) {
        window.clearInterval(this._clockTimer);
        window.clearInterval(this._idleSecondsTimer);
        const oidcToken = JSON.parse(
          localStorage.getItem(
            'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id
          )
        );
        if (document.hidden) {
          //window.clearInterval(this._idleSecondsTimer);
          if (oidcToken === undefined || oidcToken == null) {
            this.router.navigate(['idle-timeout']);
          }
        } else {
          let counter = 0;
          this._redirectClockTimerKey = setInterval(() => {
            if (counter >= 1) {
              if (!(oidcToken === undefined || oidcToken == null)) {
                if (!window.location.pathname.includes('idle-timeout')) {
                  window.clearInterval(this._redirectClockTimerKey);
                  this.router.navigate(['idle-timeout']);
                }
              } else {
                window.clearInterval(this._redirectClockTimerKey);
                this.router.navigate(['idle-timeout']);
              }
            }
            counter++;
          }, 5000);
        }
      } else if (msg.isSigningOut == true) {
        window.clearInterval(this._clockTimer);
        window.clearInterval(this._idleSecondsTimer);
        const oidcToken = JSON.parse(
          localStorage.getItem(
            'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id
          )
        );
        if (document.hidden) {
          //window.clearInterval(this._idleSecondsTimer);
          if (oidcToken === undefined || oidcToken == null) {
            this.router.navigate(['signout-message']);
          }
        } else {
          let counter = 0;
          this._redirectClockTimerKey = setInterval(() => {
            if (counter >= 1) {
              if (!(oidcToken === undefined || oidcToken == null)) {
                if (!window.location.pathname.includes('signout-message')) {
                  window.clearInterval(this._redirectClockTimerKey);
                  this.router.navigate(['signout-message']);
                }
              } else {
                window.clearInterval(this._redirectClockTimerKey);
                this.router.navigate(['signout-message']);
              }
            }
            counter++;
          }, 5000);
        }
      }
    };

    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.toastService.success('Back Online');
      } else {
        this.toastService.error('No internet access');
      }
    });

    if (!this.authService.isAuthenticated()) {
      let isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
      if (isIdleTimeout && isIdleTimeout == true) {
        localStorage.removeItem('isIdleTimeout');
        this.router.navigate(['idle-timeout']);
      } else {
        if (
          !(
            location.pathname.includes('resetpassword') ||
            location.pathname.includes('forgotpassword') ||
            location.pathname.includes('sso') ||
            location.pathname.includes('idle-timeout') ||
            location.pathname.includes('session-message')
          )
        ) {
          this.router.navigate(['login']);
        }
      }
    }
    // this.breadCrumbService.init(router, activatedRoute);

    router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        let childRoute = event.state.root.firstChild;
        while (childRoute != null) {
          if (childRoute.data.pageName != null) {
            let pageName = '';
            let moduleName = '';
            if (childRoute.firstChild?.data?.pageName) {
              pageName = childRoute.firstChild.data.pageName;
              moduleName = childRoute.data.module;
            } else {
              pageName = childRoute.data.pageName;
              moduleName = childRoute.data.module;
            }
            this.notificationService.ActivityRoutingNotification('Visit', pageName, moduleName);
            break;
          }
          childRoute = childRoute.firstChild;
        }
      }
    });
  }

  /*
 @HostListener('window:beforeunload', ['$event'])
 unloadHandler(event: Event) {
     if (localStorage.getItem('loginNotificationId') != null) {
     const dateTime = new Date();
     let sessionData: any;
     if (localStorage.getItem('sessionsTab') == null) {
         sessionData = [];
     } else {
         sessionData = JSON.parse(localStorage.getItem('sessionsTab'));
     }
     const obj: any = {};
     obj.sessionId = this.notificationService.sessionId;
     obj.Date = dateTime;
     sessionData.push(obj);
     localStorage.setItem('sessionsTab', JSON.stringify(sessionData));
     console.log('In record');
 }
 }
 */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    const a = document.querySelector('.p-dropdown-panel.p-component');
    if (a) {
      a.parentNode.removeChild(a);
      const pd: any = document.querySelector('.modal-dropdown');
      if (pd) {
        pd.firstElementChild.click();
      }
    }
  }
  @HostListener('window:click', ['$event'])
  handleClick(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('window:mousemove', ['$event'])
  handleMouseMove(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('window:keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('window:load', ['$event'])
  handleLoad(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('window:scroll', ['$event'])
  handleWheel(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('window:focus', ['$event'])
  handleOnFocus(event: KeyboardEvent) {
    this.ResetTime();
  }
  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: KeyboardEvent) {
    this.ResetTime();
  }

  ngOnInit() {
    const language = I18nService.getCurrentLocale();
    if (language !== undefined && language !== null) {
      this.document.documentElement.lang = language.split('-')[0].trim();
    }
    // doSomething();

    window.addEventListener('beforeunload', (event) => {
      this.channel.postMessage('One channel has closed');
      this.channel.close();
      window.clearInterval(this._idleSecondsTimer);
      window.clearInterval(this._clockTimer);
      window.clearInterval(this._redirectClockTimerKey);

      // Disconnedt SignalR properly
      this.signalRService.disconnect();

      if (window.localStorage.getItem('loginNotificationId') != null) {
        const dateTime = new Date();
        let sessionData: any;
        if (window.localStorage.getItem('sessionsTab') == null) {
          sessionData = [];
        } else {
          sessionData = JSON.parse(window.localStorage.getItem('sessionsTab'));
        }
        const obj: any = {};
        obj.sessionId = this.notificationService.sessionId;
        obj.Date = dateTime;
        sessionData.push(obj);
        window.localStorage.setItem('sessionsTab', JSON.stringify(sessionData));
        log.debug('In record');
      }
    });
    this.routeDecider();
    if (!Array.prototype.forEach) {
      Array.prototype.forEach = function (callback: any /*, thisArg*/) {
        let T, k;

        if (this == null) {
          throw new TypeError('this is null or not defined');
        }

        // 1. Let O be the result of calling toObject() passing the
        // |this| value as the argument.
        const O = Object(this);

        // 2. Let lenValue be the result of calling the Get() internal
        // method of O with the argument "length".
        // 3. Let len be toUint32(lenValue).
        const len = O.length;

        // 4. If isCallable(callback) is false, throw a TypeError exception.
        // See: http://es5.github.com/#x9.11
        if (typeof callback !== 'function') {
          throw new TypeError(callback + ' is not a function');
        }

        // 5. If thisArg was supplied, let T be thisArg; else let
        // T be undefined.
        if (arguments.length > 1) {
          T = arguments[1];
        }

        // 6. Let k be 0.
        k = 0;

        // 7. Repeat while k < len.
        while (k < len) {
          let kValue;

          // a. Let Pk be ToString(k).
          //    This is implicit for LHS operands of the in operator.
          // b. Let kPresent be the result of calling the HasProperty
          //    internal method of O with argument Pk.
          //    This step can be combined with c.
          // c. If kPresent is true, then
          if (k in O) {
            // i. Let kValue be the result of calling the Get internal
            // method of O with argument Pk.
            kValue = O[k];

            // ii. Call the Call internal method of callback with T as
            // the this value and argument list containing kValue, k, and O.
            callback.call(T, kValue, k, O);
          }
          // d. Increase k by 1.
          k++;
        }
        // 8. return undefined.
      };
    }
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    const currentApplicationVersion = environment.appVersion;
    log.error('Current Version : ' + currentApplicationVersion);
    setTimeout(() => {
      if (this.authService.isAuthenticated()) {
        if (environment.signalRConfigured && localStorage.getItem('IsApiNotification') !== 'true') {
          this.signalRService.init();
          this.realTimeNotificationService.init();
          this.realTimeNotificationService.subscribeListeners();
          this.OnNotificationSync();
        }
        log.info('setInterval >> this.SetNoficationTimeout()');
        setInterval(() => {
          log.info('setInterval >> this.SetNoficationTimeout() called');
          this.SetNoficationTimeout();
          if (localStorage.getItem('IsApiNotification') === 'true' || environment.signalRConfigured === false) {
            if (localStorage.getItem('lastSyncDateNotify') == null) {
              const date: any = new Date();
              localStorage.setItem('lastSyncDateNotify', date);
            }
            log.debug(' Api notification is ready');
            this.NotificationSend();
          }
        }, environment.NotificationSetting.notificationUpdateInterval);

        setTimeout(() => {
          log.info('setTimeout >> this.SetNoficationTimeout() 5000ms called');
          this.SetNoficationTimeout();
        }, 5000);
      }
    }, 5000);

    //   // Change page title on navigation or language change, based on route data
    //   merge(this.translateService.onLangChange, onNavigationEnd)
    //     .pipe(
    //       map(() => {
    //         let route = this.activatedRoute;
    //         while (route.firstChild) {
    //           route = route.firstChild;
    //         }
    //         return route;
    //       }),
    //       filter(route => route.outlet === 'primary'),
    //       mergeMap(route => route.data)
    //     )
    //     .subscribe(event => {
    //       const title = event['title'];
    //       if (title) {
    //         this.titleService.setTitle(this.translateService.instant(title));
    //       }
    //     });
  }

  ngAfterViewInit() {
    const script = document.createElement('script');
    let language = localStorage.getItem('language');
    if (language === undefined || language == null) {
      language = 'en';
    }
    const googleMapUrl =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCQzBPD81VPXP_Wg79w_ubA4XiuU4_XPaU&language=' + language;
    script.setAttribute('src', googleMapUrl);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
  initIdleTimeOut() {
    log.info('clock timer checked');
    this._idleSecondsTimer = null;
    this._lastResetTimeStamp = null;
    this._localStorage = null;
    this._lastResetTimeStamp = new Date().getTime();
    if (this.authService.isAuthenticated() && this.idleTimeoutService.getIdletimeout() > 0) {
      let isTimerExist = JSON.parse(localStorage.getItem(this._isTimerEnabledKey));
      if (!isTimerExist) {
        localStorage.setItem(this._isTimerEnabledKey, JSON.stringify(true));
        this._localStorage = window.localStorage;
        window.clearInterval(this._idleSecondsTimer);
        log.info('isTimerExist is false and set idle timeout ');
        this.ResetTime();
        this._idleSecondsTimer = setInterval(() => {
          this.CheckIdleTime();
        }, 2000);
        log.info('isTimerExist is false');
        this.channel.postMessage({ isClearInterval: true });
      } else {
        this._localStorage = window.localStorage;
        this.CheckTimerEnabled();
      }
    }
  }
  CheckTimerEnabled() {
    this._timerCount++;
    if (this._timerCount >= 2) {
      var currentStoredTimerValue = parseInt(this._localStorage[this._clockTimerKey], 10);
      if (isNaN(currentStoredTimerValue) || currentStoredTimerValue < 0) {
        localStorage.setItem(this._clockTimerKey, JSON.stringify(new Date().getTime()));
      }
      let newTimerValue = new Date().getTime();

      var secondsDiff = Math.floor((newTimerValue - currentStoredTimerValue) / 1000);
      if (secondsDiff > 45) {
        log.info('secondsDiff > 45');
        //this.channel.postMessage({ isClearInterval: true });

        var currentTimeStamp = new Date().getTime();
        localStorage.setItem(this._clockTimerKey, JSON.stringify(currentTimeStamp));

        //this.ResetTime();
        window.clearInterval(this._idleSecondsTimer);
        this._idleSecondsTimer = setInterval(() => {
          this.CheckIdleTime();
        }, 2000);
        log.info('Restarted timer');
      }
    }
  }
  GetLastResetTimeStamp(): number {
    var lastResetTimeStamp = 0;
    if (this._localStorage) {
      lastResetTimeStamp = parseInt(this._localStorage[this._localStorageKey], 10);
      if (isNaN(lastResetTimeStamp) || lastResetTimeStamp < 0) lastResetTimeStamp = new Date().getTime();
    } else {
      lastResetTimeStamp = this._lastResetTimeStamp;
    }

    return lastResetTimeStamp;
  }

  SetLastResetTimeStamp(timeStamp: any) {
    if (this._localStorage) {
      this._localStorage[this._localStorageKey] = timeStamp;
    } else {
      this._lastResetTimeStamp = timeStamp;
    }
  }

  ResetTime() {
    log.info('ResetTime called');
    this.SetLastResetTimeStamp(new Date().getTime());
  }

  WriteProgress(msg: string) {
    if (console) log.info(msg);
  }

  ClearTimerAndRestart() {
    log.info('ClearTimerAndRestart');
    window.clearInterval(this._idleSecondsTimer);
    window.clearInterval(this._clockTimer);
    this._clockTimer = setInterval(() => {
      this.initIdleTimeOut();
    }, 15000);
  }

  CheckIdleTime() {
    if (this.authService.isUserLoggedIn()) {
      var currentTimeStamp = new Date().getTime();
      localStorage.setItem(this._clockTimerKey, JSON.stringify(currentTimeStamp));

      var lastResetTimeStamp = this.GetLastResetTimeStamp();
      var secondsDiff = Math.floor((currentTimeStamp - lastResetTimeStamp) / 1000);
      if (secondsDiff <= 0) {
        log.info('secondsDiff <= 0');
        //this.ResetTime();
        secondsDiff = 0;
      }
      var msg = this.idleTimeoutService.getIdletimeout() - secondsDiff + '';
      if (console) log.info(msg);

      if (secondsDiff >= this.idleTimeoutService.getIdletimeout()) {
        window.clearInterval(this._idleSecondsTimer);
        this.ResetTime();
        this.showIdletimoutMessage();
      }
    }
  }

  showIdletimoutMessage() {
    log.info('showing Idletimout Message');
    this.channel.postMessage({ isClearInterval: true });
    if (this.authService.isAuthenticated()) {
      localStorage.setItem('isIdleTimeout', JSON.stringify(true));
      localStorage.removeItem(this._localStorageKey);
      localStorage.removeItem(this._clockTimerKey);
      this.authClearService.SetLogoutEvent();
    }
  }

  showEndSessionDialog() {
    this.dialogService.open(SessionMessageComponent, {
      header: 'Session Info',
      width: '50%',
      closeOnEscape: false,
      closable: false,
      showHeader: false,
    });
  }

  routeDecider() {
    let authData = this.authService.authData;
    if (authData && authData.Features) {
      const features = authData.Features;
      const isAdmin = location.pathname.toLocaleLowerCase().search(/admin/g);
      const isReporting = location.pathname.toLocaleLowerCase().search(/reporting/g);
      if (isReporting === -1 && isAdmin === -1) {
        const Admin = features.find((x: any) => x.Name === 'Admin');
        const Reporting = features.find((x: any) => x.Name === 'Reporting');
        if (Reporting) {
          this.router.navigate(['/reporting']);
        } else if (Admin) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/unauthorized']);
        }
      }

      // if (Reporting && isReporting === -1 && !Admin) {
      //     this.router.navigate(['/reporting']);
      // } else if (Admin && isAdmin === -1 && !Reporting) {
      //     this.router.navigate(['/admin']);
      // } else if (!Admin && !Reporting) {
      //     this.router.navigate(['/unauthorized']);
      // }
    }
  }

  // Sending notification from local storage
  NotificationSend() {
    this.permissions = this.ngxPermission.getPermissions();
    this.isPortalTrafficDataPushAllowed = this.permissions[Identifiers.PortalTrafficDataPush] ? true : false;
    if (this.isPortalTrafficDataPushAllowed) {
      if (!this.notificationApiRequestinProcess) {
        this.notificationService.getAllRecoredsAsync().subscribe((record) => {
          const tabSessionId = this.notificationService.sessionId;
          const appNotifyId: any = localStorage.getItem('appNotifyId');
          const notifications = record.filter((rec: any) => {
            return (
              (rec.monitoringType === this.notificationService.routingType &&
                rec.isRead !== true &&
                rec.tabSessionId === tabSessionId) || // Inserted record
              (rec.isRead === true &&
                rec.monitoringType === this.notificationService.routingType &&
                rec.appNotifyId === appNotifyId &&
                rec.tabSessionId === tabSessionId &&
                rec.duration === null &&
                rec.dateTimeExited !== null) || // exit time updated record
              (rec.monitoringType !== this.notificationService.routingType &&
                rec.tabSessionId === tabSessionId &&
                rec.isRead !== true) || // non routing event
              rec.isTimeOut === true
            ); // timeout record
          });

          if (notifications.length > 0) {
            for (let i = 0; i < notifications.length; i++) {
              if (this.notificationService.IsRoutingUpdateRecord(notifications[i])) {
                notifications[i] = this.notificationService.SetUpdateNotificationValues(notifications[i]);
              }
            }

            if (environment.signalRConfigured === false || localStorage.getItem('IsApiNotification') === 'true') {
              this.notificationApiRequestinProcess = true;
              this.realTimeNotificationService.SendApiNotifications(notifications).subscribe(
                (res) => {
                  // console.log('Adding Notification', res);
                  this.notificationApiRequestinProcess = false;
                  this.AddNewNotifications(res);
                },
                (error) => {
                  this.notificationApiRequestinProcess = false;
                }
              );
            } else {
              // if (this.signalRService.ConnectionCurrentState === 'connected') {
              //     this.signalRService.syncNotificationData(notifications);
              // }
              if (this.signalRService.isConnected()) {
                this.signalRService.syncNotificationData(notifications);
              }
            }
          }
        });
      }
    }
  }

  OnNotificationSync() {
    // Getting response from Signal R by sending notification from NotificationSend() method
    this.signalRService.NotificationResponse.subscribe((notifications) => {
      this.UpdateNotification(notifications);
    });

    // On receiving General Notifications
    this.signalRService.GeneralNotificationResponse.subscribe((notification) => {
      if (notification !== '') {
        this.toastService.customInfoToast(notification);
        this.notificationService.addAsync(notification).subscribe(() => {
          this.signalRService.notificationReload.next(true);
        });
      }
    });

    this.signalRService.SingleRStateResponse.subscribe((status) => {
      if (status) {
        this.Interval = setInterval(() => {
          this.NotificationSend();
        }, environment.NotificationSetting.notificationUpdateInterval);
        log.debug('Initalized Signal');
      } else {
        clearInterval(this.Interval);
      }
    });
  }

  UpdateNotification(notifications: any[]) {
    // If any Notification Got from Server then run this
    if (notifications) {
      // Clearing local DB storage
      // if (!this.signalRService.IsFirstNotificationSync) {
      //   this.notificationService.clearAsync().subscribe(e => {
      //    this.AddNewNotifications(notifications);
      //  }, (error) => {});
      // } else {
      this.AddNewNotifications(notifications);
      // this.signalRService.IsFirstNotificationSync = false;
      // }
    }
  }

  SetNoficationTimeout() {
    if (localStorage.getItem('sessionsTab') != null) {
      const sessionData: any = JSON.parse(localStorage.getItem('sessionsTab'));
      // const appNotifyId = localStorage.getItem('appNotifyId');
      this.notificationService.getAllRecoredsAsync().subscribe((res) => {
        log.info('SetNoficationTimeout() >> sessions length >> {0}', sessionData.length);
        log.info('SetNoficationTimeout() >> res length >> {0}', res.length);

        for (let j = 0; j < sessionData.length; j++) {
          const sessionId = sessionData[j].sessionId;
          const date = sessionData[j].Date;
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].dateTimeAccessed != null &&
              res[i].monitoringType === this.notificationService.routingType &&
              res[i].tabSessionId === sessionId &&
              res[i].dateTimeExited == null
            ) {
              res[i].dateTimeExited = new Date(date);
              res[i].isTimeOut = true;
              this.notificationService.setItem(res[i]);
            } else if (
              res[i].dateTimeAccessed != null &&
              res[i].monitoringType === this.notificationService.routingType &&
              res[i].tabSessionId === sessionId &&
              res[i].dateTimeExited != null &&
              res[i].duration == null
            ) {
              res[i].isTimeOut = true;
              this.notificationService.setItem(res[i]);
            }
          }
        }
      });
    }
  }

  AddNewNotifications(notifications: any[]) {
    // Adding Notifications to local Db storage
    if (!notifications) {
      notifications = [];
    }
    const promises: Promise<Notification>[] = [];
    notifications.forEach((e) => {
      if (e.monitoringType !== this.notificationService.syncDateType) {
        const promise = this.notificationService.addExist(e);
        promises.push(promise);
      } else {
        log.debug('lastSyncDateNotify >> ', e.date);
        localStorage.setItem('lastSyncDateNotify', e.date);
      }
    });

    this.notificationService.getAllRecoredsAsync().subscribe((res) => {
      const count = res.reduce((acc, cur) => (cur.isRead === true ? ++acc : acc), 0);
      const readRecords = res;
      if (count > this.ReadNotificationCount) {
        let breakCounter = 0;
        const stopCounter: number = count - this.ReadNotificationCount;
        for (let i = 0; i < this.ReadNotificationCount; i++) {
          if (breakCounter === stopCounter) {
            break;
          }
          if (readRecords[i].isRead === true) {
            this.notificationService.removeAsync(readRecords[i].id);
            breakCounter++;
          }
        }
      }
      // set unread records isread equal to true to avoid them in sending next call
      for (let i = 0; i < res.length; i++) {
        // tslint:disable-next-line:max-line-length
        if (
          readRecords &&
          readRecords.length &&
          readRecords[i].isRead === false &&
          readRecords[i].monitoringType !== this.notificationService.routingType &&
          readRecords[i].page !== 'Login'
        ) {
          readRecords[i].isRead = true;
          this.notificationService.setItem(readRecords[i]);
        }
      }
    });

    // Promise.all(promises).then(d => {
    //   // Refreshing Notification modal to update notifications from Local storage
    //   this.signalRService.notificationReload.next(true);
    // });

    forkJoin(promises).subscribe((values) => {
      // Refreshing Notification modal to update notifications from Local storage
      this.signalRService.notificationReload.next(true);
      log.debug('AddNewNotifications Result:', values);
    });
  }
  onReload() {
    window.location.reload();
  }
}
