import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';

import { UserManager } from 'oidc-client';

import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { environment, identityServerSSOConfig, identityServerTCIConfig } from '@env/environment';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';

import { OAuthService } from 'angular-oauth2-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { MyStartupService } from '@app/app.service';
import { LoaderService } from './../../core/loader.service';
import { Guid } from 'guid-typescript';
import { NotificationsService } from '@app/shared/services/notifications.service';
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  token: string;

  public loggedIn: boolean;
  public identity: any;

  error: string = undefined;

  permissions: NgxPermissionsObject;
  constructor(
    private routeParam: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private ngxpermissions: NgxPermissionsService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
    private MystartupService: MyStartupService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.routeParam.queryParams.subscribe((params) => {
      this.token = params['ssoParam'];
      let clientName = params['c'];
      if (clientName != null && clientName != '') {
        clientName = clientName.replace(/^"(.*)"$/, '$1');
      }

      if (this.token) {
        localStorage.clear();
        localStorage.setItem('isSSOLogin', JSON.stringify(true));
        localStorage.setItem('ssoToken', JSON.stringify(this.token));

        localStorage.setItem('ssoClient', JSON.stringify('TCISSO'));
        this.getValuesFromToken();
      } else if (clientName && clientName != '') {
        this.authService.getSSOConfiguration(clientName).subscribe((response: any) => {
          if (response) {
            localStorage.clear();
            localStorage.setItem('isSSOLogin', JSON.stringify(true));
            localStorage.setItem('ssoClient', JSON.stringify(clientName));
            this.initSSO(response.Data);
          } else {
            localStorage.setItem('isSSOLogin', null);
            localStorage.setItem('ssoClient', null);

            this.router.navigate(['/logoutsso']);
          }
        });
      } else {
        const client = JSON.parse(localStorage.getItem('ssoClient'));
        if (client != 'TCISSO') {
          const client = JSON.parse(localStorage.getItem('ssoClient'));
          if (client != null && client != '') {
            this.initSSO(client);
          } else {
            this.router.navigate(['/page-not-found']);
          }
        } else if (client === 'TCISSO') {
          this.token = JSON.parse(localStorage.getItem('ssoToken'));
          localStorage.setItem('isSSOLogin', JSON.stringify(true));

          this.getValuesFromToken();
        } else {
          localStorage.setItem('isSSOLogin', null);
          localStorage.setItem('ssoClient', null);

          this.router.navigate(['/logoutsso']);
        }
      }
    });
  }

  getValuesFromToken() {
    let code = decodeURI(this.token);
    code = code.replace(/\s+/g, '+');
    this.authService.getTokenParams({ Token: code }).subscribe((res) => {
      res.ClientId = '5af3178c141292374cbf363e';
      res.MarketId = '5afe6d7559845533484957b8';
      res.RoleId = '5d4bd3f91afb984f00d9a0f2';
      // check if sso user exist in baseline project
      this.authService.ssoLogin(res).subscribe((response) => {
        if (response) {
          // User already exist
          this.initTCISSO(res.Email);
        } else {
          this.router.navigate(['/unauthorized']);
        }
      });
    });
  }

  initTCISSO(username: string) {
    this.oauthService.tokenEndpoint = identityServerTCIConfig.authority + '/connect/token';
    this.oauthService.clientId = identityServerTCIConfig.client_id;
    this.oauthService.scope = identityServerTCIConfig.scope;
    this.oauthService.dummyClientSecret = identityServerTCIConfig.secret;

    this.oauthService.fetchTokenUsingPasswordFlow(username, 'password').then((resp: any) => {
      if (resp) {
        const res = {
          access_token: resp.access_token,
          expiry: 864000000000,
        };

        this.authService.saveSsoAuthToken(res);
        this.saveAuthData(res);
      }
    });
  }

  initSSO(clientId: string) {
    this.authService.initSSO(clientId);
    const mgr = this.authService.getUserManager();
    mgr.getUser().then((value) => {
      if (value) {
        this.authService.checkTokenExpiry(value.expires_at);
        this.authService.isLoggedIn = true;
        this.loggedIn = true;
        this.identity = value;
        this.initUser();
      } else {
        this.loggedIn = false;
        this.identity = '';
        this.authService.isLoggedIn = false;
        localStorage.setItem('isSSOLogin', JSON.stringify(true));
        localStorage.setItem('ssoClient', JSON.stringify(clientId));
        mgr.signinRedirect();
      }
    });
  }

  initUser() {
    const res = {
      access_token: this.identity.access_token,
      expiry: this.identity.expires_at,
    };

    this.authService.saveSsoAuthToken(res);

    this.saveAuthData(res);

    // check if sso user exist in baseline project
    this.authService.isUserExist(this.identity.profile.sub).subscribe((response) => {
      if (response) {
        // User already exist
        this.saveAuthData(res);
      } else {
        // Create SSO user in baseline DB

        const user = {
          Id: this.identity.profile.sub,
          ClientId: this.identity.profile.ClientId,
          DefaultLanguage: this.identity.profile.DefaultLanguage,
          Email: this.identity.profile.Email,
          FirstName: this.identity.profile.FirstName,
          IsFromSSO: true,
          LastName: this.identity.profile.LastName,
          MarketId: this.identity.profile.MarketId,
          RoleId: this.identity.profile.RoleId,
        };

        this.authService.createUser(user).subscribe((value) => {
          if (value != null) {
            this.saveAuthData(res);
          }
        });
      }
    });
  }

  saveAuthData(res: any) {
    this.authService.storeAuthData(res).subscribe(
      (response) => {
        const data = this.authService.onSignIn(response);

        if (environment.signalRConfigured) {
          this.signalRService.init();
          this.realTimeNotificationService.init();
          this.realTimeNotificationService.subscribeListeners();
        }
        this.authService.getLanguage().subscribe((langResponse) => {
          this.MystartupService.data = langResponse;
          // localStorage.setItem('translations', JSON.stringify(langResponse));
          localStorage.setItem('translations', JSON['makeStringify'](langResponse));
          this.permissions = this.ngxpermissions.getPermissions();
          const Admin = this.permissions['Admin'] ? true : false;
          const Reporting = this.permissions['Reporting'] ? true : false;

          this.loaderService.showMain = false;
          if (localStorage.getItem('loginNotificationId') == null) {
            const id: string = Guid.create().toString();
            localStorage.setItem('appNotifyId', id);
            const notifyData = { message: 'Login Successfull', pageName: 'Login', module: 'Login', appNotifyId: id };
            this.notificationsService.ActivityNonRequestNotification(notifyData, true);
          }
          if (Reporting) {
            this.router.navigate(['/reporting']);
          } else if (Admin) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['/unauthorized']);
          }
        });
        // this.MystartupService.load().then(() => {
        //   this.permissions = this.ngxpermissions.getPermissions();
        //   (this.permissions['Reporting'] ? true : false) ?
        //     this.router.navigate(['/reporting']) :
        //     this.router.navigate(['/admin']);
        // }).catch(() => {
        //   this.permissions = this.ngxpermissions.getPermissions();
        //   (this.permissions['Reporting'] ? true : false) ?
        //     this.router.navigate(['/reporting']) :
        //     this.router.navigate(['/admin']);
        // });
      },
      (reason) => {
        //  HideLoader();
        switch (reason.status) {
          case -1: {
            this.error = 'Some error occurred. Please check your internet connection or try again';
            this.loaderService.showMain = false;

            break;
          }
          case 400: {
            this.error = reason.Message;
            this.loaderService.showMain = false;

            break;
          }
          case 500: {
            this.error = reason.error.ExceptionMessage;
            this.loaderService.showMain = false;

            break;
          }
        }
      }
    );
  }
}
