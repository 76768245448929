import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ForgetPasswordDTO } from '@app/core/types/user';
import { RestMessage } from '@app/core/types';
import { ToastService } from '@app/shared/services/toast/toast.service';
// import { file } from 'babel-types';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  user: any = {};
  email: string;
  error: string;
  submitted: boolean;
  forgetPassword: ForgetPasswordDTO;
  response: any;

  isSubmit: boolean = false;
  isClearViewEnv: boolean = true;
  isReflecxEnv: boolean = false;
  isMBCEnv: boolean = false;
  imagePath: any = undefined;
  tenant: any = undefined;
  clientId: any = null;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private cdf: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.isSubmit = false;
    const path = this.route.snapshot.queryParamMap.get('imagepath');
    const tenant = this.route.snapshot.queryParamMap.get('tenant');
    if (path) {
      this.imagePath = path;
      if (this.imagePath) {
        this.imagePath = this.imagePath + '?' + this.getVersionNumber();
      }
      this.cdf.detectChanges();
    }
    if (tenant) {
      this.tenant = tenant;
      this.authService.getClientByName(this.tenant).subscribe((response: any) => {
        this.clientId = response.Id;
      });
    }

    this.checkEnvironment();
  }

  getVersionNumber() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const hours = d.getHours();
    const mins = d.getMinutes();
    const sec = d.getSeconds();
    const randomNumber = Math.floor(Math.random() * 100000 + 1);

    const version = year + '' + month + '' + date + '' + hours + '' + mins + '' + sec + '' + randomNumber;
    return version;
  }

  checkEnvironment() {
    this.isReflecxEnv = false;
    this.isMBCEnv = false;
    this.isClearViewEnv = true;

    var baseUrl = window.location.origin;
    if (baseUrl.includes('reflecx')) {
      this.isReflecxEnv = true;
      this.isClearViewEnv = false;
    } else if (baseUrl.includes('mbcvoices')) {
      this.isMBCEnv = true;
      this.isClearViewEnv = false;
    } else {
      this.isClearViewEnv = true;
    }
  }

  forgotPassword(email: any) {
    var emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

    if (email === undefined || email === '') {
      this.error = "Sorry, we don't recognize this email.";
      this.isSubmit = false;
      return;
    } else if (email !== '' && !emailPattern.test(email)) {
      this.error = 'Invalid email. Please try again.';
      this.isSubmit = false;
      return;
    } else {
      this.error = '';
      this.isSubmit = true;
    }

    this.forgetPassword = { Email: email, ClientId: this.clientId };

    this.authService.forgotPassword(this.forgetPassword).subscribe((response: RestMessage) => {
      this.toastService.success('Please check your email for reset password link.');
      this.isSubmit = false;
      window.location.href = '/';
    });
  }
}
