import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridHeaderTopActionDirective } from './grid-header-top-action.directive';
import { GridHeaderTopDropdownDirective } from './grid-header-top-search-dropdown.directive';

@Component({
  selector: 'ngx-search-action',
  templateUrl: './search-action.component.html',
  styleUrls: ['./search-action.component.css'],
})
export class SearchActionComponent implements OnInit {
  @Input() topBar: any;

  @Input() ActionTemplate: GridHeaderTopActionDirective;
  @Input() GridHeaderTopDropDownTemplate: GridHeaderTopDropdownDirective;
  @Input() searchtext: string;

  //searchText: String = '';
  searchText2: String = '';
  InvalidCharacters: Array<string> = ["'", '+', '|', '^'];
  @Output() onSearchTextChange = new EventEmitter<String>();
  @Output() onSearchDropDownChange = new EventEmitter();

  constructor() {
    //this.searchText = this.searchtext;
  }

  ngOnInit() {
    //this.searchText = this.searchtext;
    if (this.searchtext !== '') {
      this.onChange();
    }
  }

  onChange() {
    if ((this.searchtext && this.CheckInvalidCharacters(this.searchtext)) || this.searchtext === '') {
      this.searchText2 = this.searchtext;
      this.onSearchTextChange.emit(this.searchText2);
    } else {
      this.searchtext = this.searchText2.toString();
    }
  }

  CheckInvalidCharacters(value: String): boolean {
    for (let counter = 0; counter < this.InvalidCharacters.length; counter++) {
      if (value.includes(this.InvalidCharacters[counter])) {
        return false;
      }
    }
    return true;
  }

  selectRow(column: any) {
    this.onSearchDropDownChange.emit(column);
  }
}
