import {
  Component,
  NgModule,
  Type,
  ComponentFactoryResolver,
  ViewChild,
  OnDestroy,
  ComponentRef,
  AfterViewInit,
  ChangeDetectorRef,
  Renderer2,
  NgZone,
  DoCheck,
  ElementRef,
  Injector,
  HostListener,
} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { DynamicDialogContent } from './dynamicdialogcontent';
import { DynamicDialogConfig } from './dynamicdialog-config';
import { CommonModule } from '@angular/common';
import { DomHandler } from '../dom/domhandler';
import { DynamicDialogRef } from './dynamicdialog-ref';
import { DynamicDialogFooter } from './dynamicdialogfooter';
import { DynamicDialogHeeader } from './dynamicdialogheader';
import { DialogService } from '../common';

@Component({
  selector: 'p-dynamicDialog',
  template: `
    <div
      #mask
      class="ui-widget-overlay ui-dialog-mask ui-dialog-mask-scrollblocker"
      *ngIf="visible"
      (click)="onMaskClick()"
    ></div>
    <div
      #container
      [ngClass]="{
        'ui-dialog ui-dynamicdialog ui-widget ui-widget-content ui-corner-all ui-shadow': true,
        'ui-dialog-rtl': config.rtl
      }"
      [ngStyle]="config.style"
      [class]="config.styleClass"
      [@animation]="{
        value: 'visible',
        params: { transitionParams: config.transitionOptions || '400ms cubic-bezier(0.25, 0.8, 0.25, 1)' }
      }"
      (@animation.start)="onAnimationStart($event)"
      (@animation.done)="onAnimationEnd($event)"
      role="dialog"
      *ngIf="visible"
      [style.width]="config.width"
      [style.height]="config.height"
    >
      <div
        class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top"
        *ngIf="config.showHeader === false ? false : true"
      >
        <ng-template pDynamicDialogHeader></ng-template>
        <a
          [ngClass]="'ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all'"
          tabindex="0"
          role="button"
          (click)="close()"
          (keydown.enter)="close()"
          *ngIf="config.closable === false ? false : true"
        >
          <span class="pi pi-times"></span>
        </a>
      </div>
      <div class="ui-dialog-content ui-widget-content" [ngStyle]="config.contentStyle">
        <ng-template pDynamicDialogContent></ng-template>
      </div>
      <div class="ui-dialog-footer ui-widget-content" #footer>
        <ng-template pDynamicDialogFooter></ng-template>
      </div>
    </div>
  `,
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'translate3d(-50%, -25%, 0) scale(0.9)',
          opacity: 0,
        })
      ),
      state('visible', style({})),
      transition('* => *', animate('{{transitionParams}}')),
    ]),
  ],
  styles: [
    `
      .ui-dynamicdialog {
        transform: translateX(-50%) translateY(calc(-50%));
        -ms-transform: translateX(-50%) translateY(-50%);
        opacity: 1;
      }
    `,
  ],
})
export class DynamicDialogComponent implements AfterViewInit, OnDestroy, DoCheck {
  visible = true;
  viewInit = false;
  componentRef: ComponentRef<any>;

  mask: HTMLDivElement;

  @ViewChild(DynamicDialogContent) insertionPoint: DynamicDialogContent;
  @ViewChild(DynamicDialogFooter) footer: DynamicDialogFooter;
  @ViewChild(DynamicDialogHeeader) header: DynamicDialogHeeader;

  @ViewChild('mask') maskViewChild: ElementRef;

  @ViewChild('container') divToTrackHeightChanges: ElementRef;
  @ViewChild('footer') footerContainerRef: ElementRef;

  childComponentType: Type<any>;

  container: HTMLDivElement;

  documentEscapeListener: Function;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public renderer: Renderer2,
    public injector: Injector,
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public zone: NgZone
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.doDivHeightChange(this.getHeightAndWidthObject());
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
    this.viewInit = true;
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    if (this.viewInit) {
      // this.doDivHeightChange(this.getHeightAndWidthObject());
    }
  }

  doDivHeightChange(newValues: any) {
    if (newValues.height % 2 === 0) {
    } else {
      this.footerContainerRef.nativeElement.style.height =
        this.footerContainerRef.nativeElement.offsetHeight + 1 + 'px';
    }
  }

  getHeightAndWidthObject() {
    const newValues: any = {};
    newValues.height = this.divToTrackHeightChanges.nativeElement.offsetHeight;
    newValues.width = this.divToTrackHeightChanges.nativeElement.offsetWidth;
    return newValues;
  }

  onOverlayClicked(evt: MouseEvent) {
    this.dialogRef.close();
  }

  onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    this.componentRef = componentFactory.create(this.injector);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    const footerContainerRef = this.footer.viewContainerRef;
    const headerContainerRef = this.header.viewContainerRef;
    viewContainerRef.clear();
    footerContainerRef.clear();
    headerContainerRef.clear();
    viewContainerRef.createEmbeddedView(this.componentRef.instance.contentRef);

    footerContainerRef.createEmbeddedView(this.componentRef.instance.footerRef);

    headerContainerRef.createEmbeddedView(this.componentRef.instance.headerRef);

    setTimeout(() => {
      this.doDivHeightChange(this.getHeightAndWidthObject());
    });

    // this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  moveOnTop() {
    if (this.config.autoZIndex !== false) {
      const zIndex = this.config.baseZIndex || 0 + ++DomHandler.zindex;
      this.container.style.zIndex = String(zIndex);
      this.maskViewChild.nativeElement.style.zIndex = String(zIndex - 1);
    }
  }

  onAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.moveOnTop();
        this.bindGlobalListeners();
        DomHandler.addClass(document.body, 'ui-overflow-hidden');
        break;

      case 'void':
        this.onContainerDestroy();
        break;
    }
  }

  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.dialogRef.close();
    }
  }

  onContainerDestroy() {
    DomHandler.removeClass(document.body, 'ui-overflow-hidden');
    this.unbindGlobalListeners();
    this.container = null;
  }

  close() {
    this.visible = false;
  }

  onMaskClick() {
    if (this.config.dismissableMask) {
      this.close();
    }
  }

  bindGlobalListeners() {
    if (this.config.closeOnEscape !== false && this.config.closable !== false) {
      this.bindDocumentEscapeListener();
    }
  }

  unbindGlobalListeners() {
    this.unbindDocumentEscapeListener();
  }

  bindDocumentEscapeListener() {
    this.documentEscapeListener = this.renderer.listen('document', 'keydown', (event) => {
      if (event.which == 27) {
        if (parseInt(this.container.style.zIndex) == DomHandler.zindex) {
          this.close();
        }
      }
    });
  }

  unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }

  ngOnDestroy() {
    this.onContainerDestroy();

    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicDialogComponent, DynamicDialogContent, DynamicDialogFooter, DynamicDialogHeeader],
  entryComponents: [DynamicDialogComponent],
  exports: [DynamicDialogComponent, DynamicDialogContent, DynamicDialogFooter, DynamicDialogHeeader],
})
export class DynamicDialogModule {}
