import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-action-icons',
  templateUrl: './action-icons.component.html',
  styleUrls: ['./action-icons.component.css'],
})
export class ActionIconsComponent implements OnInit {
  @Input() view: String;
  @Input() topBar: any;
  @Output() onChangeView = new EventEmitter<String>();
  @Output() onSettingClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onviewchange(value: String) {
    this.onChangeView.emit(value);
  }
}
