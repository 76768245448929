import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeenCA from '@angular/common/locales/en-CA';
import localeenCAExtra from '@angular/common/locales/extra/en-CA';
import localeFrCaExtra from '@angular/common/locales/extra/fr-CA';
import localeFrCA from '@angular/common/locales/fr-CA';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule, I18nService, Logger } from '@app/core';
import { SharedRootModule } from '@app/shared';
import GState, { getInitialState } from '@gstate/index';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MyPrimengModule } from '@primeng';
import { Driver, NgForageConfig } from 'ngforage';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { ReportChartModule } from '../../projects/cvreporting/src/app/features/cvreporting/report-generator/charts/reporting.chart.module';
import { ReportChartComponent } from '../../projects/cvreporting/src/app/features/cvreporting/report-generator/modals/chart/report.chart.component';
import { ReportSettingComponent } from '../../projects/cvreporting/src/app/features/cvreporting/report-generator/modals/setting/report.setting.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyStartupService } from './app.service';
import { RequestCache } from './core/http/request.cache.service';
import { LoginModule } from './login/login.module';
import { SessionMessageComponent } from './login/session-message/session-message.component';

const log = new Logger('App Module');

export function startupServiceFactory(startupService: MyStartupService): Function {
  return () => startupService.load();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot(),
    CoreModule,
    MyPrimengModule,
    SharedRootModule.forRoot(),
    // SharedModule.forRoot(),
    LoginModule,
    ToastModule,
    ConfirmDialogModule,
    ProgressBarModule,
    DynamicDialogModule,
    ReportChartModule,
    StoreModule.forRoot({ ...GState.Reducers }, { initialState: getInitialState }),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [ReportSettingComponent, ReportChartComponent, AppComponent, SessionMessageComponent],
  entryComponents: [ReportSettingComponent, SessionMessageComponent, ReportChartComponent],
  providers: [
    I18nService,
    RequestCache,
    MyStartupService,
    DecimalPipe,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [MyStartupService], multi: true },
    { provide: LOCALE_ID, useValue: I18nService.getCurrentLocale() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(ngfConfig: NgForageConfig) {
    ngfConfig.configure({
      name: 'MyApp',
      driver: [
        // defaults to indexedDB -> webSQL -> localStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE,
      ],
    });

    registerLocaleData(localeenCA, 'en-CA', localeenCAExtra);
    registerLocaleData(localeFrCA, 'fr-CA', localeFrCaExtra);
  }
}
