import { Component, OnInit, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';

@Component({
  template: '',
})
export class ReportingDefaultComponent implements OnInit {
  permissions: NgxPermissionsObject;
  headerlist: any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public ngxPermission: NgxPermissionsService,
    private breadCrumbService: BreadCrumbService,
    private appConfig: AppConfigurationService
  ) {
    this.permissions = this.ngxPermission.getPermissions();
    let route1 = 'expired';
    this.headerlist = this.appConfig.getByIdentifier(Identifiers.HeaderListOrder);
    if (this.headerlist) {
      if (this.headerlist.ClientConfig === null) {
        this.headerlist.ClientConfig = this.headerlist;
      }
      if (this.headerlist.ClientConfig) {
        this.headerlist.ClientConfig.Fields.Dashboard.forEach((header: any) => {
          if (route1 === 'expired') {
            if (header === 'Global Dashboard' && this.permissions[Permission.MENU_GLOBAL_DASHBOARD] ? true : false) {
              route1 = 'reporting/globaldashboard';
            } else if (header === 'Hot Alerts' && this.permissions[Permission.MENU_HOTALERT] ? true : false) {
              route1 = 'reporting/alerts';
            } else if (header === 'Reporting' && this.permissions[Permission.MENU_REPORTS_SECTION] ? true : false) {
              route1 = 'reporting/reporting';
            } else if (
              header === 'Report Generator' && this.permissions[Permission.MENU_REPORT_GENERATOR] ? true : false
            ) {
              route1 = 'reporting/reportgenerator';
            } else if (header === 'Cross Tabs' && this.permissions[Permission.MENU_CROSSTAB] ? true : false) {
              route1 = 'reporting/crosstabs';
            } else if (header === 'Coach' && this.permissions[Permission.REPORT_COACH] ? true : false) {
              route1 = 'reporting/coach';
            } else if (header === 'Appeals' && this.permissions[Permission.MENU_APPEALS] ? true : false) {
              route1 = 'reporting/appeal';
            } else if (
              header === 'VOC' && this.permissions[Permission.REPORT_TEXTANALYTIC_VOCDASHBOARD] ? true : false
            ) {
              route1 = 'reporting/voc';
            } else if (header === 'Social Media' && this.permissions[Permission.MENU_SOCIALMEDIA] ? true : false) {
              route1 = 'reporting/socialmedia';
            } else if (header === 'VOC Demo' && this.permissions[Permission.MENU_VOCDEMO] ? true : false) {
              route1 = 'reporting/vocdemo';
            }
          }
        });
      }
    }
    this.router.navigate([route1]);
  }
  ngOnInit(): void {}
}
