import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  forwardRef,
  NgModule,
  Input,
  Renderer2,
  ViewChild,
  Inject,
  ChangeDetectorRef,
  Attribute,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule, AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularEditorComponent } from '@kolkov/angular-editor';
import { MentionModule } from '../mention/mention.module';
// import { AdvanceEditorToolBarComponent } from './toolbar/toolbar';
// import { EditorSelectComponent } from './editor-select/editor-select';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'advance-editor',
  templateUrl: './editor.html',
  styleUrls: ['./editor.scss'],
})
export class AdvanceEditorComponent extends AngularEditorComponent implements OnChanges {
  // @ViewChild('editorToolbar', {static: false}) editorToolbar: AdvanceEditorToolBarComponent;
  mentionConfig = {
    items: ['FirstName', 'LastName', 'Email'],
    triggerChar: '@',
    maxTextareaLength: 1000,
  };
  hideToolBar: boolean = false;
  @Input() editorConfig: AngularEditorConfig;

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.editorConfig.currentValue);
    if (changes.editorConfig.currentValue) {
      this.config.height = changes.editorConfig.currentValue.height;
      this.config.minHeight = changes.editorConfig.currentValue.minHeight;
      this.config.toolbarHiddenButtons = changes.editorConfig.currentValue.toolbarHiddenButtons;
      this.config.sanitize = changes.editorConfig.currentValue.sanitize;
      this.config.editable = changes.editorConfig.currentValue.editable;
      this.config.spellcheck = changes.editorConfig.currentValue.spellcheck;
      this.config.customClasses = changes.editorConfig.currentValue.customClasses;
      this.mentionConfig.items = changes.editorConfig.currentValue.mention;
      this.hideToolBar = changes.editorConfig.currentValue.hideToolBar;
    }
  }
}

@NgModule({
  imports: [CommonModule, MentionModule, FormsModule, ReactiveFormsModule, AngularEditorModule],
  exports: [AdvanceEditorComponent],
  declarations: [AdvanceEditorComponent],
})
export class AdvanceEditorModule {}
