import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './../app/login/forget-password/forget-password.component';
import { ResetPasswordComponent } from './../app/login/reset-password/reset-password.component';
import { SsoComponent } from './login/sso/sso.component';
import { LogoutComponent } from './login/logout/logout.component';
import { TimeSyncComponent } from './login/time-sync/time-sync.component';
import { ResetLoginComponent } from './login/reset-login/reset-login.component';
import { NoAuthGuard } from './core/authentication/no-auth.guard';
import { AuthenticationGuard } from './core';
import { environment } from '@env/environment';
import { CanActivateAdminModuleGuard } from './core/authentication/admin-module.guard';
import { CanActivateReportingModuleGuard } from './core/authentication/reporting-module.guard';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { AuthenticationService } from '@app/core';
import { Store } from '@ngrx/store';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { NotFound404Component } from './shared/components/404/404.component';
import { OopsComponent } from './shared/components/oops/oops.component';
import { SignoutMessageComponent } from './login/signout-message/signout-message.component';
import { IdleTimeoutComponent } from './login/idle-timeout/idle-timeout.component';

const baseRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: 'login',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: 'login',
    },
  },
  {
    path: 'login/:id',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: 'Login',
    },
  },
  {
    path: 'sso',
    component: SsoComponent,
    data: {
      breadcrumb: 'sso',
    },
  },
  {
    path: 'forgotpassword',
    component: ForgetPasswordComponent,
    data: {
      breadcrumb: 'forgot-password',
    },
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
    data: {
      breadcrumb: 'reset-password',
    },
  },
  {
    path: 'idle-timeout',
    component: IdleTimeoutComponent,
    data: {
      breadcrumb: 'idle-timeout',
    },
  },
  {
    path: 'signout-message',
    component: SignoutMessageComponent,
    data: {
      breadcrumb: 'signout-message',
    },
  },
  { path: 'reset-login', component: ResetLoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'oops', component: OopsComponent },
  { path: 'time-sync', component: TimeSyncComponent },
  { path: 'page-not-found', component: NotFound404Component },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
];
const reportingRoutes: Routes = [
  {
    path: environment.reportingRoutePrefix,
    loadChildren: () =>
      import('../../projects/cvreporting/src/app/features/cvreporting/cvreporting.module').then(
        (r) => r.CVReportingModule
      ), // #CVReportingModule',
    //   canActivate: [NgxPermissionsGuard],
    canActivateChild: [AuthenticationGuard],
    data: {
      breadcrumb: 'Reporting',
      permissions: {
        only: 'Reporting',
        redirectTo: '',
      },
    },
  },
];

const reportingMainRoutes: Routes = [
  ...[
    {
      path: '',
      redirectTo: environment.defaultAppRoute,
      // canActivate: [NgxPermissionsGuard],
      data: {
        breadcrumb: 'Reporting',
        permissions: {
          only: 'Reporting',
          redirectTo: '',
        },
      },
      pathMatch: 'full',
    },
  ],
  ...reportingRoutes,
  ...baseRoutes,
];

const reflecxRoutes: Routes = [
  {
    path: environment.reflecxRoutePrefix,
    loadChildren: () => import('../../projects/reflecx/src/app/features/admin/admin.module').then((r) => r.AdminModule), // #AdminModule',
    //  canActivate: [NgxPermissionsGuard],
    canActivateChild: [AuthenticationGuard],
    data: {
      breadcrumb: 'Admin',
      permissions: {
        only: 'Admin',
        redirectTo: '',
      },
    },
  },
];

const reflecxMainRoutes: Routes = [
  ...[
    {
      path: '',
      redirectTo: environment.defaultAppRoute,
      //   canActivate: [NgxPermissionsGuard],
      data: {
        breadcrumb: 'Admin',
        permissions: {
          only: 'Admin',
          redirectTo: '',
        },
      },
      pathMatch: 'full',
    },
  ],
  ...reflecxRoutes,
  ...baseRoutes,
];

const mainRoutes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultAppRoute,
    // canActivate: [NgxPermissionsGuard],
    data: {
      breadcrumb: 'Reporting',
      permissions: {
        only: 'Reporting',
        redirectTo: '',
      },
    },
    pathMatch: 'full',
  },
  ...reflecxRoutes,
  ...reportingRoutes,
  ...baseRoutes,
];

const routes: Routes =
  environment.Apps.ReflecxApp && environment.Apps.ReportingApp
    ? mainRoutes
    : environment.Apps.ReflecxApp
    ? reflecxMainRoutes
    : environment.Apps.ReportingApp
    ? reportingMainRoutes
    : [];
// const routes: Routes = environment.Apps.ReflecxApp && environment.Apps.ReportingApp ? mainRoutes : environment.Apps.ReflecxApp ? reflecxMainRoutes : environment.Apps.ReportingApp ? reportingMainRoutes : [];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private permissionsService: NgxPermissionsService,
    private authService: AuthenticationService,
    private store: Store<{ EventReducer: any }>
  ) {
    this.store.subscribe((data) => {
      if (this.authService.authData && this.authService.authData.Features) {
        const permissions = this.authService.authData.Features.map((x: any) => x.Name);
        this.permissionsService.loadPermissions(permissions);
      }
    });
  }
}
