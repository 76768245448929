import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import moment from 'moment';
import { Logger } from '@app/core';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { EmailEditsComponent } from 'projects/cvreporting/src/app/features/cvreporting/main-dashboard/email-edits/email-edits.component';
import { TranslateService } from '@ngx-translate/core';
const log = new Logger('GBF_SERVICE');
@Injectable({
  providedIn: 'root',
})
export class GlobalFilterService {
  public gbfTabChange: Subject<any> = new Subject();
  public gbfChange: Subject<any> = new Subject();
  public gbfResetState: Subject<any> = new Subject();
  public gbfApiCall: Subject<any> = new Subject();
  public gbfOnApplyFilter: Subject<any> = new Subject();
  currentMonth: any = moment();
  currentAllowedFilters = ['dealer', 'org', 'model', 'version', 'calendar'];
  program = '';
  tab: {
    label: '';
    value: '';
  };
  dealer = { label: 'All MIB (Corporate & Private)', value: 'All' };
  version = { label: '', value: '', version: '', versionName: '' };
  model = { Brand: 'Mercedes-Benz', Model: '', Year: '' };
  sources = [{ label: 'All', value: 'All' }];
  sentiment: any = { label: 'All Sentiment', value: 'All Sentiment', type: null };
  category = {
    level: 1,
    temp: true,
    parent: 'null',
    name: 'All Categories',
    label: 'All Categories',
    active: true,
    IsChecked: true,
  };
  surveyQuestion: any = [];
  surveyResponse = [
    {
      level: -1,
      parent: 'null',
      name: 'All Survey Responses',
      label: 'All Survey Responses',
      active: true,
      IsChecked: true,
    },
  ];
  surveyScore: any = {
    Id: '',
    Type: 'global_filter_label.Survey Score',
    Score: [0, 100],
    Types: [],
  };
  multi_org: any[] = [];
  org = {
    level: -1,
    parent: 'null',
    Identifier: '',
    NodeId: '',
    name: 'National',
    label: 'National',
    active: true,
    IsChecked: true,
  };
  org_breadcrums: any[] = [];
  language = '';
  calendar = '';
  dateIdentifier = '';
  pageSize = 25;
  pageNo = 1;
  orgIdentifier: string;
  SortColumn = '';
  Sort = '';
  filterItem = '';
  field = '';
  authorizationData: any;
  currentUrl: string;
  isMultiOrg = false;
  activatedUrl = '';
  constructor(
    public gbfConfig: GlobalFilterConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.authorizationData = h;
    this.orgIdentifier = h.OrgIdentifier;
    if (h && h.FilterBarConfig) {
      this.gbfConfig.filterConfig = h.FilterBarConfig.FilterConfig;
    }
    const foundTab = JSON.parse(localStorage.getItem('gbf_tab'));
    if (foundTab) {
      this.tab = foundTab;
    } else {
      if (this.gbfConfig.filterConfig.initialStates['tab']) {
        this.tab = this.gbfConfig.filterConfig.initialStates['tab'];
        if (this.tab) {
          localStorage.setItem('gbf_tab', JSON.stringify(this.tab));
        }
      }
    }
    this.gbfConfig.filterConfig.config.forEach((config: any) => {
      if (config.localStorageKey) {
        try {
          const found = JSON.parse(localStorage.getItem(config.localStorageKey));
          if (found && config.type === 'tree') {
            if (found.selected) {
              this[config.name] = found.selected;
              this[config.name + '_full'] = {
                selected: found.selected,
                selectedTreeItems: found.selectedTreeItems,
              };
            }
            if (found.breadcrums) {
              this[config.name + '_breadcrums'] = found.breadcrums;
            }
          } else {
            if (found) {
              this[config.name] = found;
            } else {
              if (this.gbfConfig.filterConfig.initialStates[config.name]) {
                this.gbfConfig.filterConfig.initialStates[config.name].temp = true;
                this[config.name] = this.gbfConfig.filterConfig.initialStates[config.name];
                this[config.name + '_breadcrums'] = [this.gbfConfig.filterConfig.initialStates[config.name]];
                this[config.name + '_full'] = {
                  selected: this.gbfConfig.filterConfig.initialStates[config.name],
                  selectedTreeItems: this.gbfConfig.filterConfig.initialStates[config.name],
                };
              } else {
                this[config.name + '_full'] = {
                  selected: [],
                  selectedTreeItems: [],
                };
              }
            }
          }
        } catch (error) {
          log.debug('parsing error');
        }
      }
    });
  }
  setDateIdentifier(data: any) {
    this.dateIdentifier = data;
    localStorage.setItem('gbf_date_identifier', data);
  }
  getDateIdentifier() {
    if (this.dateIdentifier) {
      return this.dateIdentifier;
    }
    if (localStorage.getItem('gbf_date_identifier')) {
      const dateIdentifier = localStorage.getItem('gbf_date_identifier');
      if (dateIdentifier) {
        return dateIdentifier;
      }
    }
  }
  setSurveyVersion(version: any) {
    localStorage.setItem('gbf_version', JSON.stringify(version));
    this.version = version;
  }
  getSurveyVersion() {
    if (this.version) {
      return this.version.version;
    } else {
      const survey = JSON.parse(localStorage.getItem('gbf_version'));
      if (survey) {
        return survey.version;
      }
    }
  }
  getCalenderRange() {
    let calendarSettings: any = {
      name: 'calendar',
      type: 'calendar',
      format: 'MMM D, YYYY',
      localStorageKey: 'gbf_calendar',
    };
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
    if (found) {
      calendarSettings = found;
    }
    let date: any = this.calendar;
    if (!date) {
      date = localStorage.getItem(calendarSettings.localStorageKey);
    }
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        const start = moment(date[0]).format(calendarSettings.format);
        const end = moment(date[1]).format(calendarSettings.format);
        return [start, end];
      }
    } else {
      const start = this.getStartDateRangeinNumber(calendarSettings.range, calendarSettings.format);
      const end = this.getEndDateRangeinNumber(calendarSettings.range, calendarSettings.format);
      return [start, end];
    }
  }
  getCalenderDropDown() {
    let calendardropdown: any = [
      {
        label: 'R10',
        key: 'R10',
        value: () => [moment().subtract(10, 'days'), moment()],
      },
      {
        label: 'R30',
        key: 'R30',
        value: () => [moment().subtract(30, 'days'), moment()],
      },
      {
        label: 'MTD',
        key: 'MTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
      },
      {
        label: 'QTD',
        key: 'QTD',
        value: (value: any = this.currentMonth) => [
          moment(value).quarter(moment(value).quarter()).startOf('quarter'),
          moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
        ],
      },
      {
        label: 'YTD',
        key: 'YTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
      },
      {
        label: 'M36',
        key: 'M36',
        value: () => [moment().subtract(-36, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_month.label'),
        key: 'PREVIOUS MONTH',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_quarter.label'),
        key: 'PREVIOUS QUARTER',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_year.label'),
        key: 'PREVIOUS YEAR',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      // tslint:disable-next-line:max-line-length
      {
        label: this.translateService.instant('program_to_date.label'),
        key: 'PROGRAM TO DATE',
        value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
      },
      {
        label: this.translateService.instant('custom_range.label'),
        key: 'CUSTOM RANGE',
        value: (): any => [],
      },
    ];
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
    if (found && found.dateDropDown) {
      let dropdown = found.dateDropDown;
      dropdown.map((obj: any) => {
        calendardropdown.forEach((element: any) => {
          if (obj.key === element.key) {
            obj.label = element.label;
            obj.value = element.value;
          }
        });
      });
      return dropdown;
    }
    return calendardropdown;
  }
  getStartDateRangeinNumber(identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment(this.currentMonth).startOf('year').format(format);
      }
      case 'R10': {
        return moment().subtract(10, 'days').format(format);
      }
      case 'R30': {
        return moment().subtract(30, 'days').format(format);
      }
      case 'MTD': {
        return moment(this.currentMonth).startOf('month').format(format);
      }
      case 'QTD': {
        return moment(this.currentMonth).quarter(moment(this.currentMonth).quarter()).startOf('quarter');
      }
      case 'PREVIOUS MONTH': {
        return moment().subtract(1, 'month').format(format);
      }
      case 'PREVIOUS QUARTER': {
        return moment().subtract(1, 'month').format(format);
      }
      case 'PREVIOUS YEAR': {
        return moment().subtract(1, 'year').format(format);
      }
      case 'PROGRAM TO DATE': {
        return moment(new Date('1-10-2019')).format(format);
      }
      case 'CUSTOM RANGE': {
        return moment(this.currentMonth).startOf('year').format(format);
      }
      case 'M36': {
        return moment(new Date()).add(-36, 'month').format(format);
      }
      default: {
        return moment(new Date()).startOf('month').format(format);
      }
    }
  }
  getEndDateRangeinNumber(identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment().format(format);
      }
      case 'R10': {
        return moment().format(format);
      }
      case 'R30': {
        return moment().format(format);
      }
      case 'MTD': {
        return moment().format(format);
      }
      case 'QTD': {
        return moment().format(format);
      }
      case 'PREVIOUS MONTH': {
        return moment().format(format);
      }
      case 'PREVIOUS QUARTER': {
        return moment().format(format);
      }
      case 'PREVIOUS YEAR': {
        return moment().format(format);
      }
      case 'PROGRAM TO DATE': {
        return moment().format(format);
      }
      case 'CUSTOM RANGE': {
        return moment().format(format);
      }
      default: {
        return moment().format(format);
      }
    }
  }
  getActiveOrgHierarchy() {
    const localOrg: any = localStorage.getItem('gbf_org');
    const rValue = {
      OrgName: this.authorizationData.OrganizationName,
      OrgLevel: this.authorizationData.Level,
      OrgParent: this.authorizationData.ParentOrganization,
    };
    let org: any = this.org;
    if (!org && localOrg) {
      org = JSON.parse(localOrg).selected;
    }
    if (org) {
      rValue.OrgName = org.name;
      rValue.OrgLevel = org.level > 0 ? org.level : 1;
      rValue.OrgParent = org.parent;
    }
    return rValue;
  }
  getSurveyQuestionResponses(name: string, initialState: any) {
    let surveyResponses: any = [];
    if (this['surveyResponse_full'].selected && Array.isArray(this['surveyResponse_full'].selectedTreeItems)) {
      surveyResponses = [...this['surveyResponse_full'].selected, ...this['surveyResponse_full'].selectedTreeItems];
    } else {
      const localStorageSurveyResponses: any = JSON.parse(localStorage.getItem('gbf_survey_response'));
      if (localStorageSurveyResponses) {
        surveyResponses = [...localStorageSurveyResponses.selected, ...localStorageSurveyResponses.selectedTreeItems];
      }
    }
    const parents = surveyResponses.filter((x: any) => x && x.parent === 'null');
    const cache: any = [];
    const reponses: any = [];
    if (parents && parents.length > 0) {
      parents.forEach((val: any) => {
        if (val.mProps && cache.indexOf(val.id) === -1) {
          cache.push(val.id);
          const vResponse: any = {};
          const options = surveyResponses.filter((x: any) => x && x.parent === val.name);
          vResponse.Id = val.id;
          vResponse.QuestionId = val.mProps.QuestionId;
          vResponse.Title = val.mProps.Title;
          vResponse.Type = val.mProps.Type;
          vResponse.Options = options.map((o: any) => {
            return {
              Id: o.Id ? o.Id : o.id,
              Title: o.label,
            };
          });
          if (vResponse.Options.length > 0) {
            reponses.push(vResponse);
          }
        }
      });
    }
    return { SurveyResponses: reponses };
  }
  getlanguageCode(name: string, initialState: any) {
    let languages: any = [];
    if (this.language) {
      languages = this.language;
    } else {
      const localStorageLanguages: any = JSON.parse(localStorage.getItem('gbf_language'));
      if (localStorageLanguages) {
        languages = localStorageLanguages;
      }
    }
    let languageCodefiled: any = [];
    if (languages) {
      languages.map((x: any) => {
        if (x.IsChecked && x.Name !== 'All') {
          languageCodefiled.push(x.LanguageCode);
        }
      });
    } else {
      languageCodefiled = [];
    }
    if (languageCodefiled && languageCodefiled.length > 0) {
      return { LanguageCode: languageCodefiled };
    } else {
      return { LanguageCode: [] };
    }
  }
  getSurveyQuestion(name: string, initialState: any) {
    let surveyQuestion: any = [];
    if (this.surveyQuestion) {
      surveyQuestion = this.surveyQuestion;
    } else {
      const localStorageSurveyQuestion: any = JSON.parse(localStorage.getItem('gbf_survey_question'));
      if (localStorageSurveyQuestion) {
        surveyQuestion = localStorageSurveyQuestion;
      }
    }
    let isselectAll = [];
    if (surveyQuestion) {
      isselectAll = surveyQuestion.filter((x: any) => x.IsChecked === true);
    }
    if (isselectAll.length > 0) {
      if (surveyQuestion && surveyQuestion.length > 0) {
        surveyQuestion = surveyQuestion.filter((obj: any) => {
          delete obj.IsSelected;
          obj.Options = obj.Options.filter((o: any) => {
            const flag = o.IsChecked;
            delete o.IsChecked;
            delete o.IsSelected;
            return flag ? true : false;
          });
          return obj.Id.toLowerCase() !== 'all' && obj.IsChecked === true;
        });
      }
    } else {
      if (surveyQuestion && surveyQuestion.length > 0) {
        surveyQuestion = surveyQuestion.filter((obj: any) => {
          delete obj.IsSelected;
          obj.Options = obj.Options.filter((o: any) => {
            const flag = o.IsChecked;
            delete o.IsChecked;
            delete o.IsSelected;
            return flag ? true : false;
          });
          return obj.Id.toLowerCase() !== 'all';
        });
      }
    }
    return { SurveyQuestions: surveyQuestion };
  }
  getCategory(name: string, initialState: any) {
    let category: any = {
      id: 1,
      level: 0,
      name: 'All Category',
    };
    if (this.category) {
      category = this.category;
    } else {
      const localStorageCategory: any = JSON.parse(localStorage.getItem('gbf_category'));
      if (localStorageCategory) {
        category = localStorageCategory;
      }
    }
    return {
      CategoryId: category.id,
      CategoryLevel: category.temp ? category.level : category.level + 1,
      CategoryName: category.name,
    };
  }
  getSurveyScore(name: string, initialState: any) {
    let surveyScore: any = {
      Id: '',
      Type: 'global_filter_label.Survey Score',
      Score: [0, 100],
      Types: [],
    };
    if (this.surveyScore) {
      surveyScore = this.surveyScore;
    } else {
      const localStorageSurveyScore: any = JSON.parse(localStorage.getItem('gbf_survey_score'));
      if (localStorageSurveyScore) {
        surveyScore = localStorageSurveyScore;
      }
    }
    return {
      ScoreRangeType: surveyScore.Type,
      ScoreRangeMin: surveyScore.Score[0],
      ScoreRangeMax: surveyScore.Score[1],
    };
  }
  getModel(name: string, initialState: any) {
    let model: any = {
      ActiveModal: '',
      ActiveModalClass: '',
      ActiveModalYear: '',
    };
    if (this.model) {
      model = this.model;
    } else {
      const local: any = JSON.parse(localStorage.getItem('gbf_mby'));
      if (local && local.selected) {
        model = local.selected;
      }
    }
    return {
      ActiveModal: model.Model === 'All Models' ? '' : model.Model,
      ActiveModalClass: model.Brand === 'All Brands' ? '' : model.Brand,
      ActiveModalYear: model.Year === 'All Years' ? '' : model.Year,
    };
  }
  getMultiOrg(name: string, initialState: any) {
    let orgs: any = [];
    if (this.multi_org && this.multi_org.length > 0) {
      orgs = this.multi_org;
    } else {
      const orgLocal: any = JSON.parse(localStorage.getItem('gbf_multi_org'));
      if (orgLocal && orgLocal.selected) {
        orgs = orgLocal.selected;
      }
    }
    const maxOrgLevel = Math.max.apply(
      Math,
      orgs.map((o: any) => {
        return o.level;
      })
    );
    const mOrgs: any[] = [];
    orgs = orgs.filter((org: any) => org.level === maxOrgLevel);
    if (orgs) {
      orgs.forEach((org: any) => {
        mOrgs.push({
          OrgName: org.NodeId, // NodeId
          OrgParent: org.parent, // Org Parent
          OrgLevel: org.level, // Org Parent
          OrgIdentifier: org.Identifier, // Identifier
        });
      });
    }
    if (mOrgs && mOrgs.length > 0) {
      return { Orgs: mOrgs, OrgLevel: maxOrgLevel };
    } else {
      this[name] = initialState;
      return { Orgs: initialState, OrgLevel: 1 };
    }
  }
  getOrg(name: string, initialState: any) {
    let org: any = {
      NodeId: '',
      parent: '',
      level: '',
      Identifier: '',
    };
    if (this.org) {
      org = this.org;
    } else {
      const orgLocal: any = JSON.parse(localStorage.getItem('gbf_org'));
      if (orgLocal && orgLocal.selected) {
        org = orgLocal.selected;
      }
    }
    return {
      OrgName: org.NodeId || org.mProps.NodeId, // NodeId
      OrgParent: org.parent, // Org Parent
      OrgLevel: org.level, // Org Parent
      OrgIdentifier: org.Identifier || org.mProps.Identifier, // Identifier
    };
  }
  getDates(name: string, initialState: any) {
    const dateRange = this.getCalenderRange();
    return {
      DateIdentifier: this.getDateIdentifier(),
      StartDate: dateRange[0],
      EndDate: dateRange[1],
    };
  }
  getDealer(name: string, initialState: any) {
    return {
      DealerType: this.dealer.value,
    };
  }
  getVersion(name: string, initialState: any) {
    return {
      SurveyVersion: this.version.value,
    };
  }
  getSources(name: string, initialState: any) {
    const checked = this.sources.filter((x: any) => x.IsChecked === true);
    if (checked) {
      return {
        SourceType: this.sources.map((x: any) => {
          return {
            Id: x.value,
            IsChecked: x.IsChecked,
            Title: x.label,
          };
        }),
      };
    } else {
      return {
        SourceType: initialState,
      };
    }
  }

  setSentiment(data: any) {
    var sentiment = JSON.parse(localStorage.getItem('gbf_sentiments'));
    if (sentiment) this.sentiment = { label: sentiment.label, value: sentiment.label, type: sentiment.value };
  }
  getSentiments(name: string, initialState: any) {
    return {
      SentimentType: this.sentiment.type,
    };
  }
  getDynamicFilterObject() {
    if (this.currentUrl.includes('?')) {
      this.currentUrl = this.currentUrl.split('?')[0];
    }
    let localUrl = this.currentUrl;
    //let localUrl = this.currentUrl.replace(window.location.search, '');
    if (this.activatedUrl) {
      localUrl = this.activatedUrl;
      this.activatedUrl = '';
    }

    const filterConfig = this.gbfConfig.filterConfig;
    let tabs = this.gbfConfig.filterConfig.pageConfig['default']['tabs'];
    let configs = this.gbfConfig.filterConfig.pageConfig['default']['filter'];
    if (filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]) {
      configs = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]['filter'];
      tabs = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]['tabs'];
    }
    let filterObject: any = {};
    configs.forEach((filter: any) => {
      const f = this.gbfConfig.filterConfig.returnFilter[filter];
      let initialState = this.gbfConfig.filterConfig.initialStates[filter];

      if (f) {
        if (f.signature === 'method') {
          if (this[f.name]) {
            filterObject = { ...filterObject, ...this[f.name](f, initialState) };
          }
        } else if (f.signature === 'property') {
          if (this[f.name] && this[f.name][f.valueProp]) {
            filterObject[f.filterKey] = this[f.name][f.valueProp];
          } else if (this[f.name]) {
            filterObject[f.filterKey] = this[f.name];
          }
        }
      }
    });

    if (!this.dateIdentifier) {
      console.log('this.dateIdentifier');
      console.log(this.dateIdentifier);

      const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
      if (found) {
        this.dateIdentifier = found.range;
        console.log(this.dateIdentifier);
      }
    }
    return { tabs: tabs, filters: filterObject };
  }
  getFilterObject() {
    const filter = this.getDynamicFilterObject();
    const filters = {
      PageSize: this.pageSize,
      PageNo: this.pageNo,
      Fields: this.field,
      Filters: this.filterItem,
      Status: '',
      SortColumn: this.SortColumn,
      Sort: this.Sort,
      ...filter.filters,
    };
    if (!filter.tabs) {
      filters.Event = this.tab.value;
    }
    return filters;
  }
}
