<ng-container *ngIf="!showRecursive">
  <div class="ui-datepicker-group ui-widget-content" *ngFor="let month of months; let i = index;">
    <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
      <ng-content select="rf-header"></ng-content>
      <!-- <a class="ui-datepicker-prev ui-corner-all" tabindex="0" (click)="navBackward($event)"
        *ngIf="i === 0 && numberOfMonths === 1">
        <span class="pi pi-chevron-left"></span>
        </a> -->
      <!-- <a class="ui-datepicker-next ui-corner-all" tabindex="0" (click)="navForward($event)"
        *ngIf="numberOfMonths === 1 ? true : (i === numberOfMonths -1)">
        <span class="pi pi-chevron-right"></span>
        </a> -->
      <div class="ui-datepicker-title">
        <!-- <span class="ui-datepicker-month"
            *ngIf="!monthNavigator && (view !== 'month')">{{locale.monthNames[month.month]}}</span> -->
        <span class="p-datepicker-month ui-datepicker-month" *ngIf="!monthNavigator && (view !== 'month')"
          >{{getTranslation('monthNames')[month.month]}}</span
        >
        <select
          class="ui-datepicker-month btn dropdown-toggle ui-dropdown-label"
          (change)="onMonthDropdownChange($event.target.value)"
        >
          <option
            [value]="i"
            *ngFor="let monthName of getTranslation('monthNames');let i = index"
            [selected]="i === month.month"
          >
            {{monthName}}
          </option>
        </select>
        <select
          style="margin-top: 4px"
          class="ui-datepicker-year btn dropdown-toggle ui-dropdown-label"
          (change)="onYearDropdownChange($event.target.value)"
        >
          <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">{{year}}</option>
        </select>
        <span class="ui-datepicker-year" *ngIf="!yearNavigator">{{view === 'month' ? currentYear : month.year}}</span>
      </div>
    </div>
    <div class="ui-datepicker-calendar-container" *ngIf="view ==='date'">
      <table class="ui-datepicker-calendar">
        <thead>
          <tr>
            <th scope="col" *ngFor="let weekDay of weekDays;let begin = first; let end = last">
              <span>{{weekDay}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let week of month.dates">
            <td
              *ngFor="let date of week"
              [ngClass]="{'ui-datepicker-other-month': date.otherMonth,
            'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}"
            >
              <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                <a
                  class="ui-state-default"
                  *ngIf="date.selectable"
                  [ngClass]="{'ui-state-active':isSelected(date), 'ui-state-highlight':date.today}"
                  (click)="onDateSelect($event,date)"
                  draggable="false"
                >
                  <ng-container *ngIf="!dateTemplate"> {{date.day}} </ng-container>
                  <ng-container *ngTemplateOutlet="dateTemplate; context: {$implicit: date}"> </ng-container>
                </a>
                <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable"> {{date.day}} </span>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<table border="0" style="padding: 0; margin: 0" *ngIf="showRecursive">
  <tr style="padding: 0; margin: 0">
    <td style="padding: 0; padding-right: 13px !important; margin: 0">
      <cvc-inner-calendar
        dateKey="start"
        (change)="onInnerCalendarChange($event)"
        [showRecursive]="false"
        [(yearRange)]="startYears"
        [(monthYear)]="monthYear"
        [monthNavigator]="monthNavigator"
        [yearNavigator]="yearNavigator"
        [endDate]="outputFormat.end"
        [defaultDate]="dateRanges[0]"
        [maxDate]="endDate"
        [readonlyInput]="true"
        [numberOfMonths]="numberOfMonths"
      ></cvc-inner-calendar>
    </td>
    <td style="padding: 0; margin: 0">
      <cvc-inner-calendar
        dateKey="end"
        (change)="onInnerCalendarChange($event)"
        [showRecursive]="false"
        [(yearRange)]="endYears"
        [(monthYear)]="monthYear"
        [monthNavigator]="monthNavigator"
        [yearNavigator]="yearNavigator"
        [defaultDate]="dateRanges[1]"
        [numberOfMonths]="numberOfMonths"
      ></cvc-inner-calendar>
    </td>
  </tr>
</table>
